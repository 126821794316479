import { ErrorMessage } from '@hookform/error-message'
import { useEffect, useState } from 'react'
import ru from 'date-fns/locale/ru'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import { useFormContext, Controller } from 'react-hook-form'

import { Text } from 'shared'

import './YearInput.scss'
registerLocale('ru', ru)

interface FormValues {
  name: string
  label: string
  onClick?: (val: string | Date) => void
  type: 'year' | 'date'
  defaultValue?: string
  status?: string
  isEditAccount?: boolean
}

const YearInput = (props: FormValues) => {
  const { name, label, onClick, type, defaultValue, status } =
    props
  const { control, formState, getFieldState, watch, setValue } =
    useFormContext()
  const { errors } = formState

 
  useEffect(() => {
    setValue(name, new Date(defaultValue))
  }, [])

  let isInputDisabled = false
  if (defaultValue !== 'null' && defaultValue && status === 'success') {
    isInputDisabled = true
  }

  const setYearComponent = ({ field: { onChange, value , onBlur, ref } }) => {
    let validValue =
      defaultValue && defaultValue !== 'null' ? new Date(defaultValue) : null
    return (
      <ReactDatePicker
        locale={ru}
        disabledKeyboardNavigation
        yearItemNumber={16}
        popperPlacement="bottom"
        placeholderText="2023"
        value={value}
        selected={validValue}
        onChange={onChange}
        onBlur={onBlur}
        wrapperClassName={
          getFieldState(name).invalid ? 'yearInput_invalid' : ''
        }
        showYearPicker
        dateFormat="yyyy"
        onSelect={(value) => {
          // console.dir(value)
          onClick(value.toDateString())
        }}
      />
    )
  }
  const setDateComponent = ({ field: { onChange, onBlur, value, ref } }) => {
    const validValue =
      defaultValue && defaultValue !== 'null' ? new Date(defaultValue) : null
    return (
      <ReactDatePicker
        locale={ru}
        disabledKeyboardNavigation
        dateFormat="dd.MM.yyyy"
        // disabled={
        //   defaultValue && defaultValue !== 'null' && status === 'success'
        // }
        // disabled={isInputDisabled}
        name={name}
        id={name}
        showMonthDropdown
        showYearDropdown
        value={value}
        selected={validValue}
        onChange={onChange}
        onBlur={onBlur}
        wrapperClassName={
          getFieldState(name).invalid ? 'yearInput_invalid' : ''
        }
        popperPlacement="bottom"
        placeholderText="Дата рождения"
        popperClassName="year-picker"
        dropdownMode="select"
        onSelect={(value) => onClick(value.toDateString())}
      />
    )
  }
  return (
    <div className="yearInput_wrapper">
      <p
        style={{
          color: getFieldState(name).invalid ? 'rgba(255, 0, 0)' : '#5D5B66',
          fontSize: '14px',
        }}
      >
        {label}
      </p>
      <Controller
        name={name}
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Обязательное поле',
          },
          validate: (birthday) => {
            const birthdayDate = new Date(birthday)
            const today = new Date()
            return birthdayDate < today || 'Введите корректную дату'
          },
        }}
        // defaultValue={defaultValue ? defaultValue : null}
        render={type === 'year' ? setYearComponent : setDateComponent}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <Text type="small" className="error">
            {message}
          </Text>
        )}
      />
    </div>
  )
}

export default YearInput
