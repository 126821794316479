import { useFormContext } from 'react-hook-form'
import {
  TextInput,
  AvatarInput,
  RadioInput,
  TextareaInput,
  YearInput,
  AuthSelectors,
  SingleCheckbox,
  INeighbour,
} from 'shared'
import { ReactNode, memo } from 'react'

import styles from './AboutMeStep.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import {
  registrationDataUpdate,
  setBirthDate,
} from 'entities/Registration/model/RegistrationSlice'
import { RegistrationSelectors } from 'entities/EditAccount'


const AboutMeStep = () => {
  const dispatch = useDispatch()

  const setFirstName = (event) => {
    dispatch(
      registrationDataUpdate({
        fieldName: 'first_name',
        value: event.target.value,
      })
    )
  }
  const setLastName = (event) => {
    dispatch(
      registrationDataUpdate({
        fieldName: 'last_name',
        value: event.target.value,
      })
    )
  }
  const setTel = (event) => {
    dispatch(
      registrationDataUpdate({ fieldName: 'phone', value: event.target.value })
    )
  }
  const setTelegram = (event) => {
    dispatch(
      registrationDataUpdate({
        fieldName: 'tg_username',
        value: event.target.value,
      })
    )
  }
  const onSetBirthday = (val: Date) => {
    dispatch(setBirthDate(val))
  }
  const setAboutMe = (event) => {
    dispatch(
      registrationDataUpdate({
        fieldName: 'about_me',
        value: event.target.value,
      })
    )
  }

  const setGender = (event) => {
    dispatch(
      registrationDataUpdate({ fieldName: 'gender', value: event.target.value })
    )
  }
  const show_phone = useSelector(RegistrationSelectors.show_phone)

  const onSetShowPhone = () => {
    dispatch(
      registrationDataUpdate({ fieldName: 'show_phone', value: !show_phone })
    )
  }
  const first_name = useSelector(RegistrationSelectors.first_name)
  const last_name = useSelector(RegistrationSelectors.last_name)
  const birthday = useSelector(RegistrationSelectors.birthday)

  dispatch(setBirthDate(birthday))

  let accountDataStatus = useSelector(RegistrationSelectors.accountDataStatus)

  const data = useSelector(RegistrationSelectors.data)
  const { phone, tg_username, about_me, gender } = data
  return (
    <div className={styles.form}>
      <AvatarInput name="avatar" />
      <TextInput
        name="first_name"
        label="Имя"
        onHandleChange={setFirstName}
        defaultValue={first_name}
        status={accountDataStatus}
      />
      <div className={styles.wrapper}>
        <TextInput
          name="last_name"
          label="Фамилия"
          onHandleChange={setLastName}
          defaultValue={last_name}
          status={accountDataStatus}
        />
        <RadioInput
          id="gender"
          items={[
            { label: 'Парень', value: 'MAN' },
            { label: 'Девушка', value: 'WOMAN' },
          ]}
          onChange={setGender}
          defaultValue={gender}
        />
      </div>
      <YearInput
        name="birthday"
        label="Дата рождения"
        onClick={onSetBirthday}
        type="date"
        defaultValue={birthday}
        status={accountDataStatus}
      />
      <div className={styles.wrapper}>
        <TextInput
          name="phone"
          label="Номер телефона"
          type="tel"
          onHandleChange={setTel}
          defaultValue={phone}
        />
        <SingleCheckbox
          onClick={onSetShowPhone}
          label="Согласен показывать свой номер для связи"
          isSelected={show_phone}
        />
      </div>

      <TextInput
        name="telegram"
        label="Ник телеграма без @"
        type="telegram"
        onHandleChange={setTelegram}
        defaultValue={tg_username}
      />
      <div className={styles.textAreaWrapper}>
        <TextareaInput
          name="aboutMe"
          label="О себе"
          onChange={setAboutMe}
          defaultValue={about_me}
        />
        <p className={styles.exampleText}>
          Например: Редко готовлю, но люблю ботать на кухне по ночам. Хочу
          завести собаку. Очень люблю читать, играть в хорроры и смотреть аниме.
          Открыт к общению и взаимопомощи :)
        </p>
      </div>
    </div>
  )
}

export default AboutMeStep
