import { IRoom } from 'shared'

const getMonthPrice = (room: IRoom): string => {
  const { price } = room

  let priceFirstDigits: string
  let priceLastDigits: string
  if (price > 1000) {
    priceFirstDigits = price.toString().slice(0, price.toString().length - 3)
    priceLastDigits = price.toString().slice(-3)
  } else {
    priceFirstDigits = ''
    priceLastDigits = price.toString()
  }
  return `${priceFirstDigits} ${priceLastDigits}`
}

const isDeposit = (room: IRoom): boolean => {
  const { deposit } = room
  return deposit && deposit !== 0
}

const getDepositPrice = (room: IRoom): string => {
  let priceFirstDigits: string
  let priceLastDigits: string
  if (isDeposit(room)) {
    const { deposit } = room
    if (deposit > 1000) {
      priceFirstDigits = deposit
        .toString()
        .slice(0, deposit.toString().length - 3)
      priceLastDigits = deposit.toString().slice(-3)
    } else {
      priceFirstDigits = ''
      priceLastDigits = deposit.toString()
    }

    return `${priceFirstDigits} ${priceLastDigits}`
  }
  return ``
}

const isPublicUtilities = (room: IRoom): boolean => {
  const { public_utilities } = room
  return public_utilities
}

export { getMonthPrice, isPublicUtilities, getDepositPrice, isDeposit }
