import { Text } from 'shared'
import Select from 'react-select'
import './SelectFilter.scss'

export interface ISelectItem<T = string> {
  value: T // id
  label: string
}
interface Props<T> {
  label: string
  className?: string
  items: ISelectItem<T>[]
  onChange: (val: any) => void
  value?: ISelectItem<T> | null
}
const SelectFilter = <P extends unknown>(props: Props<P>) => {
  const { items, label, onChange, value, className } = props
  const selectStyles = {
		dropdownIndicator: (provided, state) => ({
			...provided,
				transition: 'all .2s ease',
				transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
			}),
			control: (base) => ({
				...base,
				boxShadow: 'none',
				border: 0,
			}),
			option: (base) => ({
				...base,
				backgroundColor: '#ffffff',
			}),
			menuList: (base) => ({
				...base,
				'::-webkit-scrollbar': {
					width: '4px',
					height: '0px',
				},
				'::-webkit-scrollbar-track': {
					background: '#ffffff',
				},
				'::-webkit-scrollbar-thumb': {
					background: '#D9D1E0',
					width: '4px',
					borderRadius: '4px',
				},
			}),
	}
  return (
    <>
      <div>
        <Text type="small" color="secondary-black">
          {label}
        </Text>
        <Select
          className={className}
          onChange={onChange}
          value={value}
          captureMenuScroll={false}
          options={items}
          placeholder={label}
          classNamePrefix="select"
          isSearchable={false}
          styles={selectStyles}
        />
      </div>
    </>
  )
}
export default SelectFilter
