import { memo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { registrationDataUpdate } from "entities/Registration/model/RegistrationSlice"; 
import { ErrorMessage } from "@hookform/error-message";

import { ISelectItem, Text } from 'shared'

import styles from './RadioInput.module.scss'

type RadioValues = {
	id: string
	items: ISelectItem<string>[];
	onChange: (event) => void
	defaultValue?: string
}

const RadioInput = memo((props: RadioValues) => {
	const { onChange, items, id, defaultValue = ''} = props

	const { register,
		formState,
		getFieldState,
		watch,
	 } = useFormContext()
	const { errors } = formState

	const handleChange = (event) => {
		onChange(event)
	  }
	const components = items.map(item => {
		return (
			<label 
			key={item.value}
			className={styles.label}
			htmlFor={item.value}
			>
					<input 
					type="radio"
					id={item.value}
					value={item.value}
					style={{display: 'none'}}
					defaultChecked={defaultValue === item.value}
					onClick={handleChange} 
					{...register(id, {
						required: {
							value: true, 
							message: 'Выберите один вариант', 
						},
						// value: defaultValue ? (defaultValue === item.value ? defaultValue : null) : null,
						value: item.value,
						
					})} 
					/>
					<div className={`${styles.radio} ${defaultValue === item.value ? styles.radio_active : styles.radio}`}></div>
					<p>{item.label}</p>
			</label>
		)
	})
	return (
		<>
			<div className={styles.input}>
				<div className={styles.wrapper}>
					{components}
				</div>
				<ErrorMessage
				errors={errors}
				name={id}
				render={({ message }) => <Text type="small" className={styles.error}>{message}</Text>}
				/>
			</div>
		</>
	)
})

export default RadioInput