import { IRoomSearchParams, TBooleanToSrting, TPlateType } from 'shared'
import {
  TBathroomFilter,
  TConveniencesFilter,
  TNeighbourFilter,
  TPreferencesFilter,
  TRoomsFilters,
} from '../types'
import { PayloadAction } from '@reduxjs/toolkit'
import { InitialState } from 'widgets/FindRoomFilters/model/roomFilterSlice'

const isPreferencesFilter = (
  obj: TPreferencesFilter | IRoomSearchParams
): obj is TPreferencesFilter => {
  return (
    obj === 'without_deposit' ||
    obj === 'pets_allowed' ||
    obj === 'children_allowed' ||
    obj === 'tv' ||
    obj === 'dishwasher' ||
    obj === 'with_public_utilities' ||
    obj === 'conditioner'
  )
}

const isConveniencesFilter = (
  obj: TConveniencesFilter | IRoomSearchParams
): obj is TConveniencesFilter => {
  return (
    obj === 'balcony' ||
    obj === 'elevator' ||
    obj === 'fridge' ||
    obj === 'furniture' ||
    obj === 'garbage_chute' ||
    obj === 'internet' ||
    obj === 'washing_machine'
  )
}

const isBathroomFilter = (
  obj: TBathroomFilter | IRoomSearchParams
): obj is TBathroomFilter => {
  return (
    obj === 'BATHROOM' ||
    obj === 'SHOWER' ||
    obj === 'bathroom_is_combined' ||
    obj === 'bathroom_is_separate'
  )
}

const isPlateFilter = (
  obj: TPlateType | IRoomSearchParams
): obj is TPlateType => {
  return obj === 'ELECTRICAL' || obj === 'GAS'
}

const isNeighbourFilter = (
  obj: TNeighbourFilter | IRoomSearchParams
): obj is TNeighbourFilter => {
  return (
    obj === 'MAN' ||
    obj === 'WOMAN' ||
    obj === 'against_alcohol' ||
    obj === 'against_smoking'
  )
}

export function onSetPreferencesList(
  state: InitialState,
  action: PayloadAction<TPreferencesFilter> | PayloadAction<IRoomSearchParams>
) {
  const payload = action.payload
  if (isPreferencesFilter(payload)) {
    state.preferencesList = state.preferencesList.map((period) => {
      if (period.id === payload) {
        return {
          ...period,
          isSelected: !period.isSelected,
        }
      } else {
        return period
      }
    })
  } else {
    state.preferencesList = state.preferencesList.map((period) => {
      if (
        (payload.has_pets_allowed && period.id === 'pets_allowed') ||
        (payload.has_conditioner && period.id === 'conditioner') ||
        (payload.has_children_allowed && period.id === 'children_allowed') ||
        (payload.has_tv && period.id === 'tv') ||
        (payload.has_dishwasher && period.id === 'dishwasher') ||
        (payload.has_public_utilities &&
          period.id === 'with_public_utilities') ||
        (payload.without_deposit && period.id === 'without_deposit')
      ) {
        return {
          ...period,
          isSelected: !period.isSelected,
        }
      }

      return period
    })
  }
}
export function onSetConveniencesList(
  state: InitialState,
  action: PayloadAction<TConveniencesFilter> | PayloadAction<IRoomSearchParams>
) {
  const payload = action.payload
  if (isConveniencesFilter(payload)) {
    state.conveniencesList = state.conveniencesList.map((convenience) => {
      if (convenience.id === payload) {
        return {
          ...convenience,
          isSelected: !convenience.isSelected,
        }
      } else {
        return convenience
      }
    })
  } else {
    state.conveniencesList = state.conveniencesList.map((convenience) => {
      if (
        (payload.has_furniture && convenience.id === 'furniture') ||
        (payload.has_fridge && convenience.id === 'fridge') ||
        (payload.has_washing_machine && convenience.id === 'washing_machine') ||
        (payload.has_internet && convenience.id === 'internet') ||
        (payload.has_balcony && convenience.id === 'balcony') ||
        (payload.has_elevator && convenience.id === 'elevator') ||
        (payload.has_garbage_chute && convenience.id === 'garbage_chute')
      ) {
        return {
          ...convenience,
          isSelected: !convenience.isSelected,
        }
      }

      return convenience
    })
  }
}

interface IObjBathFilter {
  BATHROOM: boolean
  SHOWER: boolean
  bathroom_is_combined: boolean
  bathroom_is_separate: boolean
}

export function onSetBathroomList(
  state: InitialState,
  action: PayloadAction<TBathroomFilter> | PayloadAction<IRoomSearchParams>
) {
  const payload = action.payload
  if (isBathroomFilter(payload)) {
    const objBathFilter: IObjBathFilter = {
      BATHROOM: false,
      SHOWER: false,
      bathroom_is_combined: false,
      bathroom_is_separate: false,
    }
    state.bathroomList.forEach((bathroom) => {
      if (bathroom.id === 'BATHROOM') {
        objBathFilter.BATHROOM = bathroom.isSelected
      } else if (bathroom.id === 'SHOWER') {
        objBathFilter.SHOWER = bathroom.isSelected
      } else if (bathroom.id === 'bathroom_is_combined') {
        objBathFilter.bathroom_is_combined = bathroom.isSelected
      } else if (bathroom.id === 'bathroom_is_separate') {
        objBathFilter.bathroom_is_separate = bathroom.isSelected
      }
    })

    if (
      (payload === 'BATHROOM' && state.shower_type === 'SHOWER') ||
      (payload === 'SHOWER' && state.shower_type === 'BATHROOM')
    ) {
      objBathFilter.BATHROOM = !objBathFilter.BATHROOM
      objBathFilter.SHOWER = !objBathFilter.SHOWER
    } else if (payload === 'BATHROOM') {
      objBathFilter.BATHROOM = !objBathFilter.BATHROOM
    } else if (payload === 'SHOWER') {
      objBathFilter.SHOWER = !objBathFilter.SHOWER
    } else if (
      (payload === 'bathroom_is_combined' &&
        state.bathroom_is_combined === 'false') ||
      (payload === 'bathroom_is_separate' &&
        state.bathroom_is_combined === 'true')
    ) {
      objBathFilter.bathroom_is_combined = !objBathFilter.bathroom_is_combined
      objBathFilter.bathroom_is_separate = !objBathFilter.bathroom_is_separate
    } else if (payload === 'bathroom_is_combined') {
      objBathFilter.bathroom_is_combined = !objBathFilter.bathroom_is_combined
    } else if (payload === 'bathroom_is_separate') {
      objBathFilter.bathroom_is_separate = !objBathFilter.bathroom_is_separate
    }

    state.bathroomList = state.bathroomList.map((bathroom) => {
      if (bathroom.id === 'BATHROOM') {
        return {
          ...bathroom,
          isSelected: objBathFilter.BATHROOM,
        }
      }
      if (bathroom.id === 'SHOWER') {
        return {
          ...bathroom,
          isSelected: objBathFilter.SHOWER,
        }
      }
      if (bathroom.id === 'bathroom_is_combined') {
        return {
          ...bathroom,
          isSelected: objBathFilter.bathroom_is_combined,
        }
      }
      if (bathroom.id === 'bathroom_is_separate') {
        return {
          ...bathroom,
          isSelected: objBathFilter.bathroom_is_separate,
        }
      }
      return bathroom
    })
  } else {
    state.bathroomList = state.bathroomList.map((bathroom) => {
      if (
        (payload.shower_type && bathroom.id === payload.shower_type) ||
        (payload.bathroom_is_combined === 'true' &&
          bathroom.id === 'bathroom_is_combined') ||
        (payload.bathroom_is_combined === 'false' &&
          bathroom.id === 'bathroom_is_separate')
      ) {
        return {
          ...bathroom,
          isSelected: !bathroom.isSelected,
        }
      }
      return bathroom
    })
  }
}

interface IObjPlateFilter {
  ELECTRICAL: boolean
  GAS: boolean
}

export function onSetPlateList(
  state: InitialState,
  action: PayloadAction<TPlateType> | PayloadAction<IRoomSearchParams>
) {
  const payload = action.payload
  if (isPlateFilter(payload)) {
    const objBathFilter: IObjPlateFilter = {
      ELECTRICAL: false,
      GAS: false,
    }
    state.plateList.forEach((plate) => {
      if (plate.id === 'ELECTRICAL') {
        objBathFilter.ELECTRICAL = plate.isSelected
      } else if (plate.id === 'GAS') {
        objBathFilter.GAS = plate.isSelected
      }
    })

    if (
      (payload === 'ELECTRICAL' && state.plate_type === 'GAS') ||
      (payload === 'GAS' && state.plate_type === 'ELECTRICAL')
    ) {
      objBathFilter.ELECTRICAL = !objBathFilter.ELECTRICAL
      objBathFilter.GAS = !objBathFilter.GAS
    } else if (payload === 'ELECTRICAL') {
      objBathFilter.ELECTRICAL = !objBathFilter.ELECTRICAL
    } else if (payload === 'GAS') {
      objBathFilter.GAS = !objBathFilter.GAS
    }

    state.plateList = state.plateList.map((plate) => {
      if (plate.id === 'ELECTRICAL') {
        return {
          ...plate,
          isSelected: objBathFilter.ELECTRICAL,
        }
      }
      if (plate.id === 'GAS') {
        return {
          ...plate,
          isSelected: objBathFilter.GAS,
        }
      }
      return plate
    })
  } else {
    state.plateList = state.plateList.map((plate) => {
      if (payload.plate_type && plate.id === payload.plate_type) {
        return {
          ...plate,
          isSelected: !plate.isSelected,
        }
      }
      return plate
    })
  }
}

interface IObjNeighbourFilter {
  MAN: boolean
  WOMAN: boolean
  against_alcohol: boolean
  against_smoking: boolean
}

export function onSetNeighbourList(
  state: InitialState,
  action: PayloadAction<TNeighbourFilter> | PayloadAction<IRoomSearchParams>
) {
  const payload = action.payload
  if (isNeighbourFilter(payload)) {
    const objNeighbourFilter: IObjNeighbourFilter = {
      MAN: false,
      WOMAN: false,
      against_alcohol: false,
      against_smoking: false,
    }
    state.neighbourFilterList.forEach((neighbour) => {
      if (neighbour.id === 'MAN') {
        objNeighbourFilter.MAN = neighbour.isSelected
      } else if (neighbour.id === 'WOMAN') {
        objNeighbourFilter.WOMAN = neighbour.isSelected
      } else if (neighbour.id === 'against_alcohol') {
        objNeighbourFilter.against_alcohol = neighbour.isSelected
      } else if (neighbour.id === 'against_smoking') {
        objNeighbourFilter.against_smoking = neighbour.isSelected
      }
    })

    if (
      (payload === 'MAN' && state.gender === 'WOMAN') ||
      (payload === 'WOMAN' && state.gender === 'MAN')
    ) {
      objNeighbourFilter.MAN = !objNeighbourFilter.MAN
      objNeighbourFilter.WOMAN = !objNeighbourFilter.WOMAN
    } else if (payload === 'MAN') {
      objNeighbourFilter.MAN = !objNeighbourFilter.MAN
    } else if (payload === 'WOMAN') {
      objNeighbourFilter.WOMAN = !objNeighbourFilter.WOMAN
    } else if (payload === 'against_alcohol') {
      objNeighbourFilter.against_alcohol = !objNeighbourFilter.against_alcohol
    } else if (payload === 'against_smoking') {
      objNeighbourFilter.against_smoking = !objNeighbourFilter.against_smoking
    }

    state.neighbourFilterList = state.neighbourFilterList.map((neighbour) => {
      if (neighbour.id === 'MAN') {
        return {
          ...neighbour,
          isSelected: objNeighbourFilter.MAN,
        }
      }
      if (neighbour.id === 'WOMAN') {
        return {
          ...neighbour,
          isSelected: objNeighbourFilter.WOMAN,
        }
      }
      if (neighbour.id === 'against_alcohol') {
        return {
          ...neighbour,
          isSelected: objNeighbourFilter.against_alcohol,
        }
      }
      if (neighbour.id === 'against_smoking') {
        return {
          ...neighbour,
          isSelected: objNeighbourFilter.against_smoking,
        }
      }
      return neighbour
    })
  } else {
    state.neighbourFilterList = state.neighbourFilterList.map((neighbour) => {
      if (
        (payload.gender && neighbour.id === payload.gender) ||
        (payload.against_alcohol === 'true' &&
          neighbour.id === 'against_alcohol') ||
        (payload.against_smoking === 'true' &&
          neighbour.id === 'against_smoking')
      ) {
        return {
          ...neighbour,
          isSelected: !neighbour.isSelected,
        }
      }
      return neighbour
    })
  }
}

//http://localhost:3000/rooms/?against_alcohol=true&against_smoking=true&bathroom_is_combined=true&has_balcony=true&has_children_allowed=true&has_conditioner=true&has_dishwasher=true&has_elevator=true&has_fridge=true&has_furniture=true&has_garbage_chute=true&has_internet=true&has_pets_allowed=true&has_public_utilities=true&has_tv=true&has_washing_machine=true&max_apartment_area=65&max_floor=11&max_room_area=15&min_apartment_area=35&min_floor=2&min_room_area=9&minimum_rental_period=2&number_rooms=2&plate_type=ELECTRICAL&price_max=30000&price_min=15000&shower_type=BATHROOM&without_deposit=true

export function toggleBooleanFilter(
  state: InitialState,
  propperty: keyof TRoomsFilters
) {
  if (
    state[propperty] === 'true' ||
    state[propperty] === 'false' ||
    state[propperty] === null
  ) {
    state[propperty as string] =
      state[propperty] === 'false' || state[propperty] === null
        ? 'true'
        : (null as TBooleanToSrting)
  }
}
