import type { RootState } from 'app/store'

export const neighbourLikeChangeStatus = (state: RootState) =>
  state.favouritesSlice.neighbourLikeChangeStatus

export const roomLikeChangeStatus = (state: RootState) =>
  state.favouritesSlice.roomLikeChangeStatus

export const errorFavourities = (state: RootState) =>
  state.favouritesSlice.errorFavourities
export const errorStatus = (state: RootState) =>
  state.favouritesSlice.errorStatus
