import { IRoomSearchParams } from 'shared'

// ВАЖНО!!!
// При изменении интерфейса IRoomSearchParams ОБЯЗАТЕЛЬНО нужно прописать ветку if для добавления/удаления параметра.
// Так как наличие параметров проверяется с помощью фун-ции has(), то нельзя ошибаться в написании параметров в has()

// Функция, которая вытаскивает все параметры из адресной строки и формирует из этого объект

export const concatSearchParams = (searchParams: IRoomSearchParams): string => {
  let params: string = ''

  if (searchParams.offset) {
    params = params + `&offset=${searchParams.offset}`
  }
  if (searchParams.number_rooms) {
    params = params + `&number_rooms=${searchParams.number_rooms}`
  }
  if (searchParams.price_max) {
    params = params + `&price_max=${searchParams.price_max}`
  }
  if (searchParams.price_min) {
    params = params + `&price_min=${searchParams.price_min}`
  }
  if (searchParams.minimum_rental_period) {
    params =
      params + `&minimum_rental_period=${searchParams.minimum_rental_period}`
  }
  if (searchParams.min_room_area) {
    params = params + `&min_room_area=${searchParams.min_room_area}`
  }
  if (searchParams.max_room_area) {
    params = params + `&max_room_area=${searchParams.max_room_area}`
  }
  if (searchParams.min_apartment_area) {
    params = params + `&min_apartment_area=${searchParams.min_apartment_area}`
  }
  if (searchParams.max_apartment_area) {
    params = params + `&max_apartment_area=${searchParams.max_apartment_area}`
  }
  if (searchParams.min_floor) {
    params = params + `&min_floor=${searchParams.min_floor}`
  }
  if (searchParams.max_floor) {
    params = params + `&max_floor=${searchParams.max_floor}`
  }
  if (searchParams.has_pets_allowed) {
    params = params + `&has_pets_allowed=${searchParams.has_pets_allowed}`
  }
  if (searchParams.has_children_allowed) {
    params =
      params + `&has_children_allowed=${searchParams.has_children_allowed}`
  }
  if (searchParams.has_tv) {
    params = params + `&has_tv=${searchParams.has_tv}`
  }
  if (searchParams.has_dishwasher) {
    params = params + `&has_dishwasher=${searchParams.has_dishwasher}`
  }
  if (searchParams.has_public_utilities) {
    params =
      params + `&has_public_utilities=${searchParams.has_public_utilities}`
  }
  if (searchParams.without_deposit) {
    params = params + `&without_deposit=${searchParams.without_deposit}`
  }
  if (searchParams.has_conditioner) {
    params = params + `&has_conditioner=${searchParams.has_conditioner}`
  }
  if (searchParams.has_furniture) {
    params = params + `&has_furniture=${searchParams.has_furniture}`
  }
  if (searchParams.has_fridge) {
    params = params + `&has_fridge=${searchParams.has_fridge}`
  }
  if (searchParams.has_washing_machine) {
    params = params + `&has_washing_machine=${searchParams.has_washing_machine}`
  }
  if (searchParams.has_internet) {
    params = params + `&has_internet=${searchParams.has_internet}`
  }
  if (searchParams.has_balcony) {
    params = params + `&has_balcony=${searchParams.has_balcony}`
  }
  if (searchParams.has_elevator) {
    params = params + `&has_elevator=${searchParams.has_elevator}`
  }
  if (searchParams.has_garbage_chute) {
    params = params + `&has_garbage_chute=${searchParams.has_garbage_chute}`
  }
  if (searchParams.bathroom_is_combined) {
    params =
      params + `&bathroom_is_combined=${searchParams.bathroom_is_combined}`
  }
  if (searchParams.shower_type) {
    params = params + `&shower_type=${searchParams.shower_type}`
  }
  if (searchParams.plate_type) {
    params = params + `&plate_type=${searchParams.plate_type}`
  }
  if (searchParams.gender) {
    params = params + `&gender=${searchParams.gender}`
  }
  if (searchParams.against_alcohol) {
    params = params + `&against_alcohol=${searchParams.against_alcohol}`
  }

  if (searchParams.against_smoking) {
    params = params + `&against_smoking=${searchParams.against_smoking}`
  }
  if (searchParams.account_id) {
    params = params + `&account_id=${searchParams.account_id}`
  }

  return params
}
