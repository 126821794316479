import { Title, Text, Icon, IRoom, MapEmpty } from 'shared'

import styles from './RoomLocation.module.scss'

interface Props {
  room: IRoom
}

const RoomLocation = (props: Props) => {
  const { room } = props
  const { address, routes, address_x, address_y } = room

  const metroPlate = routes[0]?.destination ? (
    <>
      <div className={styles.plate}>
        {routes.map((route, ind) => {
          if (route.destination) {
            return (
              <div key={ind} className={styles.plate__metro}>
                <div className={styles.plate__metro__name}>
                  <Icon type="pixel" className={styles.plate__metro__icon} />
                  <Text type="paragraph">{route.destination}</Text>
                </div>
                <div className={styles.divider}></div>
                <div className={styles.plate__metro__time}>
                  <Icon
                    type={route.type === 'IN_PUBLIC' ? 'car' : 'walker'}
                    size="large"
                    className={
                      route.type === 'IN_PUBLIC'
                        ? styles.plate__transport
                        : styles.plate__walker
                    }
                  />
                  <Text type="paragraph">{route.time} мин</Text>
                </div>
              </div>
            )
          }
        })}
      </div>
    </>
  ) : null

  return (
    <>
      <div className={styles.wrapper}>
        <Title type="h3">Расположение</Title>
        <div>
          <Text type="paragraph" weight="bold" className={styles.text}>
            {address}
          </Text>
          {metroPlate}
        </div>
        <div className={styles.map}>
          <MapEmpty
            address={address}
            address_x={address_x}
            address_y={address_y}
          />
        </div>
      </div>
    </>
  )
}

export default RoomLocation
