import React, { InputHTMLAttributes, memo, RefObject, useEffect } from 'react'

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import { useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'

import { Text, TStatusType } from 'shared'
import { useState } from 'react'

import styles from './TextInput.module.scss'
import { E164Number } from 'libphonenumber-js/types'

interface FormValues
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  name: string
  label?: string
  type?: 'telegram' | 'default' | 'tel' | 'number' | 'everything'
  width?: string | number
  onHandleChange?: (event) => void
  maxlength?: number
  wrapperClass?: string
  defaultValue?: string | number
  status?: string
  required?: boolean
  value?: string | number
  isDisabled?: boolean
}

const TextInput = memo((props: FormValues) => {
  const { register, formState, trigger, getFieldState, watch, setValue } =
    useFormContext()
  const { errors, isValid } = formState

  const {
    label,
    name,
    type = 'text',
    width = 366,
    className,
    onHandleChange,
    maxlength,
    defaultValue,
    value,
    status = null,
    required = true,
  } = props

  let regularExpr = /[A-Za-zА-яЁё]/
  if (type === 'telegram') {
    regularExpr = /^[A-Za-z\d_]{5,32}$/
  } else if (type === 'tel') {
    regularExpr = /^(?:\+7|8)(?:\d{10})$/
  } else if (type === 'number') {
    regularExpr = /^\d+$/
  } else if (type === 'everything') {
    regularExpr = null
  }
  let isInputDisabled = false
  if (defaultValue && status == 'success') {
    isInputDisabled = true
  }

  const [isFocused, setIsFocused] = useState(false)
  const setValidation = async (e) => await trigger(e.target.name)
  const handleFocus = () => {
    setIsFocused(true)
  }
  const handleBlur = (e) => {
    if (e.target.value === '') {
      setIsFocused(false)
    } else {
      setIsFocused(true)
    }
    setValidation(e)
  }
  // let selectedOption = watch(name)
  // if (selectedOption !== undefined) onChange(selectedOption)

  const labelStyles =
    isFocused || defaultValue || formState.dirtyFields[name]
      ? {
          left: '0',
          top: '-18px',
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '20px',
          color: getFieldState(name).invalid ? '#FF0000' : '#5D5B66',
        }
      : {
          left: '12px',
          top: '16px',
          fontSize: '16px',
          fontWeight: '400',
          lineHeight: '24px',
          color: getFieldState(name).invalid ? '#FF0000' : '#A1A6B1',
        }

  const inputStyles = {
    background: getFieldState(name).invalid ? 'rgba(255, 0, 0, 0.05)' : '',
    border: getFieldState(name).invalid
      ? '1px solid var(--ff-0000-button-red, #F00)'
      : '',
  }

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue)
    }
  }, [defaultValue, setValue])

  const requiredObj = required
    ? {
        required: {
          value: true,
          message: 'Обязательное поле',
        },
      }
    : null
  return (
    <div className={styles.wrapper} style={{ width }}>
      <label htmlFor={name} style={labelStyles} className={styles.label}>
        {label}
      </label>
      <input
        {...register(name, {
          ...requiredObj,
          pattern: {
            value: regularExpr,
            message: 'Неверный формат ввода',
          },
          maxLength: {
            value: maxlength,
            message: `Превышено количество символов (${maxlength})`,
          },
          onChange: (e) => {
            if (onHandleChange) {
              onHandleChange(e)
            }
          },
          value: value,
        })}
       
        key={name}
        id={name}
        className={`${styles.input} ${className}`}
        defaultValue={defaultValue}
        disabled={isInputDisabled}
        autoComplete="off"
        onFocus={handleFocus}
        onBlur={handleBlur}
        maxLength={maxlength}
        // onSelect={onChange}
        style={inputStyles}
        type={type}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <Text type="small" className={styles.error}>
            {message}
          </Text>
        )}
      />
    </div>
  )
})

export default TextInput
