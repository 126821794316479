import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'app/store'

import { NeighbourCard } from 'widgets/NeighbourCard'
import {
  AddAndRemoveFavourites,
  FavouritiesProps,
} from 'features/AddAndRemoveFavourites'
import { fetchMyFavoriteNeighbours, myDataSelectors } from 'entities/Neighbour'
import { fetchMyFavoriteRooms, roomSelectors } from 'entities/Room'
import { RoomCard } from 'entities/Room'
import {
  Title,
  useRegistered,
  useToken,
  useClientWidth,
  adaptiveWidth,
  Container,
} from 'shared'

import styles from './FavouritesList.module.scss'

interface Props {
  favourites: 'neighbours' | 'rooms'
}

const FavouritesList = (props: Props) => {
  const { favourites } = props
  const { getClientWidth } = useClientWidth()
  const dispatch = useAppDispatch()
  const myFavoriteNeighbours = useSelector(myDataSelectors.myFavoriteNeighbours)
  const myFavoriteNeighboursStatus = useSelector(
    myDataSelectors.myFavoriteNeighboursStatus
  )
  const errorStatus = useSelector(myDataSelectors.errorStatus)
  const myFavoriteRooms = useSelector(roomSelectors.myFavoriteRooms)
  const myFavoriteRoomsStatus = useSelector(roomSelectors.myFavoriteRoomsStatus)

  const { getToken } = useToken()
  const { redirectToAuth } = useRegistered()

  useEffect(() => {
    dispatch(fetchMyFavoriteNeighbours(getToken()))
    dispatch(fetchMyFavoriteRooms(getToken()))
  }, [])

  let content: JSX.Element[] | JSX.Element
  if (errorStatus === 401) {
    redirectToAuth()
  }
  if (favourites === 'neighbours') {
    if (myFavoriteNeighboursStatus === 'error') {
      content = <Title type="h3">Упс... Что-то пошло не так</Title>
    } else if (
      myFavoriteNeighboursStatus === 'success' &&
      myFavoriteNeighbours.length === 0
    ) {
      if (getClientWidth() < adaptiveWidth['800px']) {
        content = (
          <Container>
            <Title type="h3">Нет избранных соседей</Title>
          </Container>
        )
      } else {
        content = <Title type="h3">Нет избранных соседей</Title>
      }
    } else if (
      myFavoriteNeighboursStatus === 'success' &&
      myFavoriteNeighbours.length > 0
    ) {
      content = myFavoriteNeighbours.map((user) => (
        <NeighbourCard neighbour={user} key={user.id} />
      ))
    }
  } else if (favourites === 'rooms') {
    if (myFavoriteRoomsStatus === 'error') {
      content = <Title type="h3">Упс... Что-то пошло не так</Title>
    } else if (
      myFavoriteRoomsStatus === 'success' &&
      myFavoriteRooms.length === 0
    ) {
      if (getClientWidth() < adaptiveWidth['800px']) {
        content = (
          <Container>
            <Title type="h3">Нет избранных комнат</Title>
          </Container>
        )
      } else {
        content = <Title type="h3">Нет избранных комнат</Title>
      }
    } else if (
      myFavoriteRoomsStatus === 'success' &&
      myFavoriteRooms.length > 0
    ) {
      content = myFavoriteRooms.map((room) => {
        const addAndRemoveFavourites = (props: FavouritiesProps) => {
          return (
            <AddAndRemoveFavourites
              entity={props.entity}
              isFavorite={props.isFavorite}
              id={props.id}
              className={props.className}
            />
          )
        }
        return (
          <RoomCard
            room={room}
            key={room.id}
            addAndRemoveFavourites={addAndRemoveFavourites}
          />
        )
      })
    }
  }

  if (getClientWidth() < adaptiveWidth['800px']) {
    return <div className={styles.cards}>{content}</div>
  } else {
    return (
      <Container>
        <div className={styles.cards}>{content}</div>
      </Container>
    )
  }
}

export default FavouritesList
