import {
  createSlice,
  createAsyncThunk,
  SerializedError,
  PayloadAction,
} from '@reduxjs/toolkit'
import { _apiUrl } from 'shared'

import type { INeighbour, TStatusType, INeighbourEdit } from 'shared'

export interface IEditRequest {
  method: 'PATCH' | 'PUT'
  myData: INeighbourEdit
  token: string
}

export interface IMyDataState {
  // хз почему ts заставил записать тип Error в массив соседей
  myData: INeighbour | null
  myDataStatus: TStatusType
  editMyDataStatus: TStatusType
  myFavoriteNeighbours: INeighbour[]
  myFavoriteNeighboursStatus: TStatusType
  errorMyData: string | null | SerializedError
  errorEditMyData: string | null | SerializedError
  errorStatus: number
}

const initialState: IMyDataState = {
  myData: null,
  myDataStatus: 'idle',
  editMyDataStatus: 'idle',
  myFavoriteNeighbours: [],
  myFavoriteNeighboursStatus: 'idle',
  errorMyData: null,
  errorEditMyData: null,
  errorStatus: null,
}

export const fetchMyData = createAsyncThunk(
  'myData/fetchMyData',

  async ({ token }: { token: string }) => {
    try {
      const response = await fetch(`${_apiUrl}/api/v1/me/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Token ${token}`,
        },
      })
      if (response.ok) {
        const data: INeighbour = await response.json()
        return data
      } else {
        return response.status
      }
    } catch (error) {
      return error
    }
  }
)

export interface IEditRequest {
  method: 'PATCH' | 'PUT'
  myData: INeighbourEdit
  token: string
}

export const editMyData = createAsyncThunk(
  'myData/editMyData',

  async (data: IEditRequest) => {
    const { method, myData, token } = data

    try {
      const response = await fetch(`${_apiUrl}/api/v1/me/`, {
        method: method,
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify(myData),
      })
      if (response.ok) {
        const data: INeighbourEdit = await response.json()
        return data
      } else {
        return response.status
      }
    } catch (error) {
      return error
    }
  }
)

interface NeighboursData {
  count: number
  next: string | null
  previous: string | null
  results: INeighbour[]
}

export const fetchMyFavoriteNeighbours = createAsyncThunk(
  'myData/fetchMyFavoriteNeighbours',

  async (token: string) => {

    try {
      const response = await fetch(`${_apiUrl}/api/v1/me/favorite/user/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Token ${token}`,
        },
      })
      if (response.ok) {
        const data: NeighboursData = await response.json()
        return data.results
      } else {
        return response.status
      }
    } catch (error) {
      return error
    }
  }
)

const myDataSlice = createSlice({
  name: 'myData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetchMyData
      .addCase(fetchMyData.pending, (state) => {
        state.myDataStatus = 'loading'
      })
      .addCase(fetchMyData.rejected, (state, action) => {
        state.myDataStatus = 'error'
        state.errorMyData = action.error
      })
      .addCase(
        fetchMyData.fulfilled,
        (state, action: PayloadAction<INeighbour | number>) => {
          if (typeof action.payload !== 'number') {
            state.myData = action.payload
            state.myDataStatus = 'success'
          } else {
            state.errorStatus = action.payload
            state.myDataStatus = 'error'
          }
        }
      )
      // editMyData
      .addCase(editMyData.pending, (state) => {
        state.editMyDataStatus = 'loading'
      })
      .addCase(editMyData.rejected, (state, action) => {
        state.editMyDataStatus = 'error'
        state.errorMyData = action.error
      })
      .addCase(
        editMyData.fulfilled,
        (state, action: PayloadAction<number | INeighbourEdit>) => {
          if (typeof action.payload !== 'number') {
            state.editMyDataStatus = 'success'
          } else {
            state.errorStatus = action.payload
            state.editMyDataStatus = 'error'
          }
        }
      )
      // fetchMyFavoriteNeighbours
      .addCase(fetchMyFavoriteNeighbours.pending, (state) => {
        state.myFavoriteNeighboursStatus = 'loading'
      })
      .addCase(fetchMyFavoriteNeighbours.rejected, (state, action) => {
        state.myFavoriteNeighboursStatus = 'error'
        state.errorMyData = action.error
      })
      .addCase(
        fetchMyFavoriteNeighbours.fulfilled,
        (state, action: PayloadAction<INeighbour[] | number>) => {
          if (typeof action.payload !== 'number') {
            state.myFavoriteNeighbours = action.payload
            state.myFavoriteNeighboursStatus = 'success'
          } else {
            state.errorStatus = action.payload
            state.myFavoriteNeighboursStatus = 'error'
          }
        }
      )
  },
})

export default myDataSlice.reducer

const asda = {
  id: 4,
  first_name: 'Семен',
  last_name: 'Солдатов',
  email: 'svsoldatov@edu.hse.ru',
  date_joined: '2023-09-12T22:11:51.545707+03:00',
  type_account: 'STUDENT',
  is_published: false,
  gender: 'MAN',
  birthday: '2002-04-05',
  about: 'string',
  tg_username: 'soldatov_sem',
  phone: '89286286833',
  price_min: 2647,
  price_max: 21647,
  what_neighbor: 'MAN',
  against_alcohol: true,
  against_smoking: true,
  job: 'string',
  course_number: 4,
  year_start_job: 2000,
  program_education: 1,
  county: [2, 3],
  district: [1],
  metro: [1, 3, 4],
}
