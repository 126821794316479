import { Btn, CheckboxInput, Form, Logo, routes} from 'shared'
import styles from './EntryPoint.module.scss'
import { useNavigate } from 'react-router-dom';

const EntryPoint = () => {
	const navigate = useNavigate();
	function onSubmit() {
		navigate('1')
	}
	return (
		<div className={styles.wrapper}>
			<Logo logoClassName={styles.logo}/>
			<Form onSubmit={onSubmit} id='entryPoint'>
				<div className={styles.btn_wrapper}>
					<Btn typeBtn='submit' className={styles.btn}>Хочу найти соседа</Btn>
					<CheckboxInput 
					text={<p className={styles.text}>Я соглашаюсь с <a href="https://www.hse.ru/data_protection_regulation" target='_blank' className={styles.href}>политикой конфиденциальности</a></p>}
					/>
				</div>
			</Form>
		</div>
	)
}

export default EntryPoint