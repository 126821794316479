import { AmenityCard, IRoom } from 'shared'
import type { TypeAmenity } from 'shared/ui/AmenityCard/AmenityCard'
import styles from './Amenities.module.scss'

interface Props {
  room: IRoom
}

const Amenities = (props: Props) => {
  const { room } = props
  // const room = roomCustom
  const data: {
    type: TypeAmenity
    text: string
  }[] = [
    {
      type: room.children_allowed ? 'children_allowed' : null,
      text: 'Можно с детьми',
    },
    {
      type: room.pets_allowed ? 'pets_allowed' : null,
      text: 'Можно с животными',
    },
    { type: room.garbage_chute ? 'garbage_chute' : null, text: 'Мусоропровод' },
    { type: room.furniture ? 'furniture' : null, text: 'Мебель' },
    {
      type: room.washing_machine ? 'washing_machine' : null,
      text: 'Стиральная машина',
    },
    {
      type: room.passenger_elevator ? 'passenger_elevators' : null,
      text: 'Пассажирский лифт',
    },
    { type: room.internet ? 'internet' : null, text: 'Интернет' },
    { type: room.fridge ? 'fridge' : null, text: 'Холодильник' },
    {
      type: room.freight_elevator ? 'freight_elevator' : null,
      text: 'Грузовой лифт',
    },
    { type: room.tv ? 'tv' : null, text: 'Телевизор' },
    {
      type: room.plate_type === 'ELECTRICAL' ? 'electrical_plate' : 'gas_plate',
      text:
        room.plate_type === 'ELECTRICAL'
          ? 'Электрическая плита'
          : 'Газовая плита',
    },
    {
      type: room.balcony ? 'balcony_type' : null,
      text: 'Балкон',
    },
    { type: room.conditioner ? 'conditioner' : null, text: 'Кондиционер' },
    {
      type: room.shower_type === 'BATHROOM' ? 'bathroom' : 'shower',
      text: room.shower_type === 'BATHROOM' ? 'Ванная' : 'Душ',
    },
    // { type: 'regular', text: 'Выход окон во двор' },
    {
      type: room.bathroom_is_combined ? 'sanitary_unit' : 'sanitary_unit',
      text: room.bathroom_is_combined
        ? 'Санузел совмещенный'
        : 'Санузел раздельный',
    },
    { type: room.dishwasher ? 'dishwasher' : null, text: 'Посудомойка' },
    // { type: 'regular', text: 'Закрытая парковка' },
  ]

  const elements = data.map((item, i) => {
    return (
      <AmenityCard type={`${item.type}`} text={`${item.text}`} key={`${i}`} />
    )
  })

  return (
    <>
      <div className={styles.widget}>{elements}</div>
    </>
  )
}

export default Amenities
