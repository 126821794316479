import { memo } from 'react'
import { Icon, Text, useClientWidth, adaptiveWidth } from 'shared'

import styles from './AmenityCard.module.scss'
import { Img } from 'react-image'
import bathroom from '../../../assets/icons/amenities/bathroom.svg'
import gas_plate from '../../../assets/icons/amenities/gas_plate.svg'
import freight_elevator from '../../../assets/icons/amenities/freight_elevator.svg'
import shower from '../../../assets/icons/amenities/shower.svg'
import internet from '../../../assets/icons/amenities/internet.svg'
import conditioner from '../../../assets/icons/amenities/conditioner.svg'
import passenger_elevators from '../../../assets/icons/amenities/passenger_elevators.svg'
import balcony_type from '../../../assets/icons/amenities/balcony_type.svg'
import furniture from '../../../assets/icons/amenities/furniture.svg'
import pets_allowed from '../../../assets/icons/amenities/pets_allowed.svg'
import children_allowed from '../../../assets/icons/amenities/children_allowed.svg'
import garbage_chute from '../../../assets/icons/amenities/garbage_chute.svg'
import dishwasher from '../../../assets/icons/amenities/dishwasher.svg'
import sanitary_unit from '../../../assets/icons/amenities/sanitary_unit.svg'
import washing_machine from '../../../assets/icons/amenities/washing_machine.svg'
import tv from '../../../assets/icons/amenities/tv.svg'
import fridge from '../../../assets/icons/amenities/fridge.svg'
import electrical_plate from '../../../assets/icons/amenities/electrical_plate.svg'

export type TypeAmenity =
  | 'bathroom'
  | 'gas_plate'
  | 'freight_elevator'
  | 'shower'
  | 'internet'
  | 'conditioner'
  | 'passenger_elevators'
  | 'balcony_type'
  | 'furniture'
  | 'pets_allowed'
  | 'children_allowed'
  | 'garbage_chute'
  | 'dishwasher'
  | 'sanitary_unit'
  | 'washing_machine'
  | 'tv'
  | 'fridge'
  | 'electrical_plate'
  | 'null'

interface Props {
  text: any
  type: TypeAmenity

  className?: string
}

const AmenityCard = memo((props: Props) => {
  const { text, className, type } = props
  const { getClientWidth } = useClientWidth()
  const isMobileVersion = getClientWidth() < adaptiveWidth['800px']
  let img: TypeAmenity

  switch (type) {
    case null:
      img = null
      break
    case 'balcony_type':
      img = balcony_type
      break
    case 'bathroom':
      img = bathroom
      break
    case 'children_allowed':
      img = children_allowed
      break
    case 'conditioner':
      img = conditioner
      break
    case 'dishwasher':
      img = dishwasher
      break
    case 'electrical_plate':
      img = electrical_plate
      break
    case 'freight_elevator':
      img = freight_elevator
      break
    case 'fridge':
      img = fridge
      break
    case 'furniture':
      img = furniture
      break
    case 'garbage_chute':
      img = garbage_chute
      break
    case 'gas_plate':
      img = gas_plate
      break
    case 'internet':
      img = internet
      break
    case 'passenger_elevators':
      img = passenger_elevators
      break
    case 'pets_allowed':
      img = pets_allowed
      break
    case 'sanitary_unit':
      img = sanitary_unit
      break
    case 'shower':
      img = shower
      break
    case 'tv':
      img = tv
      break
    case 'washing_machine':
      img = washing_machine
      break
  }
  return (
    <>
      {type === 'null' ? null : (
        <div className={`${styles.card}`}>
          <Img
            className={`${styles.icon} ${className}`}
            src={img}
            loader={
              <div className={`${styles.skeleton__icon} ${className}`}></div>
            }
            // container={(children) => {
            //   if (icon && locationIcon) {
            //     return <>{getImgWithIconContainer(children)}</>
            //   } else {
            //     return <div className={wrapperClass}>{children}</div>
            //   }
            // }}
          />

          <Text type={isMobileVersion ? 'mobile-small' : 'paragraph'}>
            {text}
          </Text>
        </div>
      )}
    </>
  )
})

export default AmenityCard
