// последняя версия чекбокса, для переиспользуемого нужно использовать его

import { ErrorMessage } from "@hookform/error-message"
import { useState } from "react"
import { useFormContext } from "react-hook-form"
import { Text } from 'shared'
import mark_icon from '../../../../assets/icons/functional_icons/mark_icon.svg'
import styles from './Checkbox.module.scss'

interface Props {
	text: JSX.Element | string,
}

const Checkbox = (props: Props) => {
	const {text} = props;
	const [isSelected, setSelected] = useState(false)
	const onSelect = () => {
		setSelected(isSelected => !isSelected)
	}
	const { register,
		formState: { errors },
	} = useFormContext()
	return (
		<>
			<label 
			className={`${styles.single_item} ${isSelected ? styles.single_active : styles.single_item}`}
			>
				<input
				type='checkbox'
				style={{ display: 'none' }}
				onClick={onSelect}
				{...register('policy', {
					required: 'Обязательное поле',
				})}
				/>
				<div className={styles.single_checkbox}><img src={mark_icon} alt="mark_icon" /></div>
				<p style={{display: 'inline'}}>{text}</p>
			</label>
			<ErrorMessage
			errors={errors}
			name={'policy'}
			render={({ message }) => <Text type="small" className={styles.error}>{message}</Text>}
			/>
		</>
	)
}

export default Checkbox