import React, { useEffect, useState } from 'react'
import Select, { CSSObjectWithLabel, StylesConfig, components } from 'react-select'
import { useFormContext, Controller } from 'react-hook-form'
import { Icon, IMultipleItem, ITag, Text } from 'shared'

import './SelectInput.scss'
import { ReactComponent as IconLoupe } from '../../../../assets/icons/functional_icons/loupe.svg'
import { ReactComponent as IconCross } from '../../../../assets/icons/functional_icons/cross_grey.svg'
import { ErrorMessage } from '@hookform/error-message'
import { memo } from 'react'
import { IEduProgram, IMetroSelect } from 'entities/EditAccount/types'
import { useAppDispatch } from 'app/store'
import { setMetro } from 'entities/Registration/model/RegistrationSlice'
import { IIcon } from 'shared/ui/Icon/types'
import MetroIcon from 'shared/ui/Icon/MetroIcon'

export interface ISelectItem<T = string> {
	value: T
	label: string
}

interface Props<T> {
	name: string
	label: string
	items: ISelectItem<string>[]
	onClick?: (
		val: ISelectItem<string> | IMultipleItem<string> | IMetroSelect<string>[]
	) => void
	onDelete?: (val: IMetroSelect<string>) => void
	isSearchable?: boolean
	isMulti?: boolean
	placeholder?: string
	value?: IMetroSelect<string>[] | ISelectItem[]
	type?: 'metro'
	isRequired?: boolean
	defaultValue?: string | ISelectItem<string>
	maxOptionsNumber?: number,
}

const DropdownIndicator = (props) => {
	return <IconLoupe />
}
const IndicatorSeparator = () => {
	return <IconCross />
}

const SelectInput = memo(<P extends unknown>(props: Props<P>) => {
	const {
		items,
		label,
		onClick,
		value,
		name,
		isSearchable = false,
		placeholder = '',
		isMulti,
		onDelete,
		type,
		isRequired = true,
		defaultValue,
		maxOptionsNumber,
	} = props
	const { control, formState, getFieldState, getValues } = useFormContext()
	const { errors } = formState
	let isEnough = false
	if (isMulti) {
		isEnough = value.length === maxOptionsNumber ? true : false
	}
	const { Option } = components
	const CustomOption = (props) => {
		return (
			<Option {...props} key={props.data.value}>
				{/* <div {...props} className='selectInput__menu-list'> */}
				<div className="selectInput__option_custom">
					<MetroIcon
						className="selectInput__option__icon"
						metroLines={props.data.metro_line}
					/>
					<Text type="paragraph">{props.data.label}</Text>
				</div>
				{/* </div> */}
			</Option>
		)
	}

	const selectStyles = {
		dropdownIndicator: (provided, state) => ({
			...provided,
				transition: 'all .2s ease',
				transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
			}),
			control: (base) => ({
				...base,
				boxShadow: 'none',
				border: 0,
				opacity: isEnough ? '0.5' : '1'
			}),
			option: (base) => ({
				...base,
				backgroundColor: '#ffffff',
			}),
			menuList: (base) => ({
				...base,
				'::-webkit-scrollbar': {
					width: '4px',
					height: '0px',
				},
				'::-webkit-scrollbar-track': {
					background: '#ffffff',
				},
				'::-webkit-scrollbar-thumb': {
					background: '#D9D1E0',
					width: '4px',
					borderRadius: '4px',
				},
			}),
	}
	return (
		<div className="select-input__wrapper">
				<p
					style={{
						color: getFieldState(name).invalid ? 'rgba(255, 0, 0)' : '#5D5B66',
						fontSize: '14px',
						opacity: isEnough ? '0.5' : '1'
					}}
				>
					{label}
				</p>
				<Controller
					name={name}
					control={control}
					rules={{
						required: {
							value: isRequired,
							message: 'Обязательное поле',
						},
					}}
					defaultValue={isMulti ? value : defaultValue}

					render={({ field }) => (
						<>
							<Select
								{...field}
								options={items}
								isMulti={isMulti}
								captureMenuScroll={false}
								controlShouldRenderValue={!isMulti}
								defaultValue={defaultValue}
								isDisabled={isEnough}
								onChange={
									isMulti
										? (selected: IMetroSelect<string>[]) => {
												field.onChange(selected)
												onClick(selected[selected.length - 1])
											}
										: (selected: ISelectItem) => {
												field.onChange(selected)
												onClick(selected)
											}
								}
								placeholder={placeholder ? placeholder : label}
								components={
									isSearchable
										? {
												DropdownIndicator,
												Option: type === 'metro' ? CustomOption : Option,
											}
										: {}
								}
								classNamePrefix="selectInput"
								classNames={{
									control: () =>
										getFieldState(name).invalid ? 'select_invalid' : '',
								}}
								isSearchable={isSearchable}
								styles={selectStyles}
							/>
							<div className="selectInput__items-wrapper">
								{isMulti && value
									? value.map((val) => {
										if (val){
											return (
												<div key={val.value} className="selectInput__item">
													{type === 'metro' && (
														<MetroIcon metroLines={val.metro_line} />
													)}
													<Text type="small"> {val.label}</Text>
													<div
														id={val.value}
														className="selectInput__item__icon"
														onClick={() => {
															onDelete(val)
														}}
													>
														<IconCross />
													</div>
												</div>
											)
										}
										})
									: null}
							</div>
						</>
					)}
				/>

			<ErrorMessage
				errors={errors}
				name={name}
				render={({ message }) => (
					<Text type="small" className="selectInput__error">
						{message}
					</Text>
				)}
			/>
		</div>
	)
})

export default SelectInput
