import { ReactNode } from 'react'
import styles from './Title.module.scss'

interface Props {
  type: 'h1' | 'h2' | 'h3' | 'h4'
  color?: 'purple-main' | 'white-main' | 'black-main' | 'secondary-black'
  className?: string
  children?: ReactNode
  url?: string
}

const Title = (props: Props) => {
  const { type, color = 'black-main', className = '', url } = props
  let header: JSX.Element

  if (url) {
    header = (
      <a
        href={url}
        target="_blank"
        className={`${styles.noLink} ${className} ${styles[type]} ${styles[color]}`}
        rel="noreferrer"
      >
        {props.children}
      </a>
    )
  } else if (type === 'h1' || type === 'h2') {
    header = (
      <h2 className={`${className} ${styles[type]} ${styles[color]}`}>
        {props.children}
      </h2>
    )
  } else if (type === 'h3') {
    header = (
      <h3 className={`${className} ${styles[type]} ${styles[color]}`}>
        {props.children}
      </h3>
    )
  } else if (type === 'h4') {
    header = (
      <h4 className={`${className} ${styles[type]} ${styles[color]}`}>
        {props.children}
      </h4>
    )
  }

  return <>{header}</>
}

export default Title
