import { INeighbour } from 'shared'

const trimNeighbourDescr = (
  neighbour: INeighbour,
  typeTrim: 'page' | 'card'
): string => {
  const { about } = neighbour
  let trimLength = 90
  if (typeTrim === 'card') {
    trimLength = 90
  } else if (typeTrim === 'page') {
    trimLength = 68
  }

  if (about?.length === trimLength) {
    return about
  } else if (about?.length > trimLength) {
    return `${about.slice(0, trimLength)}...`
  }
}

export default trimNeighbourDescr
