import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import {
  IEducation,
  IFaculty,
  IMultipleItem,
  IPhoto,
  ISelectItem,
  TCourse,
  TIdLevelsEducation,
  _apiUrl,
  INeighbour,
  TStatusType,
  ITag,
} from 'shared'
import { ICountry, IDistrict, IMetro, IMetroLine } from 'shared/types/neighbour'
import { TNeighbourFilters } from 'widgets/FindNeighbourFilters'
import {
  IEduProgram,
  IInitialPhotoData,
  IInitialRegistrData,
  IInputData,
  IMetroSelect,
  IRegistration,
  IRegistrationState,
  IReturnData,
} from '../../EditAccount/types'

function isFacultiesList(
  faculties: IFaculty[] | Error
): faculties is IFaculty[] {
  return 'name' in faculties[0]
}
function isEduProgramsList(
  eduPrograms: IEducation[] | Error
): eduPrograms is IEducation[] {
  return 'name' in eduPrograms[0]
}
function isMetroList(metros: IMetro[] | Error): metros is IMetro[] {
  return 'name' in metros[0]
}

function isDistrictsList(
  district: IDistrict[] | Error
): district is IDistrict[] {
  return 'name' in district[0]
}

function isCountriesList(country: ICountry[] | Error): country is ICountry[] {
  return 'name' in country[0]
}

function isTagsList(tag: ITag[] | Error): tag is ITag[] {
  return 'name' in tag[0]
}

function diff_years(dt2, dt1) {
  var diff = (dt1.getTime() - dt2.getTime()) / 1000
  diff /= 60 * 60 * 24
  return Math.abs(Math.floor(diff / 365))
}

export const fetchAllFaculties = createAsyncThunk(
  'Registration/fetchAllFaculties',
  async ({ token }: IInputData) => {
    try {
      const response = await fetch(`${_apiUrl}/api/v1/faculty/?limit=1000`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Token ${token}`,
        },
      })
      if (response.ok) {
        const data: IReturnData<IFaculty[]> = await response.json()
        return data.results
      } else {
        return response.status
      }
    } catch (error) {
      return error
    }
  }
)

export const fetchAllEduPrograms = createAsyncThunk(
  'Registration/fetchAllEduPrograms',
  async ({ token }: IInputData) => {
    try {
      const response = await fetch(
        `${_apiUrl}/api/v1/program_edu/?limit=1000`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Authorization: `Token ${token}`,
          },
        }
      )
      if (response.ok) {
        const data: IReturnData<IEducation[]> = await response.json()
        return data.results
      } else {
        return response.status
      }
    } catch (error) {
      return error
    }
  }
)

export const fetchAllEduLevels = createAsyncThunk(
  'Registration/fetchAllEduLevels',
  async ({ token }: IInputData) => {
    try {
      const response = await fetch(`${_apiUrl}/api/v1/level_edu/?limit=10`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Token ${token}`,
        },
      })
      if (response.ok) {
        const data: IReturnData<IEducation[]> = await response.json()
        return data.results
      } else {
        return response.status
      }
    } catch (error) {
      return error
    }
  }
)

export const fetchAllMetro = createAsyncThunk(
  'Registration/fetchAllMetro',
  async ({ token }: IInputData) => {
    try {
      const response = await fetch(`${_apiUrl}/api/v1/metro/?limit=1000`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Token ${token}`,
        },
      })
      if (response.ok) {
        const data: IReturnData<IMetro[]> = await response.json()
        return data.results
      } else {
        return response.status
      }
    } catch (error) {
      return error
    }
  }
)

export const fetchAllDistricts = createAsyncThunk(
  'Registration/fetchAllDistricts',
  async ({ token }: IInputData) => {
    try {
      const response = await fetch(`${_apiUrl}/api/v1/district/?limit=1000`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Token ${token}`,
        },
      })
      if (response.ok) {
        const data: IReturnData<IDistrict[]> = await response.json()
        return data.results
      } else {
        return response.status
      }
    } catch (error) {
      return error
    }
  }
)

export const fetchAllCountries = createAsyncThunk(
  'Registration/fetchAllCountries',
  async ({ token }: IInputData) => {
    try {
      const response = await fetch(`${_apiUrl}/api/v1/county/?limit=1000`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Token ${token}`,
        },
      })
      if (response.ok) {
        const data: IReturnData<ICountry[]> = await response.json()
        return data.results
      } else {
        return response.status
      }
    } catch (error) {
      return error
    }
  }
)

export const putCreateUser = createAsyncThunk(
  'registration/putCreateUser',
  async ({ token, body }: IInitialRegistrData) => {
    try {
      const response = await fetch(`${_apiUrl}/api/v1/me/`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify(body),
      })
      if (response.ok) {
        const data = await response.json()
        return data
      } else {
        return response.status
      }
    } catch (e) {
      return e
    }
  }
)

export const deletePhoto = createAsyncThunk(
  'Registration/deletePhoto',
  async ({ token, id }: { token: string; id: number }) => {
    try {
      const response = await fetch(`${_apiUrl}/api/v1/me/photo/${id}/`, {
        method: 'delete',
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      if (response.ok) {
        const data = await response.json()
        return data
      } else {
        return response.status
      }
    } catch (e) {
      return e
    }
  }
)
export const postPhoto = createAsyncThunk(
  'Registration/postPhoto',
  async ({ token, body }: IInitialPhotoData) => {
    try {
      const response = await fetch(`${_apiUrl}/api/v1/me/photo/`, {
        method: 'POST',
        headers: {
          Authorization: `Token ${token}`,
        },
        body: body,
      })
      if (response.ok) {
        const data = await response.json()
        return data
      } else {
        return response.status
      }
    } catch (e) {
      return e
    }
  }
)

export const fetchAccountData = createAsyncThunk(
  'EditAccount/fetchData',
  async ({ token }: IInputData) => {
    try {
      const response = await fetch(`${_apiUrl}/api/v1/me/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Token ${token}`,
        },
      })
      if (response.ok) {
        const data: INeighbour = await response.json()
        return data
      } else {
        return response.status
      }
    } catch (error) {
      return error
    }
  }
)
export const fetchAllTags = createAsyncThunk(
  'Registration/fetchAllTags',
  async ({ token }: IInputData) => {
    try {
      const response = await fetch(`${_apiUrl}/api/v1/user/tag/?limit=100`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Token ${token}`,
        },
      })
      if (response.ok){
        const data: IReturnData<ITag[]> = await response.json()
        return data.results
      } else {
        return response.status
      }
    } catch (error) {
      return error
    }
  }
)
const initialState: IRegistrationState = {
  data: {
    first_name: '',
    last_name: '',
    gender: null,
    birthday: '',
    age: '',
    tg_username: null,
    phone: '',
    about_me: '',
    type_account: null,
    course_number: null,
    level_education: null,
    faculty: null,
    show_phone: true,
    edu_program: null,
    admission_year: '',
    job: '',
    is_published: true,
    is_agree: false,
    photo: null,
    tags: [],
    preferences: {
      what_neighbor: null,
      against_alcohol: false,
      against_smoking: false,
      price_max: '120000',
      price_min: '0',
      metro: [],
      district: [],
      county: [],
    },
  },
  accountDataStatus: 'idle',
  errorStatus: null,
  coursesList: [
    { id: '1', text: '1', isSelected: false },
    { id: '2', text: '2', isSelected: false },
    { id: '3', text: '3', isSelected: false },
    { id: '4', text: '4', isSelected: false },
    { id: '5', text: '5', isSelected: false },
    { id: '6', text: '6', isSelected: false },
    {
      id: '7',
      text: 'Уже окончил Вышку',
      isSelected: false,
    },
  ],
  levelsEducationList: [
  ],
  facultiesListStatus: 'idle',
  facultiesList: [],
  levelsEducationListStatus: 'idle',
  eduProgramsList: [
  ],
  whatNeighbourList: [
    { id: 'MAN', text: 'Соседа', isSelected: false },
    { id: 'WOMAN', text: 'Соседку', isSelected: false },
    { id: 'NO_MATTER', text: 'Не важно', isSelected: false },
  ],
  eduProgramsListStatus: 'idle',
  wishesList: [
    {
      id: 'against_alcohol',
      text: 'Против алкоголя',
      isSelected: false,
    },
    {
      id: 'against_smoking',
      text: 'Против курения',
      isSelected: false,
    },
  ],
  photoStatus: 'idle',
  errorMessage: null,
  metroList: null,
  metroListStatus: 'idle',
  countriesList: null,
  countriesListStatus: 'idle',
  currentStepIndex: 0,
  postUserDataStatus: 'idle',
  isFirstStep: true,
  isLastStep: false,
  districtsList: [],
  districtsListStatus: 'idle',
  tagsList: [],
  tagsListStatus: 'idle',
  progress: 5,
}

const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    getProgress: (state) => {
      state.progress = 5 + Number(!!state.data.photo)*5 + Number(!!state.data.gender)*10 + Number(!!state.data.tg_username)*20 + Number(state.data.tags.length >= 1)*10 + Number(+state.data.preferences.price_max < 120000)*10 + Number(!!state.data.preferences.what_neighbor)*10 + Number(!!state.data.preferences.against_alcohol)*10 + Number(!!state.data.preferences.against_smoking)*10 + Number(state.data.preferences.metro.length >= 1)*10
    },
    registrationDataUpdate: (state, action) => {
      state.data = {
        ...state.data,
        [action.payload.fieldName]: action.payload.value,
      }
      if (action.payload.fieldName === 'birthday') {
        setBirthDate(action.payload.value)
      }
    },
    registrationFormStep: (state, action) => {
      state.currentStepIndex = action.payload
    },
    registrationIsFirstStep: (state, action) => {
      state.isFirstStep = action.payload
    },
    registrationIsLastStep: (state, action) => {
      state.isLastStep = action.payload
    },
    setBirthDate: (state, action) => {
      if (action.payload && action.payload !== 'null') {
        state.data.birthday = action.payload
        let d1 = new Date(state.data.birthday)
        let d2 = new Date()
        state.data.age = diff_years(d1, d2).toString()
      }
    },
    setCoursesList: (state, action: PayloadAction<IMultipleItem<string>>) => {
      // state.data.course_number = state.coursesList.find(course => course.id === action.payload).text; это текст
      state.data.course_number = {
        value: action.payload.id,
        label: action.payload.text,
      }
    },
    setWhatNeighbour: (
      state,
      action: PayloadAction<INeighbour['what_neighbor']>
    ) => {
      state.data.preferences.what_neighbor = action.payload
    },
    setLevelEducation: (state, action: PayloadAction<ISelectItem<string>>) => {
      state.data.level_education = action.payload
    },
    setFaculty: (state, action: PayloadAction<ISelectItem<string>>) => {
      state.data.faculty = action.payload
    },
    setEduProgram: (state, action: PayloadAction<IEduProgram<string>>) => {
      // нужно в edu_program запоминать id выбранной образовательной программы, которая есть в АПИ /api/v1/program_edu/
      state.data.edu_program = action.payload
    },
    setEduProgramsList: (
      state,
      action: PayloadAction<IEduProgram<string>[]>
    ) => {
      state.eduProgramsList = action.payload
    },
    filterEduProgramsByLevel: (state) => {
      state.eduProgramsList = state.eduProgramsList.filter(
        (program) =>
          program.level_education.label === state.data.level_education.label
      )
    },
    filterEduProgramsByFaculty: (state) => {
      state.eduProgramsList = state.eduProgramsList.filter(
        (program) => program.faculty.label === state.data.faculty.label
      )
    },
    setAgainstAlcohol: (state) => {
      state.wishesList = state.wishesList.map((wish) => {
        if (wish.id === 'against_alcohol') {
          if (wish.isSelected) {
            state.data.preferences.against_alcohol = false
          } else {
            state.data.preferences.against_alcohol = true
          }
          return {
            ...wish,
            isSelected: !wish.isSelected,
          }
        } else {
          return wish
        }
      })
    },
    setAgainstSmoking: (state) => {
      state.wishesList = state.wishesList.map((wish) => {
        if (wish.id === 'against_smoking') {
          if (wish.isSelected) {
            state.data.preferences.against_smoking = false
          } else {
            state.data.preferences.against_smoking = true
          }
          return {
            ...wish,
            isSelected: !wish.isSelected,
          }
        } else {
          return wish
        }
      })
    },
    setPriceMax: (
      state,
      action: PayloadAction<TNeighbourFilters['price_max']>
    ) => {
      state.data.preferences.price_max = action.payload
    },
    setPriceMin: (
      state,
      action: PayloadAction<TNeighbourFilters['price_min']>
    ) => {
      state.data.preferences.price_min = action.payload
    },
    setMetro: (state, action: PayloadAction<IMetroSelect<string>[]>) => {
      state.data.preferences.metro = action.payload
    },
    setDistrict: (state, action: PayloadAction<ISelectItem[]>) => {
      state.data.preferences.district = action.payload
    },
    setCountry: (state, action: PayloadAction<ISelectItem[]>) => {
      state.data.preferences.county = action.payload
    },
    setTag: (state, action: PayloadAction<ISelectItem[]>) => {
      state.data.tags = action.payload
    },
    resetPhoto: (state) => {
      state.data.photo = null
    },
    setPostUserDataStatus: (state, action: PayloadAction<TStatusType>) => {
      state.postUserDataStatus = 'idle'
    },
    resetRegErrorStatus: (state) => {
      state.errorStatus = null;
    }
  },
  extraReducers: (builder) => {
    // fetchAllFaculties
    builder.addCase(fetchAllFaculties.pending, (state) => {
      state.facultiesListStatus = 'loading'
    })
    builder.addCase(fetchAllFaculties.rejected, (state) => {
      state.facultiesListStatus = 'error'
    })
    builder.addCase(
      fetchAllFaculties.fulfilled,
      (state, action: PayloadAction<IFaculty[] | number>) => {
        if (typeof action.payload === 'number') {
          state.errorStatus = action.payload
          state.facultiesListStatus = 'error'
        } else {
          if (isFacultiesList(action.payload)) {
            state.facultiesList = action.payload.map((facul) => {
              return {
                value: facul.id.toString(),
                label: facul.name,
              }
            })
          }
          // state.allFaculties = action.payload
          state.facultiesListStatus = 'success'
        }
      }
    )

    // fetchAllEduPrograms
    builder.addCase(fetchAllEduPrograms.pending, (state) => {
      state.eduProgramsListStatus = 'loading'
    })
    builder.addCase(fetchAllEduPrograms.rejected, (state) => {
      state.eduProgramsListStatus = 'error'
    })
    builder.addCase(
      fetchAllEduPrograms.fulfilled,
      (state, action: PayloadAction<IEducation[] | number>) => {
        if (typeof action.payload === 'number') {
          state.errorStatus = action.payload
          state.eduProgramsListStatus = 'error'
        } else {
          if (isEduProgramsList(action.payload)) {
            state.eduProgramsList = action.payload.map((program) => {
              return {
                value: program.id.toString(),
                label: program.name,
                short_name: program.short_name,
                faculty: {
                  value: program.faculty.id.toString(),
                  label: program.faculty.name,
                },
                level_education: {
                  value: program.level_education.id.toString(),
                  label: program.level_education.name,
                },
              }
            })
          }
          state.eduProgramsListStatus = 'success'
        }
      }
    )

    // fetchAllEduLevels
    builder.addCase(fetchAllEduLevels.pending, (state) => {
      state.levelsEducationListStatus = 'loading'
    })
    builder.addCase(fetchAllEduLevels.rejected, (state) => {
      state.levelsEducationListStatus = 'error'
    })
    builder.addCase(
      fetchAllEduLevels.fulfilled,
      (state, action: PayloadAction<IEducation[] | number>) => {
        if (typeof action.payload === 'number') {
          state.errorStatus = action.payload
          state.levelsEducationListStatus = 'error'
        } else {
          if (isFacultiesList(action.payload)) {
            state.levelsEducationList = action.payload.map((level) => {
              return {
                value: level.id.toString(),
                label: level.name,
              }
            })
          }
          state.levelsEducationListStatus = 'success'
        }
      }
    )

    // fetchAllMetro
    builder.addCase(fetchAllMetro.pending, (state) => {
      state.metroListStatus = 'loading'
    })
    builder.addCase(fetchAllMetro.rejected, (state) => {
      state.metroListStatus = 'error'
    })
    builder.addCase(
      fetchAllMetro.fulfilled,
      (state, action: PayloadAction<IMetro[] | number>) => {
        if (typeof action.payload === 'number') {
          state.errorStatus = action.payload
          state.metroListStatus = 'error'
        } else {
          if (isMetroList(action.payload)) {
            state.metroList = action.payload.map((metro) => {
              return {
                value: metro.id.toString(),
                label: metro.name,
                metro_line: [...metro.metro_line],
              }
            })
          }
          state.metroListStatus = 'success'
        }
      }
    )

    // fetchAllDistricts
    builder.addCase(fetchAllDistricts.pending, (state) => {
      state.districtsListStatus = 'loading'
    })
    builder.addCase(fetchAllDistricts.rejected, (state) => {
      state.districtsListStatus = 'error'
    })
    builder.addCase(
      fetchAllDistricts.fulfilled,
      (state, action: PayloadAction<IDistrict[] | number>) => {
        if (typeof action.payload === 'number') {
          state.errorStatus = action.payload
          state.districtsListStatus = 'error'
        } else {
          if (isDistrictsList(action.payload)) {
            state.districtsList = action.payload.map((metro) => {
              return {
                value: metro.id.toString(),
                label: metro.name,
              }
            })
          }
          state.districtsListStatus = 'success'
        }
      }
    )

    // fetchAllCountries
    builder.addCase(fetchAllCountries.pending, (state) => {
      state.countriesListStatus = 'loading'
    })
    builder.addCase(fetchAllCountries.rejected, (state) => {
      state.countriesListStatus = 'error'
    })
    builder
      .addCase(
        fetchAllCountries.fulfilled,
        (state, action: PayloadAction<ITag[] | number>) => {
          if (typeof action.payload === 'number') {
            state.errorStatus = action.payload
            state.countriesListStatus = 'error'
          } else {
            if (isCountriesList(action.payload)) {
              state.countriesList = action.payload.map((metro) => {
                return {
                  value: metro.id.toString(),
                  label: metro.name,
                }
              })
            }
            state.countriesListStatus = 'success'
          }
        }
      )

      // deletePhoto
      .addCase(deletePhoto.pending, (state) => {
        state.photoStatus = 'loading'
        state.errorMessage = null
      })
      .addCase(
        deletePhoto.fulfilled,
        (state, action: PayloadAction<number | null>) => {
          if (typeof action.payload === 'number') {
            state.errorStatus = action.payload
            state.photoStatus = 'error'
          } else {
            state.photoStatus = 'success'
            state.errorMessage = null
          }
        }
      )
      .addCase(deletePhoto.rejected, (state, action) => {
        state.photoStatus = 'error'
        state.errorMessage = action.error.message
      })

      // postPhoto
      .addCase(postPhoto.pending, (state, action) => {
        state.photoStatus = 'loading'
        state.errorMessage = null
      })
      .addCase(
        postPhoto.fulfilled,
        (state, action: PayloadAction<number | IPhoto>) => {
          if (typeof action.payload === 'number') {
            state.errorStatus = action.payload
            state.photoStatus = 'error'
          } else {
            state.photoStatus = 'success'
            state.data.photo = action.payload
            state.errorMessage = null
          }
        }
      )
      .addCase(postPhoto.rejected, (state, action) => {
        state.photoStatus = 'error'
        state.errorMessage = action.error.message
      })

      // putCreateUser
      .addCase(putCreateUser.pending, (state) => {
        state.postUserDataStatus = 'loading'
        state.errorMessage = null
      })
      .addCase(putCreateUser.rejected, (state) => {
        state.postUserDataStatus = 'error'
      })
      .addCase(
        putCreateUser.fulfilled,
        (state, action: PayloadAction<number | null>) => {
          if (typeof action.payload === 'number') {
            state.errorStatus = action.payload
            state.postUserDataStatus = 'error'
          } else {
            state.postUserDataStatus = 'success'
            state.errorMessage = null
          }
        }
      )

    // fetchAccountData
    builder.addCase(fetchAccountData.pending, (state) => {
      state.accountDataStatus = 'loading'
    })
    builder.addCase(fetchAccountData.rejected, (state) => {
      state.accountDataStatus = 'error'
    })
    builder.addCase(
      fetchAccountData.fulfilled,
      (state, action: PayloadAction<INeighbour>) => {
        if (typeof action.payload === 'number') {
          state.errorStatus = action.payload
          state.accountDataStatus = 'error'
        } else {
          state.data.first_name = action.payload.first_name
          state.data.last_name = action.payload.last_name
          state.data.gender = action.payload.gender
          state.data.birthday = action.payload.birthday
          state.data.tg_username = action.payload.tg_username
          // state.data.phone = action.payload.phone
          // state.data.about_me = action.payload.about
          // state.data.type_account = action.payload.type_account
          // if (action.payload.type_account === 'STUDENT'){
          //   state.data.course_number = {
          //     value: action.payload.course_number.toString(),
          //     label: action.payload.course_number.toString(),
          //   }
          //   state.data.level_education = {
          //     value:
          //       action.payload.program_education.level_education.id.toString(),
          //     label: state.levelsEducationList.find(
          //       (item) =>
          //         item.value ===
          //         action.payload.program_education?.level_education.id.toString()
          //     )?.label,
          //   }
          //   state.data.faculty = {
          //     value: action.payload.program_education?.faculty.id.toString(),
          //     label: action.payload.program_education?.faculty.name,
          //   }
          //   state.data.edu_program = {
          //     label: action.payload.program_education?.name,
          //     value: action.payload.program_education?.id.toString(),
          //     short_name: action.payload.program_education?.short_name,
          //     level_education: state.data.level_education,
          //     faculty: state.data.faculty,
          //   }
          // } else {
          //   state.data.admission_year = action.payload.year_start_job?.toString()
          // }
          state.data.photo =
            action.payload.photo[action.payload.photo.length - 1]
          // state.data.job = action.payload.job
          state.data.show_phone = action.payload.show_phone
          state.data.tags = action.payload.tag.map(tag => {
            return {
              value: tag.id.toString(),
              label: tag.name
            }
          })
          state.data.preferences = {
            what_neighbor: action.payload.what_neighbor,
            against_alcohol: action.payload.against_alcohol,
            against_smoking: action.payload.against_smoking,
            price_max: action.payload.price_max.toString(),
            price_min: action.payload.price_min.toString(),
            metro: action.payload.metro.map((metro) => {
              return {
                value: metro.id.toString(),
                label: metro.name,
                metro_line: metro.metro_line,
              }
            }),
            district: action.payload.district.map((district) => {
              return {
                value: district.id.toString(),
                label: district.name,
              }
            }),
            county: action.payload.county.map((county) => {
              return {
                value: county.id.toString(),
                label: county.name,
              }
            }),
          }

          state.accountDataStatus = 'success'
        }
      }
    )

    // fetchAllTags
    .addCase(fetchAllTags.pending, state => {
      state.tagsListStatus = 'loading'
    })
    .addCase(fetchAllTags.rejected, state => {
      state.tagsListStatus = 'error'
    })
    .addCase(fetchAllTags.fulfilled, 
      (state, action : PayloadAction<ITag[] | number>) => {
        if (typeof action.payload === 'number') {
          state.errorStatus = action.payload
          state.tagsListStatus = 'error'
        } else {
          if (isTagsList(action.payload)) {
            state.tagsList = action.payload.map((tag) => {
              return {
                value: tag.id.toString(),
                label: tag.name,
              }
            })
          }
          state.tagsListStatus = 'success';
        }
      })
  },
})

const { actions } = registrationSlice
export const {
  registrationDataUpdate,
  registrationFormStep,
  registrationIsFirstStep,
  registrationIsLastStep,
  setCoursesList,
  setLevelEducation,
  setFaculty,
  setEduProgram,
  setEduProgramsList,
  filterEduProgramsByLevel,
  filterEduProgramsByFaculty,
  setBirthDate,
  setWhatNeighbour,
  setAgainstAlcohol,
  setAgainstSmoking,
  setPriceMax,
  setPriceMin,
  setMetro,
  setDistrict,
  setCountry,
  resetPhoto,
  setPostUserDataStatus,
  setTag,
  resetRegErrorStatus,
  getProgress,
} = actions

export default registrationSlice.reducer
