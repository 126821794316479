import {Text} from 'shared'
import styles from './Toast.module.scss'


interface Props{
	color?: 'red' | 'black-main',
	isCancelBtn?: boolean,
	onClick?: () => void, 
	label: string,
	className?: string, 
	isVisible: boolean,
	closeToast: () => void
}
const Toast = (props: Props) => {
	const {color, isCancelBtn, label, className, closeToast, isVisible} = props
	const onCancel = () => {
		closeToast()
	}
	return ( 
		<>
			{isVisible ?
				(<div className={`${styles.wrapper} ${className}`}>
				<Text type='paragraph' color={color}>{label}</Text>
				{/* {isCancelBtn ? 
				<Text type='paragraph' color='purple-main' onClick={onCancel} className={styles.cancel}>Отменить</Text>
				: null} */}
				</div>) 
			: null }
		</>
	)
}

export default Toast