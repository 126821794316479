import { Text } from 'shared'

import './MultipleFilter.css'

export interface IMultipleItem<T = string> {
  text: string
  isSelected: boolean
  id: T // параметр, который заносим в один из filtersSlice и потом в параметры адресной строки
}
interface Props<T> {
  label: string | JSX.Element
  items: IMultipleItem<T>[]
  onClick: (id: string) => void
  wrapperClass?: string
  itemClass?: string
  value?: null
}
// прописать дженетики для того чтобы можно было гибко настраивать каждый тип MultipleItem
// у который все filterId будут зависить от передаваемых типов (например, пол, предпочтения по сосдеу. преимущества и тд)
const MultipleFilter = <P extends unknown>(props: Props<P>): JSX.Element => {
  let { label, onClick, items, itemClass, wrapperClass } = props
  let elements = items.map((item, ind) => {
    return (
      <div
        key={ind}
        onClick={() => onClick(item.id as string)}
        className={`${itemClass} ${item.isSelected ? 'item_selected' : 'item'}`}
      >
        <Text
          type="paragraph"
          color={`${item.isSelected ? 'white-main' : 'black-main'}`}
        >
          {item.text}
        </Text>
      </div>
    )
  })
  return (
    <>
      <div>
        <Text type="small" color="secondary-black">
          {label}
        </Text>
        <div className={`wrapper ${wrapperClass}`}>{elements}</div>
      </div>
    </>
  )
}

export default MultipleFilter
