import { memo } from 'react'
import { Title, Text, useClientWidth, adaptiveWidth } from 'shared'

import styles from './RoomDescription.module.scss'

interface Props {
  descr: string
}

const RoomDescription = memo((props: Props) => {
  const { descr } = props
  const { getClientWidth } = useClientWidth()
  const isMobileVersion = getClientWidth() < adaptiveWidth['800px']
  return (
    <>
      <div className={styles.wrapper}>
        <Title type={isMobileVersion ? 'h4' : 'h3'}>Описание</Title>
        <Text
          type={isMobileVersion ? 'small' : 'paragraph'}
          color="secondary-black"
        >
          {descr}
        </Text>
      </div>
    </>
  )
})

export default RoomDescription
