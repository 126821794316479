// @ts-nocheck
import {
  forwardRef,
  HTMLAttributes,
  PropsWithoutRef,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react'
import { setRouteToMetro, setAddress } from 'widgets/CreateAdForm'
import { Btn, IRoute } from '../../index'
import { useAppDispatch } from '../../../app/store'
import { clearMetroName } from 'shared/lib/clearMetroName'

interface Props extends HTMLAttributes<HTMLDivElement> {
  inputRef?: RefObject<HTMLInputElement>
  address?: string
}

export const Map = forwardRef<HTMLButtonElement, PropsWithoutRef<Props>>(
  (props, ref) => {
    const { inputRef, className, address } = props
    const dispatch = useAppDispatch()

    const [errorMes, setError] = useState('')
    const [message, setMessage] = useState('')
    const [route, setRoute] = useState(null)
    // фактически, тут неправильно написано - мы ещё не установили карту
    const [map, setMap] = useState(null)
    // const inputRef = useRef(null)
    const mapDiv = useRef(null)
    const btnRef = useRef(null)
    const defaultAddress = 'Москва, Покровский бульвар, 11с1'
    const example = address || defaultAddress

    useEffect(() => {
      // @ts-ignore
      if (window.ymaps) {
        // @ts-ignore
        window.ymaps.ready(init).then(() => {
          setMap((map) => {
            if (map) {
              map.destroy()
            }
          })
          geocode(example)
        })
      }
    }, [example])

    function init() {
      // Подключаем поисковые подсказки к полю ввода.
      // @ts-ignore
      var suggestView = new ymaps.SuggestView(inputRef.current)
      // @ts-ignore
      const map = new ymaps.Map(mapDiv.current, {
        center: [55.753691, 37.648187],
        zoom: 16,
      })
      setMap(map)
    }

    function showGeoObject(obj, isValidAdress: boolean) {
      setMap((map) => {
        if (map) {
          map.destroy()
        }
      })
      // console.log(obj.properties.get('metaDataProperty'))
      // console.log(obj.geometry.getCoordinates())
      let bounds = obj.properties.get('boundedBy'),
        // Рассчитываем видимую область для текущего положения пользователя.
        // @ts-ignore
        mapState = window.ymaps.util.bounds.getCenterAndZoom(
          bounds,
          [1400, 400]
        ),
        // Сохраняем полный адрес для сообщения под картой.
        address = [obj.getCountry(), obj.getAddressLine()].join(', '),
        // Сохраняем укороченный адрес для подписи метки.
        shortAddress = [
          obj.getThoroughfare(),
          obj.getPremiseNumber(),
          obj.getPremise(),
        ].join(' ')
      // Убираем контролы с карты.
      // @ts-ignore
      mapState.controls = []
      // Создаём карту.
      // console.log(mapState)
      // @ts-ignore
      const newMap = new ymaps.Map(mapDiv.current, mapState)
      // @ts-ignore
      const placemark = new ymaps.Placemark(
        newMap.getCenter(),
        {
          iconCaption: shortAddress,
          balloonContent: shortAddress,
        },
        {
          preset: 'islands#redHomeCircleIcon',
        }
      )

      newMap.geoObjects.add(placemark)
      // @ts-ignore
      newMap.setCenter(mapState.center, mapState.zoom)
      // @ts-ignore
      placemark.geometry.setCoordinates(mapState.center)
      // @ts-ignore
      placemark.properties.set({
        iconCaption: shortAddress,
        balloonContent: shortAddress,
      })
      setMap(newMap)

      // Выводим сообщение под картой.
      setMessage(address)
      // console.log(obj.geometry.getCoordinates())
      // console.log('before function')
      if (isValidAdress) {
        findNearestMetroStations(obj.geometry.getCoordinates())
      }
    }

    const findNearestMetroStations = (coordinatesAdress) => {
      // Поиск ближайших станций метро
      // @ts-ignore
      window.ymaps
        .geocode(coordinatesAdress, { kind: 'metro', results: 4 })
        .then(function (res) {
          const obj1 = res.geoObjects.get(0)
          // любое имя станции будет начинаться либо с "метро", либо с "станция"
          const name1 = obj1.properties.get('name')
          const obj2 = res.geoObjects.get(1)
          const name2 = obj2.properties.get('name')
          const obj3 = res.geoObjects.get(2)
          const name3 = obj3.properties.get('name')

          addRoute(
            coordinatesAdress,
            obj1.geometry.getCoordinates(),
            clearMetroName(name1)
          )

          addRoute(
            coordinatesAdress,
            obj2.geometry.getCoordinates(),
            clearMetroName(name2)
          )
          addRoute(
            coordinatesAdress,
            obj3.geometry.getCoordinates(),
            clearMetroName(name3)
          )
        })
    }

    const addRoute = (
      coordinatesAddress,
      coordinatesMassTransit,
      nameMetro: string
    ): number => {
      // @ts-ignore
      let multiRouteMasstransit = new ymaps.multiRouter.MultiRoute(
        {
          referencePoints: [coordinatesAddress, coordinatesMassTransit],
          params: {
            routingMode: 'masstransit',
          },
        },
        {
          // Автоматически устанавливать границы карты так, чтобы маршрут был виден целиком.
          boundsAutoApply: true,
        }
      )

      // Подписка на событие обновления данных маршрута аН общественном
      multiRouteMasstransit.model.events.add('requestsuccess', function () {
        // Получение ссылки на активный маршрут.
        // поэтому метод getActiveRoute() вернет объект <u>multiRouter.driving.Route</u>.
        var activeRoute = multiRouteMasstransit.getActiveRoute()
        // Вывод информации о маршруте.
        // console.log(nameMetro)
        // console.log(
        //   'Длина на общественном: ' +
        //     activeRoute.properties.get('distance').text
        // )
        // console.log(
        //   'Время прохождения на общественном: ' +
        //     activeRoute.properties.get('duration').text
        // )
        const timeToMetro = Number(
          activeRoute.properties.get('duration').text.split('мин')[0]
        )

        dispatch(
          setRouteToMetro({
            destination: nameMetro,
            time: timeToMetro,
            type: 'IN_PUBLIC',
          })
        )
      })
      // console.log('after all')

      let multiRoutePedestrian = new ymaps.multiRouter.MultiRoute(
        {
          referencePoints: [coordinatesAddress, coordinatesMassTransit],
          params: {
            routingMode: 'pedestrian',
          },
        },
        {
          // Автоматически устанавливать границы карты так, чтобы маршрут был виден целиком.
          boundsAutoApply: true,
        }
      )

      // Подписка на событие обновления данных маршрута.
      multiRoutePedestrian.model.events.add('requestsuccess', function () {
        // Получение ссылки на активный маршрут.
        // поэтому метод getActiveRoute() вернет объект <u>multiRouter.driving.Route</u>.
        var activeRoute = multiRoutePedestrian.getActiveRoute()
        // Вывод информации о маршруте.
        // console.log(nameMetro)
        // console.log(
        //   'Длина пешком: ' + activeRoute.properties.get('distance').text
        // )
        // console.log(
        //   'Время прохождения пешком: ' +
        //     activeRoute.properties.get('duration').text
        // )
        const timeToMetro = Number(
          activeRoute.properties.get('duration').text.split('мин')[0]
        )

        dispatch(
          setRouteToMetro({
            destination: nameMetro,
            time: timeToMetro,
            type: 'ON_FOOT',
          })
        )
      })
      // let multiAddon = new ymaps.multiRouter.EditorAddon()
      // console.log(multiRoute.geometry)
      // setRoute(multiRoute)
      // console.log(map)
      // map.geoObjects.add(multiRoute)
      // console.log('add route at the map')
    }

    function geocode(value?: string) {
      // Геокодируем введённые данные.
      const geoinfo = inputRef.current.value || value
      // @ts-ignore
      window.ymaps.geocode(geoinfo).then(function (res) {
        var obj = res.geoObjects.get(0),
          error,
          hint

        const typeAdress = obj.properties.get(
          'metaDataProperty.GeocoderMetaData.precision'
        )

        if (obj) {
          // Об оценке точности ответа геокодера можно прочитать тут: https://tech.yandex.ru/maps/doc/geocoder/desc/reference/precision-docpage/
          switch (
            // @ts-ignore
            obj.properties.get('metaDataProperty.GeocoderMetaData.precision')
          ) {
            // @ts-ignore
            case 'exact':
              // mapRef.geoObjects.add(res.geoObjects)
              break
            // @ts-ignore
            case 'number':
            // @ts-ignore
            case 'near':
            // @ts-ignore
            case 'range':
              error = 'Неточный адрес, требуется уточнение'
              hint = 'Уточните номер дома'
              break
            // @ts-ignore
            case 'street':
              error = 'Неполный адрес, требуется уточнение'
              hint = 'Уточните номер дома'
              break
            // @ts-ignore
            default:
              error = 'Неточный адрес, требуется уточнение'
              hint = 'Уточните адрес'
          }
        } else {
          error = 'Адрес не найден'
          hint = 'Уточните адрес'
        }

        // границы найденного геообъекта на карте
        //   console.log(obj.properties.get('boundedBy'))
        // получаем страну объекта
        //   console.log(obj.getCountry())
        // получаем полную адресную строку найденного геообъекта

        const isValidAdress =
          obj &&
          typeAdress !== 'range' &&
          typeAdress !== 'street' &&
          value !== defaultAddress

        if (isValidAdress) {
          // заносим в стор только адрес, который выбирает пользователь, а не дефолтный (сейчас Покровка)
          dispatch(
            setAddress({
              address: obj.getAddressLine(),
              address_x: obj.geometry.getCoordinates()[0],
              address_y: obj.geometry.getCoordinates()[1],
            })
          )
        }

        // Если геокодер возвращает пустой массив или неточный результат, то показываем ошибку.
        if (error) {
          //   showError(error)
          setError(error)
          setMessage(hint)
        } else {
          setError('')
          showGeoObject(obj, isValidAdress)
        }
      })
    }

    return (
      <>
        <div
          className={className}
          style={{ width: '100%', height: '400px' }}
          ref={mapDiv}
          id="map"
        ></div>
        <div id="header">
          <button
            ref={ref}
            onClick={() => {
              geocode()
            }}
            type="button"
            style={{ display: 'none' }}
          >
            Проверить
          </button>
        </div>
      </>
    )
  }
)

Map.displayName = 'Map'
