import styles from './DoubleInput.module.scss'
import { Text, TextInput } from 'shared'
import { useFormContext } from 'react-hook-form'
import { useState, FC, useEffect } from 'react'
import { ErrorMessage } from '@hookform/error-message'

interface Props {
  firstValue?: string | number
  secondValue?: string | number
}

export const DoubleInput: FC<Props> = ({ firstValue, secondValue }) => {
  const { register, formState, trigger, getValues, setValue, getFieldState } =
    useFormContext()

  const {
    register: secondRegister,
    trigger: secondTrigger,
    getFieldState: secondFieldState,
  } = useFormContext()

  const { errors, isValid } = formState

  // let regularExpr = /^\d+$/;
  let regularExpr = /^\d+$/

  const [isFocused, setIsFocused] = useState(false)
  const [isSecondFocused, setIsSecondFocused] = useState(false)
  const setValidation = async (e) => await trigger(e.target.name)
  const handleFocus = () => {
    setIsFocused(true)
  }
  const handleFocusSecond = () => {
    setIsSecondFocused(true)
  }
  const handleBlur = (e) => {
    if (e.target.value === '') {
      setIsFocused(false)
    } else {
      setIsFocused(true)
    }
    setValidation(e)
  }

  const handleBlurSecond = (e) => {
    if (e.target.value === '') {
      setIsSecondFocused(false)
    } else {
      setIsSecondFocused(true)
    }
    setValidation(e)
  }

  const labelStyles =
    isFocused ||
    formState.dirtyFields['floor'] ||
    isSecondFocused ||
    formState.dirtyFields['max_floor']
      ? {
          left: 0,
          top: '-22px',
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '22px',
          color:
            getFieldState('floor').invalid || getFieldState('max_floor').invalid
              ? '#FF0000'
              : '#5D5B66',
        }
      : {
          fontSize: '14px',
          left: 0,
          top: '-22px',
          fontWeight: '400',
          lineHeight: '22px',
          color:
            getFieldState('floor').invalid || getFieldState('max_floor').invalid
              ? '#FF0000'
              : '#5D5B66',
        }

  const inputStyles = {
    background:
      getFieldState('floor').invalid || getFieldState('max_floor').invalid
        ? 'rgba(255, 0, 0, 0.05)'
        : '',
    border:
      getFieldState('floor').invalid || getFieldState('max_floor').invalid
        ? '1px solid var(--ff-0000-button-red, #F00)'
        : '',
  }

  useEffect(() => {
    if (firstValue && secondValue) {
      setValue('floor', firstValue)
      setValue('max_floor', secondValue)
    }
  }, [firstValue, secondValue])

  return (
    <>
      <div className={styles.wrapper} style={inputStyles}>
        <label className={styles.label} style={labelStyles} htmlFor={'floor'}>
          Этажи
        </label>
        <input
          {...register('floor', {
            required: {
              value: true,
              message: 'Обязательные поля',
            },
            pattern: {
              value: regularExpr,
              message: 'Неверный формат ввода',
            },
          })}
          key={'floor'}
          id={'floor'}
          className={`${styles.input}`}
          autoComplete="off"
          onFocus={handleFocus}
          onBlur={handleBlur}
          style={inputStyles}
          type={'number'}
        />

        <Text type={'small'} color={'grey'}>
          из
        </Text>

        <input
          {...secondRegister('max_floor', {
            required: {
              value: true,
              message: 'Обязательные поля',
            },
            pattern: {
              value: regularExpr,
              message: 'Неверный формат ввода',
            },
            validate: (value) =>
              +value > +getValues('floor') || +value === +getValues('floor'),
          })}
          key={'max_floor'}
          id={'max_floor'}
          className={`${styles.input}`}
          autoComplete="off"
          onFocus={handleFocusSecond}
          onBlur={handleBlurSecond}
          style={inputStyles}
          type={'number'}
        />
      </div>
    </>
  )
}
