import React, { useLayoutEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'app/store'

import {
  AddAndRemoveFavourites,
  FavouritiesProps,
} from 'features/AddAndRemoveFavourites'
import { RoomCard } from 'entities/Room'
import { fetchAllRooms, roomSelectors } from 'entities/Room'
import { getSearchRoomsParams } from 'entities/Room'
import {
  Title,
  useToken,
  Text,
  useClientWidth,
  adaptiveWidth, useRegistered,
  Container,
} from 'shared'

import styles from './RoomList.module.scss'

const RoomList = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { getToken } = useToken()
  const token = getToken()
  const dispatch = useAppDispatch()
  const { getClientWidth } = useClientWidth()

  useLayoutEffect(() => {
    dispatch(resetRoomData())
    dispatch(
      fetchAllRooms({
        token,
        searchParams: getSearchRoomsParams(searchParams),
      })
    )
  }, [])
  const errorStatus = useSelector(roomSelectors.errorStatus)
  const { redirectToAuth } = useRegistered()
  const roomsArray = useSelector(roomSelectors.roomsArray)
  const roomsArrayStatus = useSelector(roomSelectors.roomsArrayStatus)
  const resetRoomData = roomSelectors.resetRoomData
  if (errorStatus === 401) {
    redirectToAuth()
  }
  const roomCards = useMemo(() => {
    if (roomsArrayStatus === 'success' && roomsArray.length !== 0) {
      const addAndRemoveFavourites = (props: FavouritiesProps) => {
        return (
          <AddAndRemoveFavourites
            entity={props.entity}
            isFavorite={props.isFavorite}
            id={props.id}
            className={props.className}
          />
        )
      }
      return roomsArray.map((room) => (
        <RoomCard
          key={room.id}
          room={room}
          addAndRemoveFavourites={addAndRemoveFavourites}
        />
      ))
    } else if (roomsArrayStatus === 'success' && roomsArray.length === 0) {
      if (getClientWidth() < adaptiveWidth['572px']) {
        return (
          <>
            <div className={styles.empty__wrapper}>
              <Text weight="bold" type="paragraph">
                По этому запросу никого не нашлось
              </Text>
              <Text
                type="small"
                color={'secondary-black'}
                className={styles.empty__text}
              >
                Попробуйте поменять фильтры и поискать заново
              </Text>
            </div>
          </>
        )
      } else {
        return (
          <>
            <div className={styles.empty__wrapper}>
              <Title type="h4">По этому запросу никого не нашлось</Title>
              <Text
                type="paragraph"
                color={'secondary-black'}
                className={styles.empty__text}
              >
                Попробуйте поменять фильтры и поискать заново
              </Text>
            </div>
          </>
        )
      }
    } else if (roomsArrayStatus === 'error') {
      if (getClientWidth() < adaptiveWidth['572px']) {
        return (
          <div className={styles.empty__wrapper}>
            <Text weight="bold" type="paragraph">
              Произошла ошибка.{' '}
            </Text>
            <Text
              type="small"
              color={'secondary-black'}
              className={styles.empty__text}
            >
              Попробуйте сбросить фильтры и заново начать поиск
            </Text>
          </div>
        )
      } else {
        return (
          <div className={styles.empty__wrapper}>
            <Title type="h4">Произошла ошибка. </Title>
            <Text
              type="paragraph"
              color={'secondary-black'}
              className={styles.empty__text}
            >
              Попробуйте сбросить фильтры и заново начать поиск
            </Text>
          </div>
        )
      }
    }
  }, [roomsArrayStatus])

  if (getClientWidth() < adaptiveWidth['800px']) {
    return <div className={styles.cards}>{roomCards}</div>
  } else {
    return <Container className={styles.cards}>{roomCards}</Container>
  }
}

export default RoomList
