import {
  IMultipleItem,
  MultipleFormFilter,
  CheckboxesInput,
  Range,
  useToken,
  SelectInput,
  ISelectItem,
  useRegistered,
  INeighbour,
} from 'shared'
import { RegistrationSelectors } from 'entities/EditAccount'

import { memo, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import styles from './PreferencesStep.module.scss'
import { useAppDispatch } from 'app/store'
import { TNeighbourFilters } from 'widgets/FindNeighbourFilters'
import {
  setPriceMax,
  setPriceMin,
  setWhatNeighbour,
  setAgainstAlcohol,
  setAgainstSmoking,
  fetchAllMetro,
  setMetro,
  fetchAllDistricts,
  setDistrict,
  fetchAllCountries,
  setCountry,
} from 'entities/Registration/model/RegistrationSlice'
import { IMetroSelect, TAgainstWishes } from 'entities/EditAccount/types'

const PreferencesStep = memo(() => {
  const dispatch = useAppDispatch()
  const whatNeighbourList = useSelector(RegistrationSelectors.whatNeighbourList)
  const wishesList = useSelector(RegistrationSelectors.wishesList)
  const priceMax = useSelector(RegistrationSelectors.priceMax)
  const priceMin = useSelector(RegistrationSelectors.priceMin)
  const metroList = useSelector(RegistrationSelectors.metroList)
  const districtsList = useSelector(RegistrationSelectors.districtsList)
  const countriesList = useSelector(RegistrationSelectors.countriesList)
  let metroSelected = useSelector(RegistrationSelectors.metro)
  let districtSelected = useSelector(RegistrationSelectors.district)
  let countrySelected = useSelector(RegistrationSelectors.country)
  const errorStatus = useSelector(RegistrationSelectors.errorStatus)
  const { redirectToAuth } = useRegistered()

  const onSetWhatNeighbour = (
    val: IMultipleItem<INeighbour['what_neighbor']>
  ) => {
    dispatch(setWhatNeighbour(val.id))
  }
  const onSetWishesList = (id: TAgainstWishes) => {
    if (id === 'against_alcohol') {
      dispatch(setAgainstAlcohol())
    } else if (id === 'against_smoking') {
      dispatch(setAgainstSmoking())
    }
  }

  const { getToken } = useToken()
  useEffect(() => {
    dispatch(fetchAllMetro({ token: getToken() }))
    dispatch(fetchAllDistricts({ token: getToken() }))
    dispatch(fetchAllCountries({ token: getToken() }))
  }, [])

  const onAddMetro = (metro: IMetroSelect<string>) => {
    if (!metro){
      return 
    }
    metroSelected = [...metroSelected, metro]
    dispatch(setMetro(metroSelected))
  }

  const onDeleteMetro = (metro: IMetroSelect<string>) => {
    metroSelected = metroSelected.filter((val) => val.value !== metro.value)
    dispatch(setMetro(metroSelected))
  }

  const onAddDistrict = (district: ISelectItem) => {
    if (!district){
      return 
    }
    districtSelected = [...districtSelected, district]
    dispatch(setDistrict(districtSelected))
  }

  const onDeleteDistrict = (district: ISelectItem) => {
    districtSelected = districtSelected.filter(
      (val) => val.value !== district.value
    )
    dispatch(setDistrict(districtSelected))
  }

  const onAddCountry = (country: ISelectItem) => {
    if (!country){
      return 
    }
    countrySelected = [...countrySelected, country]
    dispatch(setCountry(countrySelected))
  }
  const onDeleteCountry = (country: ISelectItem) => {
    countrySelected = countrySelected.filter(
      (val) => val.value !== country.value
    )
    dispatch(setCountry(countrySelected))
  }
  if (errorStatus === 401) {
    redirectToAuth()
  }
  
  const preferences = useSelector(RegistrationSelectors.preferences)
  const {what_neighbor, against_alcohol, against_smoking, price_min, price_max} = preferences
  return (
    <div className={styles.form}>
      <div>
        <MultipleFormFilter
          name="whatNeighbour"
          label="Хочу жить с"
          items={whatNeighbourList}
          padding="8px 14px"
          onClick={onSetWhatNeighbour}
          wrapperClass={styles.triple_wrapper}
          itemClass={styles.triple_neighbours}
          isRequired={false}
          selectedValue={what_neighbor}
        />
        <CheckboxesInput
          name="wishes"
          items={wishesList}
          onClick={onSetWishesList}
          defaultValue={{against_alcohol, against_smoking}}
        />
      </div>
      <Range
        label="Предпочтения по цене, ₽/мес"
        minValue={+priceMin}
        maxValue={+priceMax}
        maxDefaultValue={120000}
        step={500}
        onChangeMin={(item) => {
          dispatch(setPriceMin(item.toString()))
        }}
        onChangeMax={(item) => {
          dispatch(setPriceMax(item.toString()))
        }}
        width="100%"
        type='single'
      />
      <div
        style={{
          width: '100%',
          height: '1px',
          background: ' #000',
          opacity: '0.1',
        }}
      ></div>
      <SelectInput
        placeholder="Какие станции для вас удобнее?"
        label="Предпочтение по метро"
        name="metroSelect"
        items={metroList}
        onClick={onAddMetro}
        onDelete={onDeleteMetro}
        isMulti={true}
        isSearchable={true}
        value={metroSelected}
        type="metro"
        isRequired={false}
        maxOptionsNumber={3}
      />
      <SelectInput
        placeholder="Например, на юге Москвы"
        label="Округ"
        name="countrySelect"
        items={countriesList}
        onClick={onAddCountry}
        onDelete={onDeleteCountry}
        isMulti={true}
        isSearchable={true}
        value={countrySelected}
        isRequired={false}
        maxOptionsNumber={3}
      />
      <SelectInput
        placeholder="В каком районе?"
        label="Район"
        name="districtSelect"
        items={districtsList}
        onClick={onAddDistrict}
        onDelete={onDeleteDistrict}
        isMulti={true}
        isSearchable={true}
        value={districtSelected}
        isRequired={false}
        maxOptionsNumber={3}
      />
    </div>
  )
})

export default PreferencesStep
