import {forwardRef, InputHTMLAttributes, PropsWithChildren, useEffect, useState} from "react";
import styles from './Checkbox.module.scss';
import {useFormContext} from "react-hook-form";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
}


export const Checkbox = forwardRef<HTMLInputElement, PropsWithChildren<Props>> ((props, ref) => {
  const {
    name,
    children,
    className,
    onClick,
    defaultChecked
  } = props

  const { register, watch, setValue } = useFormContext();

  const isChecked = watch(name);
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    setChecked(defaultChecked)
    if (defaultChecked) {
      setValue(name, defaultChecked)
    }
    else {
      setValue(name, false)
    }
  }, [defaultChecked]);

  const onHandleClick = (e) => {
    setChecked(checked => !checked)
    setValue(name, e.target.checked ? true : false)
  }

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <label className={styles.label}>
        <input
          onClick={onClick}
          ref={ref}
          checked={checked}
          name={name}
          type="checkbox"
          {...register(name)}
          onChange={(e) => onHandleClick(e)}
          className={styles.input}
        />
        <span className={`${styles.icon} ${isChecked ? styles.active : ''}`}/>
        <span>{children}</span>
      </label>
    </div>
  );
});

Checkbox.displayName = 'Checkbox';

