import { useLayoutEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'app/store'
import { NeighbourCard } from 'widgets/NeighbourCard'
import { fetchAllNeighbours, neighbourSelector } from 'entities/Neighbour'
import { getSearchNeigboursParams } from 'entities/Neighbour'
import {
  Title,
  useToken,
  Text,
  Container,
  useClientWidth,
  adaptiveWidth,
  useRegistered,
} from 'shared'

import { INeighboursState } from 'entities/Neighbour'

import styles from './NeighbourList.module.scss'

const NeighbourList = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { getToken } = useToken()
  const token = getToken()
  const { getClientWidth } = useClientWidth()

  const neighboursArrayStatus = useSelector(
    neighbourSelector.neighboursArrayStatus
  )
  const errorStatus = useSelector(neighbourSelector.errorStatus)
  const { redirectToAuth } = useRegistered()

  useLayoutEffect(() => {
    // при первом рендере виджета мы используем параметры, которые есть в адресной строке, чтобы задать нужные параметры при запросе
    dispatch(
      fetchAllNeighbours({
        token,
        searchParams: getSearchNeigboursParams(searchParams),
      })
    )
  }, [])
  if (errorStatus === 401) {
    redirectToAuth()
  }
  const dispatch = useAppDispatch()
  const neighboursArray: INeighboursState['neighboursArray'] = useSelector(
    neighbourSelector.neighboursArray
  )
  const neighboursStatus: INeighboursState['neighboursArrayStatus'] =
    useSelector(neighbourSelector.neighboursArrayStatus)
  let content: JSX.Element
  if (neighboursStatus === 'success' && neighboursArray.length !== 0) {
    const cards = neighboursArray.map((neighbour) => (
      <NeighbourCard key={neighbour.id} neighbour={neighbour} />
    ))
    content = <div className={styles.cards}>{cards}</div>
  } else if (neighboursStatus === 'success' && neighboursArray.length === 0) {
    if (getClientWidth() < adaptiveWidth['572px']) {
      content = (
        <>
          <div className={styles.empty__wrapper}>
            <Text weight="bold" type="paragraph">
              По этому запросу никого не нашлось
            </Text>
            <Text
              type="small"
              color={'secondary-black'}
              className={styles.empty__text}
            >
              Попробуйте поменять фильтры и поискать заново
            </Text>
          </div>
        </>
      )
    } else {
      content = (
        <>
          <div className={styles.empty__wrapper}>
            <Title type="h4">По этому запросу никого не нашлось</Title>
            <Text
              type="paragraph"
              color={'secondary-black'}
              className={styles.empty__text}
            >
              Попробуйте поменять фильтры и поискать заново
            </Text>
          </div>
        </>
      )
    }
  } else if (neighboursStatus === 'error') {
    if (getClientWidth() < adaptiveWidth['572px']) {
      content = (
        <div className={styles.empty__wrapper}>
          <Text weight="bold" type="paragraph">
            Произошла ошибка.
          </Text>
          <Text type="small" color={'secondary-black'}>
            Попробуйте сбросить фильтры и заново начать поиск
          </Text>
        </div>
      )
    }
    content = (
      <Title className={styles.empty__wrapper} type="h4">
        Произошла ошибка. Попробуйте сбросить фильтры и заново начать поиск
      </Title>
    )
  }

  if (getClientWidth() < adaptiveWidth['572px']) {
    return <> {content}</>
  } else {
    return (
      <>
        <Container>{content}</Container>
      </>
    )
  }
}
export default NeighbourList
