import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAppDispatch } from 'app/store'
import {
  editOneMyAdRoom,
  myAdsSelectors,
  deleteOneMyAdRoom,
} from 'entities/MyAds'
import {
  ModalWraper,
  Text,
  Btn,
  Title,
  routes,
  useToken,
  useRegistered,
} from 'shared'

import styles from './DeleteAdModal.module.scss'

interface Props {
  id: number
  isDeleteAdModal: boolean
  handleCloseModal: () => void
}

const DeleteAdModal = (props: Props) => {
  const { id, isDeleteAdModal, handleCloseModal } = props
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { getToken } = useToken()
  const deleteMyAdStatus = useSelector(myAdsSelectors.deleteMyAdStatus)
  const resetDeleteStatus = useSelector(myAdsSelectors.resetDeleteStatus)
  const errorStatus = useSelector(myAdsSelectors.errorStatus)
  const { redirectToAuth } = useRegistered()
  useEffect(() => {
    if (deleteMyAdStatus === 'success') {
      navigate(`${routes.myAdsPage.allAdsPage}`)
      dispatch(resetDeleteStatus)
    }
  }, [deleteMyAdStatus])

  const handleDeleteAd = async () => {
    await dispatch(
      deleteOneMyAdRoom({
        id,
        token: getToken(),
      })
    )
  }
  if (errorStatus === 401) {
    redirectToAuth()
  }
  return (
    <>
      <ModalWraper
        isModalOpen={isDeleteAdModal}
        showCloseBtn={false}
        wrapperClass={styles.wrapperModal}
      >
        <div className={styles.modalContent}>
          <Title type="h3">Удалить объявление?</Title>
          <Text type="paragraph">
            Оно исчезнет с сайта навсегда. <br /> Вы уверены, что хотите
            продолжить?
          </Text>
          <div className={styles.btnsGroup}>
            <Btn onClick={handleCloseModal} className={styles.btn}>
              Назад
            </Btn>
            <Btn
              onClick={handleDeleteAd}
              type="outlined"
              color="button-red"
              className={styles.btn}
            >
              Удалить
            </Btn>
          </div>
        </div>
      </ModalWraper>
    </>
  )
}

export default DeleteAdModal
