import { INeighbour } from 'shared'

const getMonthPrice = (neighbour: INeighbour): string => {
  const { price_min, price_max } = neighbour
  let priceMinFirstDigits: string
  let priceMinLastDigits: string
  let priceMaxFirstDigits: string
  let priceMaxLastDigits: string
  if (price_min > 1000) {
    priceMinFirstDigits = price_min
      .toString()
      .slice(0, price_min.toString().length - 3)
    priceMinLastDigits = price_min.toString().slice(-3)
  } else {
    priceMinFirstDigits = ''
    priceMinLastDigits = price_min.toString()
  }

  if (price_max > 1000) {
    priceMaxFirstDigits = price_max
      .toString()
      .slice(0, price_max.toString().length - 3)
    priceMaxLastDigits = price_max.toString().slice(-3)
  } else {
    priceMaxFirstDigits = ''
    priceMaxLastDigits = price_max.toString()
  }

  return `до ${priceMaxFirstDigits} ${priceMaxLastDigits}`
}

// 66 666—99 999 ₽ в месяц

export default getMonthPrice
