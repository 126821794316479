import { memo } from 'react'
import { Link } from 'react-router-dom'
import { routes } from 'shared'
import { Text, Avatar, Icon, IRoom } from 'shared'

import {
  getAge,
  getFullName,
  getGender,
  getLabelBadHabits,
  getWhatNeighbour,
  getJobOrEducation,
  dateToString,
} from 'shared'

import styles from './CardRoomPreferences.module.scss'

interface Props {
  room: IRoom
}

const CardRoomPreferences = memo((props: Props) => {
  const { room } = props
  const { landlord } = room
  const imgLandlord = landlord.photo.length > 0 ? landlord.photo[0].image : null

  let age = getAge(landlord.birthday)
  const labelBadHabbits = getLabelBadHabits(landlord)
  const desiredNeighbour = getWhatNeighbour(landlord)

  const habbitsAndDesiredNeigRow =
    labelBadHabbits && desiredNeighbour ? (
      <div className={styles.last__row}>
        {labelBadHabbits ? (
          <div className={styles.habbits}>
            <div className={styles.icons}>
              {landlord.against_smoking ? <Icon type="no-smoking" /> : null}
              {landlord.against_alcohol ? <Icon type="no-alcohol" /> : null}
            </div>
            <Text type="small">{labelBadHabbits}</Text>
          </div>
        ) : null}

        {desiredNeighbour ? (
          <div className={styles.desired__neighbour}>
            <Icon type="pixel"></Icon>
            <Text type="small">{desiredNeighbour}</Text>
          </div>
        ) : null}
      </div>
    ) : null

  return (
    <>
      <div className={styles.wrapper}>
        <Link
          to={`${routes.neighbourPage.parentPage}/${landlord.id}`}
          className={styles.no__underline}
        >
          <div className={styles.card}>
            <div className={styles.card__info}>
              <Avatar
                img={imgLandlord}
                className={styles.avatar}
                abbreviationOfName={
                  landlord.last_name[0] + landlord.first_name[0]
                }
              />
              <div className={''}>
                <Text type="paragraph" weight="bold">
                  {getFullName(landlord)}
                </Text>
                <div className={styles.card__dots}>
                  <Text type="small">{getGender(landlord)}</Text>
                  {age ? 
                   <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                    <div className={styles.divider}></div>
                    <Text type="small">{age}</Text>
                  </div> : null}
                  {/* <div className={styles.divider}></div>
                  <Text type="small">{getJobOrEducation(landlord)}</Text> */}
                </div>
              </div>
            </div>
            <div className={styles.divider__horisontal}></div>
            {habbitsAndDesiredNeigRow}
          </div>
        </Link>

        <Text type="paragraph" color="grey" className={styles.datePublish}>
          Дата публикации: {dateToString(room.time_create)}
        </Text>
      </div>
    </>
  )
})

export default CardRoomPreferences
