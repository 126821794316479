import React, {
  FC,
  FormEvent,
  InputHTMLAttributes,
  useEffect,
  useState,
} from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import styles from './ImgInput.module.scss'
import { Btn, Text, Title, useRegistered, useToken } from 'shared'
import { ErrorMessage } from '@hookform/error-message'
import cross from 'assets/icons/cross_icon.svg'
import nextId from 'react-id-generator'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../../../../app/store'
import {
  deleteAdPhoto,
  filterIds,
  postPhotoAdForm,
} from '../../../../widgets/CreateAdForm/model/createAdFormSlice'
import { createAdFormSelectors } from 'widgets'
import { useSelector } from 'react-redux'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  files?: any
  setFiles: any
  photos: any
}

export const ImgInput: FC<Props> = (props, ref) => {
  const { id } = useParams()

  const {
    className,
    name,
    children,
    multiple,
    files,
    setFiles,
    photos,
    ...rest
  } = props

  const { getToken } = useToken()
  const token = getToken()

  const [imgs, setImgs] = useState([])
  const [isFocused, setIsFocused] = useState(false)
  const {
    register,
    formState,
    trigger,
    getFieldState,
    setValue,
    watch,
    control,
  } = useFormContext()
  const photosStatus = useSelector(createAdFormSelectors.photoStatus)
  const errorStatus = useSelector(createAdFormSelectors.errorStatus)
  const dispatch = useAppDispatch()
  const { redirectToAuth } = useRegistered()

  const value = watch(name)

  useEffect(() => {
    if (photos) {
      setImgs(photos)
      setValue(name, photos)
    }
  }, [photos, setValue, imgs])

  const { errors, isValid } = formState

  const handleFocus = () => {
    setIsFocused(true)
  }
  const handleBlur = (e) => {
    if (e.target.value === '') {
      setIsFocused(false)
    } else {
      setIsFocused(true)
    }
    setValidation(e)
  }

  const setValidation = async (e) => await trigger(e.target.name)
  const onFileChange = async (e) => {
    if (id) {
      const formData = new FormData()
      formData.append('image', e.target.files[0])
      formData.append('room', id)
      const postPhotoAdFormResult = await dispatch(
        postPhotoAdForm({ token, body: formData })
      )
      // @ts-ignore
      const photo = postPhotoAdFormResult.payload

      await setImgs((photos) => [...photos, photo])
    } else {
      const formData = new FormData()
      formData.append('image', e.target.files[0])
      const postPhotoAdFormResult = await dispatch(
        postPhotoAdForm({ token, body: formData })
      )
      // @ts-ignore
      const photo = postPhotoAdFormResult.payload

      await setImgs((photos) => [...photos, photo])
    }
  }

  const onRemove = async (photoId) => {
    await dispatch(deleteAdPhoto({ token, id: photoId }))
    const imgsForFilters = imgs.filter((img) => img.id !== photoId)
    setImgs(imgsForFilters)

    if (!id) {
      await dispatch(filterIds(photoId))
    }
  }

  const [counter, setCounter] = useState(0)
  const onHandleChange = async (e) => {
    await onFileChange(e)
    setValue(name, e.target.files[0])
  }

  const color = getFieldState(name).invalid ? 'red' : 'grey'
  if (errorStatus === 401) {
    redirectToAuth()
  }

  return (
    <>
      <div className={styles.wrapper}>
        <div
          className={styles.images}
          style={{ display: imgs?.length > 0 ? 'flex' : 'none' }}
        >
          {imgs?.map((src, ind) => (
            <div className={styles.imageWrap} key={ind}>
              <img src={src?.image} alt={src?.id} className={styles.image} />
              <div className={styles.remove} onClick={() => onRemove(src?.id)}>
                <img src={cross} alt="" />
              </div>
            </div>
          ))}
        </div>
        {imgs?.length < 15 ? (
          <label className={styles.labelWrapper} htmlFor={name}>
            <Title className={styles.title} type={'h4'}>
              Фотографии комнаты
            </Title>
            <div className={styles.download}>Загрузить</div>
            <Text type={'small'} color={color} className={styles.text}>
              Загрузите фотографии весом не более 15 мб
            </Text>
          </label>
        ) : null}
      </div>
      <Controller
        name={name}
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Обязательное поле',
          },
        }}
        render={({ field }) => (
          <input
            onChange={(e) => onHandleChange(e)}
            onBlur={handleBlur}
            id={name}
            name={name}
            type="file"
            style={{ display: 'none' }}
          />
        )}
      />
    </>
  )
}
