// @ts-nocheck
import {
  forwardRef,
  HTMLAttributes,
  PropsWithoutRef,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react'

interface Props extends HTMLAttributes<HTMLDivElement> {
  address: string
  address_x: number
  address_y: number
  className?: string
}

export const MapEmpty = forwardRef<HTMLButtonElement, PropsWithoutRef<Props>>(
  (props, ref) => {
    const { className, address, address_x, address_y } = props

    // фактически, тут неправильно написано - мы ещё не установили карту
    const [map, setMap] = useState(null)
    // const inputRef = useRef(null)
    const mapDiv = useRef(null)

    useEffect(() => {
      // @ts-ignore
      if (window.ymaps) {
        // @ts-ignore
        window.ymaps.ready(init).then(() => {
          setMap((map) => {
            if (map) {
              map.destroy()
            }
          })
          geocode(address)
        })
      }
    }, [address])

    function init() {
      // Подключаем поисковые подсказки к полю ввода.
      // @ts-ignore
      const map = new ymaps.Map(mapDiv.current, {
        center: [address_x, address_y],
        zoom: 16,
      })
      setMap(map)
    }

    function showGeoObject(obj) {
      setMap((map) => {
        if (map) {
          map.destroy()
        }
      })
      let bounds = obj.properties.get('boundedBy'),
        // Рассчитываем видимую область для текущего положения пользователя.
        // @ts-ignore
        mapState = window.ymaps.util.bounds.getCenterAndZoom(
          bounds,
          [1400, 400]
        ),
        // Сохраняем полный адрес для сообщения под картой.
        address = [obj.getCountry(), obj.getAddressLine()].join(', '),
        // Сохраняем укороченный адрес для подписи метки.
        shortAddress = [
          obj.getThoroughfare(),
          obj.getPremiseNumber(),
          obj.getPremise(),
        ].join(' ')
      // Убираем контролы с карты.
      // @ts-ignore
      mapState.controls = []
      // Создаём карту.
      // @ts-ignore
      const newMap = new ymaps.Map(mapDiv.current, mapState)
      // @ts-ignore
      const placemark = new ymaps.Placemark(
        newMap.getCenter(),
        {
          iconCaption: shortAddress,
          balloonContent: shortAddress,
        },
        {
          preset: 'islands#redHomeCircleIcon',
        }
      )

      newMap.geoObjects.add(placemark)
      // @ts-ignore
      newMap.setCenter(mapState.center, mapState.zoom)
      // @ts-ignore
      placemark.geometry.setCoordinates(mapState.center)
      // @ts-ignore
      placemark.properties.set({
        iconCaption: shortAddress,
        balloonContent: shortAddress,
      })
      setMap(newMap)
    }

    function geocode(value?: string) {
      // Геокодируем введённые данные.
      const geoinfo = value
      // @ts-ignore
      window.ymaps.geocode(geoinfo).then(function (res) {
        var obj = res.geoObjects.get(0),
          error,
          hint

        if (obj) {
          // Об оценке точности ответа геокодера можно прочитать тут: https://tech.yandex.ru/maps/doc/geocoder/desc/reference/precision-docpage/
          switch (
            // @ts-ignore
            obj.properties.get('metaDataProperty.GeocoderMetaData.precision')
          ) {
            // @ts-ignore
            case 'exact':
              // mapRef.geoObjects.add(res.geoObjects)
              break
            // @ts-ignore
            case 'number':
            // @ts-ignore
            case 'near':
            // @ts-ignore
            case 'range':
              error = 'Неточный адрес, требуется уточнение'
              hint = 'Уточните номер дома'
              break
            // @ts-ignore
            case 'street':
              error = 'Неполный адрес, требуется уточнение'
              hint = 'Уточните номер дома'
              break
            // @ts-ignore
            default:
              error = 'Неточный адрес, требуется уточнение'
              hint = 'Уточните адрес'
          }
        } else {
          error = 'Адрес не найден'
          hint = 'Уточните адрес'
        }

        // Если геокодер возвращает пустой массив или неточный результат, то показываем ошибку.
        if (error) {
          //   showError(error)

        } else {
          showGeoObject(obj)
        }
      })
    }

    return (
      <>
        <div
          className={className}
          style={{ width: '100%', height: '600px' }}
          ref={mapDiv}
          id="map"
        ></div>
      </>
    )
  }
)

MapEmpty.displayName = 'Map'
