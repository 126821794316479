import styles from './Plate.module.scss'

interface Props {
  className?: string
}

const Plate = (props) => {
  const { className = '' } = props
  return (
    <>
      <div className={`${styles.plate} ${className}`}>{props.children}</div>
    </>
  )
}

export default Plate
