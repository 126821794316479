import {
  createSlice,
  createAsyncThunk,
  SerializedError,
  PayloadAction,
} from '@reduxjs/toolkit'
import { concatSearchParams } from '../lib/concatSearchParams'

import { _apiUrl, countDisplayedRooms } from 'shared'
import type { IRoomSearchParams, IRoom, TStatusType } from 'shared'

interface IRoomData {
  count: number
  next: string | null
  previous: string | null
  results: IRoom[]
}

interface InitDataFetchAllRooms {
  token: string
  searchParams: IRoomSearchParams
}

export const fetchAllRooms = createAsyncThunk(
  'rooms/fetchAllRooms',
  async ({ token, searchParams }: InitDataFetchAllRooms) => {
    try {
      const response = await fetch(
        `${_apiUrl}/api/v1/room/?limit=${countDisplayedRooms}${concatSearchParams(
          searchParams
        )}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Authorization: `Token ${token}`,
          },
        }
      )
      if (response.ok) {
        const data: IRoomData = await response.json()
        return data
      } else {
        return response.status
      }
    } catch (error) {
      return error
    }
  }
)

interface IFetchOneRoom {
  id: number | string
  token: string
}

interface IReturnDataFetchOneRoom {
  room: IRoom
  isMyAd: boolean
}

export const fetchOneRoom = createAsyncThunk(
  'rooms/fetchOneRoom',
  async ({ id, token }: IFetchOneRoom, { dispatch }) => {
    try {
      const response = await fetch(`${_apiUrl}/api/v1/room/${id.toString()}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Token ${token}`,
        },
      })
      if (response.ok) {
        const data: IRoom = await response.json()
        return data
      } else {
        return response.status
      }
    } catch (error) {
      return error
    }
  }
)

export const fetchMyFavoriteRooms = createAsyncThunk(
  'rooms/fetchMyFavoriteRooms',
  async (token: string) => {
    try {
      const response = await fetch(`${_apiUrl}/api/v1/me/favorite/room/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Token ${token}`,
        },
      })
      if (response.ok) {
        const data: IRoomData = await response.json()
        return data.results
      } else {
        return response.status
      }
    } catch (error) {
      return error
    }
  }
)

export interface IRoomsState {
  countRoomsPages: number
  roomsArray: IRoom[]
  roomStatus: TStatusType | 'loadedData'
  room: IRoom
  roomsArrayStatus: TStatusType

  myFavoriteRooms: IRoom[]
  myFavoriteRoomsStatus: TStatusType
  error: string | null | SerializedError
  errorStatus: number
}

const initialState: IRoomsState = {
  countRoomsPages: 0,
  roomsArray: [],
  roomStatus: 'idle',
  room: null,
  roomsArrayStatus: 'idle',
  myFavoriteRooms: [],
  myFavoriteRoomsStatus: 'idle',
  error: null,
  errorStatus: null,
}

const roomSlice = createSlice({
  name: 'rooms',
  initialState,
  reducers: {
    setLoadedDataRoom: (state, action) => {
      state.roomStatus = 'loadedData'
      state.room = action.payload
    },
    resetRoomData: (state) => {
      // ресет данных нужно, чтобы при переходе по ссылке /rooms/{id своего объявления}
      // пользователя редиректило на свое объявление
      state.roomStatus = 'idle'
      state.room = null
    },
    setFavotiteRoom: (
      state,
      action: PayloadAction<{ id: number; is_favorite: boolean }>
    ) => {
      if (state?.room?.id === action.payload.id) {
        state.room.is_favorite = action.payload.is_favorite
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchAllRooms
      .addCase(fetchAllRooms.pending, (state) => {
        state.roomsArrayStatus = 'loading'
      })
      .addCase(fetchAllRooms.rejected, (state, action) => {
        state.roomsArrayStatus = 'error'
        state.error = action.error
      })
      .addCase(
        fetchAllRooms.fulfilled,
        (state, action: PayloadAction<IRoomData | number>) => {
          if (typeof action.payload !== 'number') {
            state.roomsArray = action.payload.results
            state.countRoomsPages = Math.ceil(
              action.payload.count / countDisplayedRooms
            )
            state.roomsArrayStatus = 'success'
          } else if (
            typeof action.payload === 'number' &&
            action.payload >= 400
          ) {
            state.roomsArrayStatus = 'error'
            state.errorStatus = action.payload
          }
        }
      )
      // fetchOneRoom
      .addCase(fetchOneRoom.pending, (state) => {
        state.roomStatus = 'loading'
      })
      .addCase(fetchOneRoom.rejected, (state, action) => {
        state.roomStatus = 'error'
        state.error = action.error
      })
      .addCase(
        fetchOneRoom.fulfilled,
        (state, action: PayloadAction<IRoom | number>) => {
          if (typeof action.payload === 'number') {
            state.errorStatus = action.payload
            state.roomStatus = 'error'
          } else {
            // проверка, что не получили ошибку
            if ('id' in action.payload) {
              state.roomStatus = 'success'
              state.room = action.payload
            }
          }
        }
      )
      // fetchMyFavoriteRooms
      .addCase(fetchMyFavoriteRooms.pending, (state) => {
        state.myFavoriteRoomsStatus = 'loading'
      })
      .addCase(fetchMyFavoriteRooms.rejected, (state, action) => {
        state.myFavoriteRoomsStatus = 'error'
        state.error = action.error
      })
      .addCase(
        fetchMyFavoriteRooms.fulfilled,
        (state, action: PayloadAction<IRoom[] | number>) => {
          if (typeof action.payload === 'number') {
            state.errorStatus = action.payload
            state.myFavoriteRoomsStatus = 'error'
          } else {
            state.myFavoriteRooms = action.payload
            state.myFavoriteRoomsStatus = 'success'
          }
        }
      )
  },
})

export const { resetRoomData, setLoadedDataRoom, setFavotiteRoom } =
  roomSlice.actions

export default roomSlice.reducer
