import styles from './SingleCheckbox.module.scss'
import mark_icon from '../../../../assets/icons/functional_icons/mark_icon.svg'

interface Props {
	onClick: () => void,
	label: string
	isSelected: boolean
}
const SingleCheckbox = (props: Props) => {
	const {onClick, label, isSelected} = props
	return (
		<div>
			<label
			className={`${styles.single_item} ${isSelected ? styles.single_active : styles.single_item}`}
          	>
				<input
					onChange={onClick}
					type="checkbox"
					style={{ display: 'none' }}
				/>
              <div className={styles.single_checkbox}><img src={mark_icon} alt="mark_icon" /></div>
			  <p style={{display: 'inline'}}>{label}</p>
          </label>
		 </div>
	)
}

export default SingleCheckbox