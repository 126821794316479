import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import styles from './StepsLayout.module.scss'
import { Btn, Container, Form, Text, Title, routes } from "shared";
import { ExitRegModal } from "features/ExitRegModal";
import { useState } from "react";
import { InfoBar, ProfileCard } from "entities/EditAccount";
import { AboutMeBar, PreferencesBar } from "entities/EditAccount";
interface Props {
	title: 'Редактирование' | 'Регистрация'
}
function StepsLayout({title} : Props) {
	const [isExitRegModalOpen, setExitRegModalOpen] = useState(false)
	const openExitRegModalOpen = () => setExitRegModalOpen(true)
	const closeExitRegModalOpen = () => {
		setExitRegModalOpen(false)
	}
	const stepNumber = useParams();
	const navigate = useNavigate();
	const isEdit = title === 'Редактирование'
	const goBack = () => {
		isEdit ? navigate(`${routes.editAccountPage}/1`) : navigate('../1')
		window.scroll(0, 0);
	}
	return (
	  <Container>
		<div className={styles.page}>
			<div className={styles.header}>

			<Btn
				type="outlined"
				height={36}
				width={72}
				className={styles.backButton}
				onClick={+stepNumber["*"] === 1 ? isEdit ? openExitRegModalOpen : null : goBack}
				disabled={+stepNumber["*"] === 1 && !isEdit}
			>
				<Text type="small" color="purple-main">
					{+stepNumber["*"] === 1 && !isEdit? 'На главную' : 'Назад'}
				</Text>
			</Btn>
			<ExitRegModal
				isExitRegModalOpen={isExitRegModalOpen}
				closeModal={closeExitRegModalOpen}
				title='Выйти?'
				message='Изменённые данные не сохранятся, придется редактировать их заново'
				exitLink={routes.myAccount}
				isClearStorage={title === 'Редактирование' ? false : true}
			/>
				<div className={styles.header__title}>  
					<Title type="h3">{title}</Title>
					<div className={styles.header__wrapper}>
					<p className={styles.slash}>/</p>
					<Text
						type="paragraph"
						weight="bold"
						className={styles.header__subtitle}
					>{`${stepNumber["*"]}/2`}</Text>
					</div>
				</div>

				<div className={styles.progressBar}>
					<div
					className={styles.progressLine}
					style={{ width: `${10 + +stepNumber["*"] * 40}%` }}
					></div>
				</div>
			</div>
			<div className={styles.wrapper}>
				<div className={styles.stepsWrapper}>
					<Outlet/>
				</div>
				<div>
					<ProfileCard type="registration"/>
					<InfoBar/>
					{/* {stepNumber["*"] === '1' ? <AboutMeBar/> : <PreferencesBar/>} */}
				</div>
       		</div>
			<Btn 
				form="registration"
				className={styles.nextButton}
			>{stepNumber["*"] === '1'? 'Продолжить' : 'Сохранить'}</Btn>
		</div>
	  </Container>
	)
  }
export default StepsLayout