import { useAppDispatch } from 'app/store'
import { resetEditErrorStatus } from 'entities/EditAccount/model/EditAccountSlice'
import { resetRegErrorStatus } from 'entities/Registration/model/RegistrationSlice'
import { createContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { AuthSelectors, routes, useToken, _elkAuthUrl, _apiUrl } from 'shared'
import { checkToken, resetCheckAuthStatus, resetAuthErrorStatus } from 'shared/model/AuthSlice'

type RegisteredContextType = {
  redirectToAuth: () => void
}

export const RegisteredContext = createContext<RegisteredContextType>({
  redirectToAuth: () => {},
})

const AuthProvider = ({ children }: { children: JSX.Element }) => {
  let [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useAppDispatch()
  const checkAuthStatus = useSelector(AuthSelectors.checkAuthStatus)
  const was_registered = useSelector(AuthSelectors.was_registered)
  const errorStatus = useSelector(AuthSelectors.errorStatus)
  const navigate = useNavigate()
  const location = useLocation()

  const { getToken, deleteToken } = useToken()

  const resetErrors = () => {
    dispatch(resetAuthErrorStatus());
    dispatch(resetRegErrorStatus());
    dispatch(resetEditErrorStatus())
  }

  const checkRegistered = () => {
    let path = location.pathname
    let isPathReg = !!(path === routes.registration || path === '/registration/1' || path === '/registration/2')
    if (was_registered) {
      if (isPathReg){
        navigate(routes.findNeighbourPage)
      }
    } else {
      if (!isPathReg){
        navigate(routes.registration)
      }
    }
  }

  const redirectToAuth = () => {
    if (location.pathname === routes.authPage ||
      location.pathname === `${routes.authPage}/`){
      } else {
        resetErrors();
        deleteToken();
        window.location.replace(_elkAuthUrl)
      }
  }

  useEffect(() => {
    if (getToken() === '' || !getToken()) {
      redirectToAuth()
    } else if (getToken()) {
      dispatch(checkToken(getToken()))
    }
  }, [])
  useEffect(() => {
    if (errorStatus === 401) {
      redirectToAuth()
    } else {
      checkRegistered()
    }
  }, [])

  return (
    <RegisteredContext.Provider value={{ redirectToAuth }}>
      {children}
    </RegisteredContext.Provider>
  )
}

export default AuthProvider
