import { useFormContext, Controller } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { TailSpin } from 'react-loading-icons'
import { FidgetSpinner, Oval } from 'react-loader-spinner'

import { INeighbour, IPhoto, Text, useRegistered, useToken } from 'shared'

import styles from './AvatarInput.module.scss'
import { memo, useEffect, useState } from 'react'
import cross from 'assets/icons/cross_icon.svg'
import {
  postPhoto,
  deletePhoto,
  resetPhoto,
  getProgress,
} from '../../../../entities/Registration/model/RegistrationSlice'
import { useAppDispatch } from 'app/store'
import { useSelector } from 'react-redux'
import { RegistrationSelectors } from 'entities/EditAccount'

type AvatarValues = {
  name: string
  className?: string,
  imageClassName?: string,
}

const AvatarInput = memo((props: AvatarValues) => {
  const { register, formState, trigger, getFieldState, control } =
    useFormContext()
  const [error, setError] = useState(false)
  const dispatch = useAppDispatch()
  const { getToken } = useToken()
  const token = getToken()
  const { errors } = formState
  const { name, className, imageClassName } = props
  const photo = useSelector(RegistrationSelectors.photo)
  const photoStatus = useSelector(RegistrationSelectors.photoStatus)
  const errorStatus = useSelector(RegistrationSelectors.errorStatus)
  const { redirectToAuth } = useRegistered()
  const onRemove = async (id) => {
    await dispatch(deletePhoto({ token, id }))
    dispatch(resetPhoto())
  }
  const handleUpload = async (e) => {
    const formData = new FormData()
    formData.append('image', e.target.files[0])
    await dispatch(postPhoto({ token, body: formData })).then(response => {
      if (typeof response.payload === 'number'){
        setError(true);
      } else {
        setError(false);
      }
    })
    dispatch(getProgress())
  }
  if (errorStatus === 401) {
    redirectToAuth()
  }
  const loadBtnOrSpinner =
    photoStatus === 'loading' ? (
      <>
        <Oval
          ariaLabel="loading-indicator"
          height={36}
          width={36}
          strokeWidth={5}
          strokeWidthSecondary={5}
          color="#7953c3"
          secondaryColor="#d9c6f9"
        />
      </>
    ) : (
      <div className={styles.input} id={name}>
        Загрузить
      </div>
    )
  return (
    <>
      {photo?.image ? (
        <div className={`${styles.imageWrap} ${imageClassName}`}>
          <img
            src={photo?.image}
            alt={photo?.id.toString()}
            className={styles.image}
          />
          <div className={styles.remove} onClick={() => onRemove(photo.id)}>
            <img src={cross} alt="" />
          </div>
        </div>
      ) : (
        <div
          className={`${styles.avatar} ${className}`}
          style={
            getFieldState(name).invalid
              ? { borderColor: '#FF0000', background: 'rgba(255, 0, 0, 0.05)' }
              : {}
          }
        >
          <label htmlFor="input__file" className={styles.wrapper}>
            <Text type="paragraph" weight="bold">
              Аватар профиля
            </Text>
            {loadBtnOrSpinner}
            <div>
              {error ? <Text type="small" color='red'>Это фото больше 15 мб</Text> : null}
              <Text type="small" color={error ? 'red' : 'grey'}>
                Загрузите фотографию весом не более 15 мб
              </Text>
            </div>
          </label>

          <Controller
            name={name}
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Обязательное поле',
              },
            }}
            render={({ field }) => (
              <input
                type="file"
                id="input__file"
                accept=".png,.jpg,.jpeg"
                onChange={(e) => handleUpload(e)}
                style={{ display: 'none' }}
              />
            )}
          />
        </div>
      )}
    </>
  )
})

export default AvatarInput
