import { INeighbour } from 'shared'

const getLabelBadHabits = (neighbour: INeighbour): string | null => {
  const { against_alcohol, against_smoking } = neighbour

  if (against_alcohol && against_smoking) {
    return `Против курения и алкоголя`
  } else if (!against_alcohol && against_smoking) {
    return `Против курения`
  } else if (against_alcohol && !against_smoking) {
    return `Против алкоголя`
  } else if (!against_alcohol && !against_smoking) {
    return null
  }
}

export default getLabelBadHabits
