import { Img } from 'react-image'
import { Text } from '../../'
import styles from './Avatar.module.scss'

interface IProps {
  img: string | null
  size?: number | string
  className?: string
  abbreviationOfName?: string
}

const Avatar = ({ img, size, className, abbreviationOfName }: IProps) => {
  let customSize: object

  if (size) {
    if (typeof size === 'string') {
      customSize = { maxWidth: `${size}`, maxHeight: `${size}` }
    } else if (typeof size === 'number') {
      customSize = { maxWidth: `${size}px`, maxHeight: `${size}px` }
    }
  } else {
    customSize = {}
  }

  const isImgNull = img === null

  return (
    <>
      {isImgNull ? (
        <div className={`${styles.wrapper} ${styles.skeleton__img}`}>
          {abbreviationOfName ? (
            <Text type={'paragraph'}>{abbreviationOfName}</Text>
          ) : null}
        </div>
      ) : (
        <div
          className={`${styles.wrapper} ${
            isImgNull ? styles.skeleton__img : ''
          }`}
        >
          <Img
            style={customSize}
            src={img}
            alt=""
            className={`${styles.avatar} ${className} `}
            loader={
              <div
                style={customSize}
                className={`${styles.skeleton__img} ${className}`}
              ></div>
            }
          />
        </div>
      )}
    </>
  )
}

export default Avatar
