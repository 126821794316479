import { INeighbour, Text, Btn, useClientWidth, adaptiveWidth, routes } from 'shared'
import { NavLink } from 'react-router-dom'

import styles from './MyAccountSettings.module.scss'

interface Props {
  myData: INeighbour
  tooglePublishMeInList: () => JSX.Element
}

const MyAccountSettings = (props: Props) => {
  const { myData, tooglePublishMeInList } = props
  const { tg_username, phone } = myData
  const { getClientWidth } = useClientWidth()
  const isTabletVersion = getClientWidth() < adaptiveWidth['1040px']
  if (isTabletVersion) {
  }
  return (
    <>
      <div className={styles.settings__card}>
        <div className={styles.contacts}>
          <Text type="paragraph">@{tg_username}</Text>
          {/* <Text type="paragraph">{phone}</Text> */}
        </div>

        <div className={styles.plate__showingMe}>
          <Text type="paragraph">Показывать меня в поиске</Text>
          {tooglePublishMeInList()}
        </div>
        {isTabletVersion ? null : (
           
          <Btn width={'100%'} type="outlined">
            <NavLink
           to={routes.editAccountPage}
           style={{textDecoration: 'none'}}
           >
            <Text type='paragraph' color='purple-main'>Редактировать профиль</Text>
           </NavLink>
          </Btn>
        )}
      </div>
    </>
  )
}

export default MyAccountSettings
