export const routes = {
  findNeighbourPage: '/',
  neighbourPage: {
    fullPath: '/neighbours/:neighbourId',
    neighbourId: 'neighbourId',
    parentPage: '/neighbours',
  },
  roomPage: {
    fullPath: '/rooms/:roomId',
    neighbourId: 'roomId',
    parentPage: '/rooms',
  },
  myAccount: '/account',
  myAdsPage: {
    allAdsPage: '/myAds',
    myAdsId: 'myAdsId',
    oneAdPage: '/myAds/:myAdsId',
  },
  emailError: '/email-error',
  favouritesPage: '/favourites',
  registration: '/registration',
  registrationSteps: '/registration/*',
  createAdPage: '/create-ad',
  editePage: '/edit-ad/:id',
  editAccountPage:'/edit-account',
  editAccountSteps: '/edit-account/*',
  authPage: '/auth/hse_redirect/',
  }
