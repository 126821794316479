import { INeighbour } from 'shared'

const getEducationRow = (neighbour: INeighbour): string => {
  const { program_education, course_number, type_account } = neighbour
  if (type_account === 'EMPLOYEE') {
    return `${neighbour.job}`
  } else if (type_account === 'STUDENT') {
    if (program_education) {
      const { level_education, faculty } = program_education

      if (course_number === 7) {
        // Тогда это выпускник
        return `Выпускник (${faculty.name})`
      } else if (program_education && course_number && faculty) {
        return `${course_number} курс ${level_education.name.toLowerCase()} ${
          faculty.name
        }`
      } else {
        return ''
      }
    } else {
      return ''
    }
  } else return ''
}

export default getEducationRow
