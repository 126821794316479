import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  IMultipleItem,
  IRoomSearchParams,
  TStatusType,
  TPlateType,
  TNumberRooms,
  TMinimumRentalPeriod,
  ICheckboxesItem,
} from 'shared'
import {
  onSetBathroomList,
  onSetConveniencesList,
  onSetNeighbourList,
  onSetPlateList,
  onSetPreferencesList,
  toggleBooleanFilter,
} from './actions'
import {
  TBathroomFilter,
  TConveniencesFilter,
  TNeighbourFilter,
  TPreferencesFilter,
  TRoomsFilters,
} from '../types/index'

export interface InitialState extends TRoomsFilters {
  statusForAllFilters: TStatusType
  amountRoomsList: IMultipleItem<TRoomsFilters['number_rooms']>[]
  rentalPeriodList: IMultipleItem<TRoomsFilters['minimum_rental_period']>[]
  preferencesList: ICheckboxesItem<TPreferencesFilter>[]
  conveniencesList: ICheckboxesItem<TConveniencesFilter>[]
  bathroomList: ICheckboxesItem<TBathroomFilter>[]
  plateList: ICheckboxesItem<TPlateType>[]
  neighbourFilterList: ICheckboxesItem<TNeighbourFilter>[]
}

const initialState: InitialState = {
  statusForAllFilters: 'idle',
  number_rooms: null,
  price_min: '0',
  price_max: '0',
  minimum_rental_period: null,
  min_room_area: '0',
  max_room_area: '0',
  min_apartment_area: '0',
  max_apartment_area: '0',
  min_floor: '0',
  max_floor: '0',
  has_pets_allowed: null,
  has_children_allowed: null,
  has_tv: null,
  has_dishwasher: null,
  has_public_utilities: null,
  without_deposit: null,
  has_conditioner: null,
  has_furniture: null,
  has_fridge: null,
  has_washing_machine: null,
  has_internet: null,
  has_balcony: null,
  has_elevator: null,
  has_garbage_chute: null,
  bathroom_is_combined: null,
  shower_type: null,
  plate_type: null,
  gender: null,
  against_alcohol: null,
  against_smoking: null,
  amountRoomsList: [
    { id: '0', text: 'Студия', isSelected: false },
    { id: '1', text: '1', isSelected: false },
    { id: '2', text: '2', isSelected: false },
    { id: '3', text: '3', isSelected: false },
    { id: '4', text: '4', isSelected: false },
    { id: '5', text: '5+', isSelected: false },
  ],
  rentalPeriodList: [
    { id: '2', text: '2 месяца', isSelected: false },
    { id: '6', text: 'полгода', isSelected: false },
    { id: '12', text: 'год', isSelected: false },
  ],
  preferencesList: [
    { id: 'pets_allowed', text: 'Можно с животными', isSelected: false },
    { id: 'children_allowed', text: 'Можно с детьми', isSelected: false },
    { id: 'tv', text: 'Телевизор', isSelected: false },
    { id: 'dishwasher', text: 'Посудомойка', isSelected: false },
    {
      id: 'with_public_utilities',
      text: 'С учетом коммунальных услуг',
      isSelected: false,
    },
    { id: 'without_deposit', text: 'Без залога', isSelected: false },
    { id: 'conditioner', text: 'Кондиционер', isSelected: false },
  ],
  conveniencesList: [
    { id: 'furniture', text: 'Мебель', isSelected: false },
    { id: 'fridge', text: 'Холодильник', isSelected: false },
    { id: 'washing_machine', text: 'Стиральная машина', isSelected: false },
    { id: 'internet', text: 'Интернет', isSelected: false },
    { id: 'balcony', text: 'Балкон', isSelected: false },
    { id: 'elevator', text: 'Лифт', isSelected: false },
    { id: 'garbage_chute', text: 'Мусоропровод', isSelected: false },
  ],
  bathroomList: [
    { id: 'bathroom_is_combined', text: 'Совмещенный', isSelected: false },
    { id: 'bathroom_is_separate', text: 'Раздельный', isSelected: false },
    { id: 'BATHROOM', text: 'Ванна', isSelected: false },
    { id: 'SHOWER', text: 'Душ', isSelected: false },
  ],
  plateList: [
    { id: 'ELECTRICAL', text: 'Электрическая', isSelected: false },
    { id: 'GAS', text: 'Газовая', isSelected: false },
  ],
  neighbourFilterList: [
    { id: 'MAN', text: 'Парень', isSelected: false },
    { id: 'WOMAN', text: 'Девушка', isSelected: false },
    { id: 'against_alcohol', text: 'Не пьет', isSelected: false },
    { id: 'against_smoking', text: 'Не курит', isSelected: false },
  ],
}

const roomFiltersSlice = createSlice({
  name: 'roomFilters',
  initialState,
  reducers: {
    resetAllFilters: (state) => {
      state.statusForAllFilters = 'loading'
      state.number_rooms = initialState.number_rooms
      state.price_min = initialState.price_min
      state.price_max = initialState.price_max
      state.minimum_rental_period = initialState.minimum_rental_period
      state.min_room_area = initialState.min_room_area
      state.max_room_area = initialState.max_room_area
      state.min_apartment_area = initialState.min_apartment_area
      state.max_apartment_area = initialState.max_apartment_area
      state.min_floor = initialState.min_floor
      state.max_floor = initialState.max_floor
      state.has_pets_allowed = initialState.has_pets_allowed
      state.has_children_allowed = initialState.has_children_allowed
      state.has_tv = initialState.has_tv
      state.has_dishwasher = initialState.has_dishwasher
      state.has_public_utilities = initialState.has_public_utilities
      state.without_deposit = initialState.without_deposit
      state.has_conditioner = initialState.has_conditioner
      state.has_furniture = initialState.has_furniture
      state.has_fridge = initialState.has_fridge
      state.has_washing_machine = initialState.has_washing_machine
      state.has_internet = initialState.has_internet
      state.has_balcony = initialState.has_balcony
      state.has_elevator = initialState.has_elevator
      state.has_garbage_chute = initialState.has_garbage_chute
      state.bathroom_is_combined = initialState.bathroom_is_combined
      state.shower_type = initialState.shower_type
      state.plate_type = initialState.plate_type
      state.gender = initialState.gender
      state.against_alcohol = initialState.against_alcohol
      state.against_smoking = initialState.against_smoking
      state.amountRoomsList = initialState.amountRoomsList
      state.rentalPeriodList = initialState.rentalPeriodList
      state.preferencesList = initialState.preferencesList
      state.conveniencesList = initialState.conveniencesList
      state.bathroomList = initialState.bathroomList
      state.plateList = initialState.plateList
      state.neighbourFilterList = initialState.neighbourFilterList

      state.statusForAllFilters = 'success'
    },
    setAllFilters: (state, action: PayloadAction<IRoomSearchParams>) => {
      state.statusForAllFilters = 'loading'
      const payload = action.payload
      if (payload.price_max) {
        state.price_max = payload.price_max
      }
      if (payload.price_min) {
        state.price_min = payload.price_min
      }
      if (payload.min_room_area) {
        state.min_room_area = payload.min_room_area
      }
      if (payload.max_room_area) {
        state.max_room_area = payload.max_room_area
      }
      if (payload.min_apartment_area) {
        state.min_apartment_area = payload.min_apartment_area
      }
      if (payload.max_apartment_area) {
        state.max_apartment_area = payload.max_apartment_area
      }
      //
      if (payload.min_floor) {
        state.min_floor = payload.min_floor
      }
      if (payload.max_floor) {
        state.max_floor = payload.max_floor
      }

      if (payload.number_rooms) {
      }

      if (payload.number_rooms) {
        const numberRoomsArray: TNumberRooms[] = payload.number_rooms.split(
          ','
        ) as TNumberRooms[]
        state.amountRoomsList = state.amountRoomsList.map((room) => {
          const isRoomContainNumber = numberRoomsArray.includes(room.id)
          return {
            ...room,
            isSelected: isRoomContainNumber,
          }
        })
      }
      if (payload.minimum_rental_period) {
        state.rentalPeriodList = state.rentalPeriodList.map((period) => {
          return {
            ...period,
            isSelected:
              period.id === payload.minimum_rental_period
                ? !period.isSelected
                : false,
          }
        })
        state.minimum_rental_period = payload.minimum_rental_period
      }
      // set preferences
      onSetPreferencesList(state, action)
      if (payload.has_pets_allowed === 'true') {
        toggleBooleanFilter(state, 'has_pets_allowed')
      }
      if (payload.has_children_allowed === 'true') {
        toggleBooleanFilter(state, 'has_children_allowed')
      }
      if (payload.has_tv === 'true') {
        toggleBooleanFilter(state, 'has_tv')
      }
      if (payload.has_dishwasher === 'true') {
        toggleBooleanFilter(state, 'has_dishwasher')
      }
      if (payload.has_public_utilities === 'true') {
        toggleBooleanFilter(state, 'has_public_utilities')
      }
      if (payload.without_deposit === 'true') {
        toggleBooleanFilter(state, 'without_deposit')
      }
      if (payload.has_conditioner === 'true') {
        toggleBooleanFilter(state, 'has_conditioner')
      }
      // set conviences
      onSetConveniencesList(state, action)
      if (payload.has_balcony === 'true') {
        toggleBooleanFilter(state, 'has_balcony')
      }
      if (payload.has_elevator === 'true') {
        toggleBooleanFilter(state, 'has_elevator')
      }
      if (payload.has_fridge === 'true') {
        toggleBooleanFilter(state, 'has_fridge')
      }
      if (payload.has_furniture === 'true') {
        toggleBooleanFilter(state, 'has_furniture')
      }
      if (payload.has_garbage_chute === 'true') {
        toggleBooleanFilter(state, 'has_garbage_chute')
      }
      if (payload.has_internet === 'true') {
        toggleBooleanFilter(state, 'has_internet')
      }
      if (payload.has_washing_machine === 'true') {
        toggleBooleanFilter(state, 'has_washing_machine')
      }
      // set bathroom
      onSetBathroomList(state, action)
      if (payload.bathroom_is_combined) {
        state.bathroom_is_combined = payload.bathroom_is_combined
      }
      if (payload.shower_type) {
        state.shower_type = payload.shower_type
      }
      // set plate
      onSetPlateList(state, action)
      if (payload.plate_type) {
        state.plate_type = payload.plate_type
      }
      // set neighbour
      onSetNeighbourList(state, action)
      if (payload.gender) {
        state.gender = payload.gender
      }
      if (payload.against_alcohol) {
        state.against_alcohol = payload.against_alcohol
      }
      if (payload.against_smoking) {
        state.against_smoking = payload.against_smoking
      }

      state.statusForAllFilters = 'success'
    },
    setPriceMin: (state, action: PayloadAction<TRoomsFilters['price_min']>) => {
      state.price_min = action.payload
    },
    setPriceMax: (state, action: PayloadAction<TRoomsFilters['price_max']>) => {
      state.price_max = action.payload
    },
    setMinRoomArea: (
      state,
      action: PayloadAction<TRoomsFilters['min_room_area']>
    ) => {
      state.min_room_area = action.payload
    },
    setMaxRoomArea: (
      state,
      action: PayloadAction<TRoomsFilters['max_room_area']>
    ) => {
      state.max_room_area = action.payload
    },
    setMinApartmentArea: (
      state,
      action: PayloadAction<TRoomsFilters['min_apartment_area']>
    ) => {
      state.min_apartment_area = action.payload
    },
    setMaxApartmentArea: (
      state,
      action: PayloadAction<TRoomsFilters['max_apartment_area']>
    ) => {
      state.max_apartment_area = action.payload
    },
    setMinFloor: (state, action: PayloadAction<TRoomsFilters['min_floor']>) => {
      state.min_floor = action.payload
    },
    setMaxFloor: (state, action: PayloadAction<TRoomsFilters['max_floor']>) => {
      state.max_floor = action.payload
    },
    setAmountRoomsList: (state, action: PayloadAction<TNumberRooms>) => {
      state.amountRoomsList = state.amountRoomsList.map((room) => {
        return {
          ...room,
          isSelected:
            room.id === action.payload ? !room.isSelected : room.isSelected,
        }
      })
      state.number_rooms = state.amountRoomsList
        .filter((numberRoom) => numberRoom.isSelected)
        .map((numberRoom) => numberRoom.id)
        .join(',') as TNumberRooms
    },
    setRentalPeriodList: (
      state,
      action: PayloadAction<TMinimumRentalPeriod>
    ) => {
      state.rentalPeriodList = state.rentalPeriodList.map((period) => {
        return {
          ...period,
          isSelected: period.id === action.payload ? !period.isSelected : false,
        }
      })
      state.minimum_rental_period = action.payload
    },
    setPreferencesList: (state, action: PayloadAction<TPreferencesFilter>) => {
      onSetPreferencesList(state, action)
      if (action.payload === 'children_allowed') {
        toggleBooleanFilter(state, 'has_children_allowed')
      } else if (action.payload === 'conditioner') {
        toggleBooleanFilter(state, 'has_conditioner')
      } else if (action.payload === 'dishwasher') {
        toggleBooleanFilter(state, 'has_dishwasher')
      } else if (action.payload === 'pets_allowed') {
        toggleBooleanFilter(state, 'has_pets_allowed')
      } else if (action.payload === 'tv') {
        toggleBooleanFilter(state, 'has_tv')
      } else if (action.payload === 'with_public_utilities') {
        toggleBooleanFilter(state, 'has_public_utilities')
      } else if (action.payload === 'without_deposit') {
        toggleBooleanFilter(state, 'without_deposit')
      }
    },
    setConveniencesList: (
      state,
      action: PayloadAction<TConveniencesFilter>
    ) => {
      onSetConveniencesList(state, action)
      if (action.payload === 'balcony') {
        toggleBooleanFilter(state, 'has_balcony')
      } else if (action.payload === 'elevator') {
        toggleBooleanFilter(state, 'has_elevator')
      } else if (action.payload === 'fridge') {
        toggleBooleanFilter(state, 'has_fridge')
      } else if (action.payload === 'furniture') {
        toggleBooleanFilter(state, 'has_furniture')
      } else if (action.payload === 'garbage_chute') {
        toggleBooleanFilter(state, 'has_garbage_chute')
      } else if (action.payload === 'internet') {
        toggleBooleanFilter(state, 'has_internet')
      } else if (action.payload === 'washing_machine') {
        toggleBooleanFilter(state, 'has_washing_machine')
      }
    },
    setBathroomList: (state, action: PayloadAction<TBathroomFilter>) => {
      onSetBathroomList(state, action)
      if (action.payload === 'BATHROOM') {
        if (state.shower_type === 'BATHROOM') {
          state.shower_type = null
        } else {
          state.shower_type = 'BATHROOM'
        }
      } else if (action.payload === 'SHOWER') {
        if (state.shower_type === 'SHOWER') {
          state.shower_type = null
        } else {
          state.shower_type = 'SHOWER'
        }
      } else if (action.payload === 'bathroom_is_combined') {
        if (state.bathroom_is_combined === 'true') {
          state.bathroom_is_combined = null
        } else {
          state.bathroom_is_combined = 'true'
        }
      } else if (action.payload === 'bathroom_is_separate') {
        if (state.bathroom_is_combined === 'false') {
          state.bathroom_is_combined = null
        } else {
          state.bathroom_is_combined = 'false'
        }
      }
    },
    setPlateList: (state, action: PayloadAction<TPlateType>) => {
      onSetPlateList(state, action)
      if (action.payload === 'ELECTRICAL') {
        if (state.plate_type === 'ELECTRICAL') {
          state.plate_type = null
        } else {
          state.plate_type = 'ELECTRICAL'
        }
      } else if (action.payload === 'GAS') {
        if (state.plate_type === 'GAS') {
          state.plate_type = null
        } else {
          state.plate_type = 'GAS'
        }
      }
    },
    setNeighbourist: (state, action: PayloadAction<TNeighbourFilter>) => {
      onSetNeighbourList(state, action)
      if (action.payload === 'MAN') {
        if (state.gender === 'MAN') {
          state.gender = null
        } else {
          state.gender = 'MAN'
        }
      } else if (action.payload === 'WOMAN') {
        if (state.gender === 'WOMAN') {
          state.gender = null
        } else {
          state.gender = 'WOMAN'
        }
      } else if (action.payload === 'against_alcohol') {
        if (state.against_alcohol === 'true') {
          state.against_alcohol = null
        } else {
          state.against_alcohol = 'true'
        }
      } else if (action.payload === 'against_smoking') {
        if (state.against_smoking === 'true') {
          state.against_smoking = null
        } else {
          state.against_smoking = 'true'
        }
      }
    },
  },
})

export default roomFiltersSlice.reducer

export const {
  resetAllFilters,
  setAllFilters,
  setPriceMax,
  setPriceMin,
  setMinRoomArea,
  setMaxRoomArea,
  setMinApartmentArea,
  setMaxApartmentArea,
  setMinFloor,
  setMaxFloor,
  setAmountRoomsList,
  setRentalPeriodList,
  setPreferencesList,
  setConveniencesList,
  setBathroomList,
  setPlateList,
  setNeighbourist,
} = roomFiltersSlice.actions
