import type { RootState } from 'app/store'
import { TRoomsFilters } from '../types'

export const statusForAllFilters = (state: RootState) => state.roomFilterSlice

export const price_max = (state: RootState) => state.roomFilterSlice.price_max

export const price_min = (state: RootState) => state.roomFilterSlice.price_min

export const number_rooms = (state: RootState) =>
  state.roomFilterSlice.number_rooms

export const minimum_rental_period = (state: RootState) =>
  state.roomFilterSlice.minimum_rental_period

export const min_room_area = (state: RootState) =>
  state.roomFilterSlice.min_room_area

export const max_room_area = (state: RootState) =>
  state.roomFilterSlice.max_room_area

export const min_apartment_area = (state: RootState) =>
  state.roomFilterSlice.min_apartment_area

export const max_apartment_area = (state: RootState) =>
  state.roomFilterSlice.max_apartment_area

export const min_floor = (state: RootState) => state.roomFilterSlice.min_floor

export const max_floor = (state: RootState) => state.roomFilterSlice.max_floor

export const has_pets_allowed = (state: RootState) =>
  state.roomFilterSlice.has_pets_allowed

export const has_children_allowed = (state: RootState) =>
  state.roomFilterSlice.has_children_allowed

export const has_tv = (state: RootState) => state.roomFilterSlice.has_tv

export const has_dishwasher = (state: RootState) =>
  state.roomFilterSlice.has_dishwasher

export const has_public_utilities = (state: RootState) =>
  state.roomFilterSlice.has_public_utilities

export const without_deposit = (state: RootState) =>
  state.roomFilterSlice.without_deposit

export const has_conditioner = (state: RootState) =>
  state.roomFilterSlice.has_conditioner

export const has_furniture = (state: RootState) =>
  state.roomFilterSlice.has_furniture

export const has_fridge = (state: RootState) => state.roomFilterSlice.has_fridge

export const has_washing_machine = (state: RootState) =>
  state.roomFilterSlice.has_washing_machine

export const has_internet = (state: RootState) =>
  state.roomFilterSlice.has_internet

export const has_balcony = (state: RootState) =>
  state.roomFilterSlice.has_balcony

export const has_elevator = (state: RootState) =>
  state.roomFilterSlice.has_elevator

export const has_garbage_chute = (state: RootState) =>
  state.roomFilterSlice.has_garbage_chute

export const bathroom_is_combined = (state: RootState) =>
  state.roomFilterSlice.bathroom_is_combined

export const shower_type = (state: RootState) =>
  state.roomFilterSlice.shower_type

export const plate_type = (state: RootState) => state.roomFilterSlice.plate_type

export const gender = (state: RootState) => state.roomFilterSlice.gender

export const against_alcohol = (state: RootState) =>
  state.roomFilterSlice.against_alcohol

export const against_smoking = (state: RootState) =>
  state.roomFilterSlice.against_smoking

export const amountRoomsList = (state: RootState) =>
  state.roomFilterSlice.amountRoomsList

export const rentalPeriodList = (state: RootState) =>
  state.roomFilterSlice.rentalPeriodList

export const preferencesList = (state: RootState) =>
  state.roomFilterSlice.preferencesList

export const conveniencesList = (state: RootState) =>
  state.roomFilterSlice.conveniencesList

export const bathroomList = (state: RootState) =>
  state.roomFilterSlice.bathroomList

export const plateList = (state: RootState) => state.roomFilterSlice.plateList

export const neighbourFilterList = (state: RootState) =>
  state.roomFilterSlice.neighbourFilterList

export const allRoomFilters = (state: RootState): TRoomsFilters => {
  return {
    number_rooms: state.roomFilterSlice.number_rooms,
    price_max: state.roomFilterSlice.price_max,
    price_min: state.roomFilterSlice.price_min,
    minimum_rental_period: state.roomFilterSlice.minimum_rental_period,
    min_room_area: state.roomFilterSlice.min_room_area,
    max_room_area: state.roomFilterSlice.max_room_area,
    min_apartment_area: state.roomFilterSlice.min_apartment_area,
    max_apartment_area: state.roomFilterSlice.max_apartment_area,
    min_floor: state.roomFilterSlice.min_floor,
    max_floor: state.roomFilterSlice.max_floor,
    has_pets_allowed: state.roomFilterSlice.has_pets_allowed,
    has_children_allowed: state.roomFilterSlice.has_children_allowed,
    has_tv: state.roomFilterSlice.has_tv,
    has_dishwasher: state.roomFilterSlice.has_dishwasher,
    has_public_utilities: state.roomFilterSlice.has_tv,
    without_deposit: state.roomFilterSlice.without_deposit,
    has_conditioner: state.roomFilterSlice.has_conditioner,
    has_furniture: state.roomFilterSlice.has_furniture,
    has_fridge: state.roomFilterSlice.has_fridge,
    has_washing_machine: state.roomFilterSlice.has_washing_machine,
    has_internet: state.roomFilterSlice.has_internet,
    has_balcony: state.roomFilterSlice.has_balcony,
    has_elevator: state.roomFilterSlice.has_elevator,
    has_garbage_chute: state.roomFilterSlice.has_garbage_chute,
    bathroom_is_combined: state.roomFilterSlice.bathroom_is_combined,
    shower_type: state.roomFilterSlice.shower_type,
    plate_type: state.roomFilterSlice.plate_type,
    gender: state.roomFilterSlice.gender,
    against_alcohol: state.roomFilterSlice.against_alcohol,
    against_smoking: state.roomFilterSlice.against_smoking,
  }
}
