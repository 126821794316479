import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, redirect } from 'react-router-dom'

const useToken = () => {
  const readToken = () => {
    // return ''
    // return `4424ddffdd67b6f4570dceab84df8614dc6801cc`
    const tokenString = localStorage.getItem('token')
    return tokenString
  }

  const setToken = (userToken: string) => {
    localStorage.setItem('token', userToken)
  }

  const deleteToken = () => {
    localStorage.removeItem('token')
  }

  const clearStorage = () => {
    localStorage.clear()
  }

  return {
    setToken,
    readToken,
    getToken: readToken,
    deleteToken,
    clearStorage,
  }
}
export default useToken
