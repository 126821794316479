import { useSelector } from 'react-redux'
import {
  Text,
  Title,
  SocialIcon,
  Container,
  useClientWidth,
  adaptiveWidth,
} from 'shared'

import styles from './Footer.module.scss'

const Footer = () => {
  const { getClientWidth } = useClientWidth()

  return (
    <>
      <footer className={styles.footer}>
        <Container>
          <div className={styles.footer__content}>
            <div className={styles.emailAndLinks}>
              <div className="">
                <Title
                  type={
                    getClientWidth() <= adaptiveWidth['800px'] ? 'h4' : 'h2'
                  }
                  url="mailto:homeet@styleru.org"
                  color="purple-main"
                  // className={styles.noLink}
                >
                  homeet@styleru.org
                </Title>
                <Text
                  type="paragraph"
                  color="secondary-black"
                  // url="https://forms.gle/W4yJoxytwHhMq1FT9"
                  className={styles.technical__support}
                >
                  Техническая поддержка
                </Text>
              </div>

              <div className={styles.row}>
                <div className={styles.links}>
                  <Text type="paragraph" className="link">
                    О сервисе
                  </Text>
                  <Text type="paragraph" className="link">
                    Контакты
                  </Text>
                  <Text type="paragraph" className="link">
                    Политика конфиденциальности
                  </Text>
                </div>
                <div className={styles.social}>
                  <SocialIcon type="telegram" size="small"></SocialIcon>
                  <SocialIcon type="vk" size="small"></SocialIcon>
                </div>
              </div>
            </div>

            <div className={styles.divider}></div>
            <div className={styles.text}>
              <Text className={styles.text_1} type="small" color="grey">
                Предложение не является публичной офертой. <br /> Сервис не
                несет ответственности за действия пользователей, достоверность
                информации и т.д.
              </Text>
              <Text type="small" color="grey">
                Сделано в бвср
              </Text>
            </div>
          </div>
        </Container>
        <div className={styles.line}></div>
      </footer>
    </>
  )
}

export default Footer
