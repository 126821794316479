import { INeighbourSearchParams } from 'shared'
import { TNeighbourFilters } from '..'

export const fromFiltersToSearhParams = (
  filters: TNeighbourFilters,
  offset: string
): INeighbourSearchParams => {
  let params: INeighbourSearchParams = {} as INeighbourSearchParams

  if (filters.price_max && filters.price_max !== '0') {
    params.price_max = filters.price_max
  }
  if (filters.price_min && filters.price_min !== '0') {
    params.price_min = filters.price_min
  }
  if (filters.age_min && filters.age_min !== '0') {
    params.age_min = filters.age_min
  }
  if (filters.age_max && filters.age_max !== '0') {
    params.age_max = filters.age_max
  }
  if (filters.gender) {
    params.gender = filters.gender
  }
  if (filters.course_number) {
    params.course_number = filters.course_number
  }
  if (filters.level_education) {
    params.level_education = filters.level_education
  }
  if (filters.faculty) {
    params.faculty = filters.faculty
  }
  if (filters.against_alcohol) {
    params.against_alcohol = filters.against_alcohol
  }
  if (filters.against_smoking) {
    params.against_smoking = filters.against_smoking
  }
  return params
}
