import {
  createSlice,
  createAsyncThunk,
  SerializedError,
  PayloadAction,
} from '@reduxjs/toolkit'

import { _apiUrl } from 'shared'
import type { IRoom, TStatusType } from 'shared'
import { responsiveFontSizes } from '@mui/material'

interface IRoomData {
  count: number
  next: string | null
  previous: string | null
  results: IRoom[]
}

interface IFetchMyRoomAds {
  token: string
}

export const fetchMyRoomAds = createAsyncThunk(
  'myAds/fetchMyRoomAds',
  async ({ token }: IFetchMyRoomAds) => {
    try {
      const response = await fetch(`${_apiUrl}/api/v1/me/room/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Token ${token}`,
        },
      })
      if (response.ok) {
        const data: IRoomData = await response.json()
        return data.results
      } else {
        return response.status
      }
    } catch (error) {
      return error
    }
  }
)

interface IFetchOneMyAdRoom {
  id: number | string
  token: string
}

export const fetchOneMyAdRoom = createAsyncThunk(
  'rooms/fetchOneMyAdRoom',
  async ({ id, token }: IFetchOneMyAdRoom) => {
    try {
      const response = await fetch(`${_apiUrl}/api/v1/room/${id.toString()}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Token ${token}`,
        },
      })
      if (response.ok) {
        const data: IRoom = await response.json()

        return data
      } else {
        return response.status
      }
    } catch (error) {
      return error
    }
  }
)

export interface IEditOneMyAdRoom extends IFetchOneMyAdRoom {
  data?: Partial<IRoom>
  method: 'PUT' | 'PATCH'
}

export const editOneMyAdRoom = createAsyncThunk(
  'rooms/editOneMyAdRoom',
  async ({ id, token, data, method }: IEditOneMyAdRoom) => {
    try {
      const response = await fetch(`${_apiUrl}/api/v1/room/${id.toString()}/`, {
        method: method,
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify(data),
      })
      if (response.ok) {
        const data: IRoom = await response.json()
        return data
      } else {
        return response.status
      }
    } catch (error) {
      return error
    }
  }
)

export const deleteOneMyAdRoom = createAsyncThunk(
  'rooms/deleteOneMyAdRoom',
  async ({ id, token }: IFetchOneMyAdRoom) => {
    try {
      const response = await fetch(`${_apiUrl}/api/v1/room/${id.toString()}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Token ${token}`,
        },
      })
      if (response.ok) {
        return
      } else {
        return response.status
      }
    } catch (error) {
      return error
    }
  }
)

export interface IMyAdsState {
  allMyRoomAds: IRoom[] // пока что для страницы с моими объявлениями не делаю пагинацию
  myRoomAd: IRoom
  myRoomAdStatus: TStatusType | 'loadedData'
  allMyRoomAdsStatus: TStatusType
  editMyAdStatus: TStatusType
  deleteMyAdStatus: TStatusType
  error: string | null | SerializedError
  errorStatus: number
}

const initialState: IMyAdsState = {
  allMyRoomAds: [],
  myRoomAd: null,
  myRoomAdStatus: 'idle',
  editMyAdStatus: 'idle',
  deleteMyAdStatus: 'idle',
  allMyRoomAdsStatus: 'idle',
  error: null,
  errorStatus: null,
}

const myAdsSlice = createSlice({
  name: 'myAds',
  initialState,
  reducers: {
    setLoadedDataMyAd: (state, action) => {
      state.myRoomAdStatus = 'loadedData'
      state.myRoomAd = action.payload
    },
    resetRoomData: (state) => {
      // ресет данных нужно, чтобы при переходе по ссылке /rooms/{id своего объявления}
      // пользователя редиректило на свое объявление
      state.myRoomAdStatus = 'idle'
      state.myRoomAd = null
    },
    resetDeleteStatus: (state) => {
      state.deleteMyAdStatus = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchMyRoomAds
      .addCase(fetchMyRoomAds.pending, (state) => {
        state.allMyRoomAdsStatus = 'loading'
      })
      .addCase(fetchMyRoomAds.rejected, (state, action) => {
        state.allMyRoomAdsStatus = 'error'
        state.error = action.error
      })
      .addCase(
        fetchMyRoomAds.fulfilled,
        (state, action: PayloadAction<IRoom[] | number>) => {
          if (typeof action.payload === 'number') {
            state.errorStatus = action.payload
            state.allMyRoomAdsStatus = 'error'
          } else {
            state.allMyRoomAds = action.payload
            state.allMyRoomAdsStatus = 'success'
          }
        }
      )
      // fetchOneMyAdRoom
      .addCase(fetchOneMyAdRoom.pending, (state) => {
        state.myRoomAdStatus = 'loading'
      })
      .addCase(fetchOneMyAdRoom.rejected, (state, action) => {
        state.myRoomAdStatus = 'error'
        state.error = action.error
      })
      .addCase(
        fetchOneMyAdRoom.fulfilled,
        (state, action: PayloadAction<IRoom | number>) => {
          if (typeof action.payload === 'number') {
            state.errorStatus = action.payload
            state.allMyRoomAdsStatus = 'error'
          } else {
            state.myRoomAd = action.payload
            state.myRoomAdStatus = 'success'
          }
        }
      )
      // editOneMyAdRoom
      .addCase(editOneMyAdRoom.pending, (state) => {
        state.editMyAdStatus = 'loading'
      })
      .addCase(editOneMyAdRoom.rejected, (state, action) => {
        state.editMyAdStatus = 'error'
        state.error = action.error
      })
      .addCase(
        editOneMyAdRoom.fulfilled,
        (state, action: PayloadAction<IRoom | number>) => {
          if (typeof action.payload === 'number') {
            state.errorStatus = action.payload
            state.allMyRoomAdsStatus = 'error'
          } else {
            state.editMyAdStatus = 'success'
            state.myRoomAd = action.payload
          }
        }
      )
      // deleteOneMyAdRoom
      .addCase(deleteOneMyAdRoom.pending, (state) => {
        state.deleteMyAdStatus = 'loading'
      })
      .addCase(deleteOneMyAdRoom.rejected, (state, action) => {
        state.deleteMyAdStatus = 'error'
        state.error = action.error
      })
      .addCase(
        deleteOneMyAdRoom.fulfilled,
        (state, action: PayloadAction<number>) => {
          if (typeof action.payload === 'number') {
            state.errorStatus = action.payload
            state.allMyRoomAdsStatus = 'error'
          } else {
            state.deleteMyAdStatus = 'success'
          }
        }
      )
  },
})

export const { resetRoomData, setLoadedDataMyAd, resetDeleteStatus } =
  myAdsSlice.actions

export default myAdsSlice.reducer
