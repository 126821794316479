import type { ISocialIcon } from './types'
import styles from './SocialIcon.module.scss'
import './font_icons/icon_fonts.scss'

const SocialIcon = ({
  type,
  size = 'default',
  variant = 'tonal',
  className,
}: ISocialIcon) => {
  let sizeClass: string = ''
  let wrapperClass: string
  let colorClass: string

  if (size === 'large') {
    sizeClass = styles.large
  } else if (size === 'small') {
    sizeClass = styles.small
  }

  if (type === 'ok') {
    wrapperClass = `${styles.icon__wrapper} ${styles.icon__wrapper_tonal} ${styles.ok}`
    colorClass = styles.ok__color
  } else if (type === 'vk') {
    wrapperClass = `${styles.icon__wrapper} ${styles.icon__wrapper_tonal} ${styles.vk}`
    colorClass = styles.vk__color
  } else if (type === 'telegram') {
    wrapperClass = `${styles.icon__wrapper} ${styles.icon__wrapper_tonal} ${styles.telegram}`
    colorClass = styles.telegram__color
  }

  return (
    <>
      {' '}
      <div className={`${wrapperClass} ${sizeClass} ${className}`}>
        <span className={`icon-${type} ${colorClass}`}></span>
      </div>
    </>
  )
}

export default SocialIcon
