import type { RootState } from 'app/store'
import { resetRoomData, setLoadedDataRoom, setFavotiteRoom } from './roomSlice'

export const countRoomsPages = (state: RootState) =>
  state.roomSlice.countRoomsPages

export const roomsArray = (state: RootState) => state.roomSlice.roomsArray

export const room = (state: RootState) => state.roomSlice.room

export const roomsArrayStatus = (state: RootState) =>
  state.roomSlice.roomsArrayStatus

export const roomStatus = (state: RootState) => state.roomSlice.roomStatus

export { resetRoomData, setLoadedDataRoom, setFavotiteRoom }

export const myFavoriteRooms = (state: RootState) =>
  state.roomSlice.myFavoriteRooms

export const myFavoriteRoomsStatus = (state: RootState) =>
  state.roomSlice.myFavoriteRoomsStatus

export const error = (state: RootState) => state.roomSlice.error
export const errorStatus = (state: RootState) => state.roomSlice.errorStatus
