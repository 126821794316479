import { createSlice } from "@reduxjs/toolkit";


interface InitialState {
  isAgreed: boolean
}

const initialState: InitialState = {
  isAgreed: false
}

const createAdConclusionSlice = createSlice({
  name: 'createAdConclusion',
  initialState,
  reducers: {
    toggleAgreement: (state) => {
      state.isAgreed = !state.isAgreed
    }
  }
})

export default createAdConclusionSlice.reducer

export const { toggleAgreement } = createAdConclusionSlice.actions