import { ReactNode } from 'react'
import styles from './Text.module.scss'

interface Props {
  type: 'paragraph' | 'small' | 'tiny' | 'mobile-small'
  color?:
    | 'purple-main'
    | 'white-main'
    | 'black-main'
    | 'secondary-black'
    | 'grey'
    | 'red'
  weight?: 'regular' | 'bold'
  className?: string
  onClick?: () => void
  children?: ReactNode
  url?: string
}

const Text = (props: Props) => {
  const {
    type,
    color = 'black-main',
    weight = 'regular',
    className = '',
    onClick,
    url,
  } = props
  if (url) {
    return (
      <a
        href={url}
        target="_blank"
        className={`${styles.noLink} ${styles[type]} ${styles[color]} ${styles[weight]} ${className}`}
        onClick={onClick}
        rel="noreferrer"
      >
        {props.children}
      </a>
    )
  }
  return (
    <>
      <p
        className={`${styles[type]} ${styles[color]} ${styles[weight]} ${className}`}
        onClick={onClick}
      >
        {props.children}
      </p>
    </>
  )
}

export default Text
