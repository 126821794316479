import {
  IRoomSearchParams,
  TGender,
  TAlcohol,
  TSmoking,
  TNumberRooms,
  TMinimumRentalPeriod,
  TBooleanToSrting,
  TShowerType,
  TPlateType,
} from 'shared'

// ВАЖНО!!!
// При изменении интерфейса IRoomSearchParams ОБЯЗАТЕЛЬНО нужно прописать ветку if для добавления/удаления параметра.
// Так как наличие параметров проверяется с помощью фун-ции has(), то нельзя ошибаться в написании параметров в has()

// Функция, которая вытаскивает все параметры из адресной строки и формирует из этого объект
export const getSearchRoomsParams = (
  searchParams: URLSearchParams
): IRoomSearchParams => {
  let params: IRoomSearchParams = { offset: '0' } // инициализируем offset при первом рендинге на первой странице
  if (searchParams.has('offset')) {
    params.offset = searchParams.get('offset')
  }
  if (searchParams.has('account_id')) {
    params.account_id = searchParams.get('account_id')
  }
  if (searchParams.has('number_rooms')) {
    params.number_rooms = searchParams.get('number_rooms') as TNumberRooms
  }
  if (searchParams.has('price_max')) {
    const searchPriceMax = searchParams.get('price_max')
    const searchPriceMin = searchParams.get('price_min')
    // @ts-ignore
    if (
      Number.isInteger(+searchPriceMax) &&
      Number.isInteger(+searchPriceMin)
    ) {
      if (+searchPriceMax > +searchPriceMin) {
        params.price_max = searchPriceMax
      } else {
        params.price_max = searchPriceMin
      }
    }
  }
  if (searchParams.has('price_min')) {
    const searchPriceMax = searchParams.get('price_max')
    const searchPriceMin = searchParams.get('price_min')

    // @ts-ignore
    if (
      Number.isInteger(+searchPriceMax) &&
      Number.isInteger(+searchPriceMin)
    ) {
      if (+searchPriceMax > +searchPriceMin) {
        params.price_min = searchPriceMin
      } else {
        params.price_min = searchPriceMax
      }
    }
  }
  if (searchParams.has('minimum_rental_period')) {
    params.minimum_rental_period = searchParams.get(
      'minimum_rental_period'
    ) as TMinimumRentalPeriod
  }
  if (searchParams.has('min_room_area')) {
    const searchMinRoomArea = searchParams.get('min_room_area')
    const searchMaxRoomArea = searchParams.get('max_room_area')
    // @ts-ignore
    if (
      Number.isInteger(+searchMinRoomArea) &&
      Number.isInteger(+searchMaxRoomArea)
    ) {
      if (+searchMaxRoomArea > +searchMinRoomArea) {
        params.min_room_area = searchMinRoomArea
      } else {
        params.min_room_area = searchMaxRoomArea
      }
    }
  }
  if (searchParams.has('max_room_area')) {
    const searchMinRoomArea = searchParams.get('min_room_area')
    const searchMaxRoomArea = searchParams.get('max_room_area')
    // @ts-ignore
    if (
      Number.isInteger(+searchMinRoomArea) &&
      Number.isInteger(+searchMaxRoomArea)
    ) {
      if (+searchMaxRoomArea > +searchMinRoomArea) {
        params.max_room_area = searchMaxRoomArea
      } else {
        params.max_room_area = searchMinRoomArea
      }
    }
  }
  if (searchParams.has('min_apartment_area')) {
    // params.min_apartment_area = searchParams.get('min_apartment_area')

    const searchMinApartmentArea = searchParams.get('min_room_area')
    const searchMaxApartmentArea = searchParams.get('max_room_area')
    // @ts-ignore
    if (
      Number.isInteger(+searchMinApartmentArea) &&
      Number.isInteger(+searchMaxApartmentArea)
    ) {
      if (+searchMaxApartmentArea > +searchMinApartmentArea) {
        params.min_apartment_area = searchMaxApartmentArea
      } else {
        params.min_apartment_area = searchMinApartmentArea
      }
    }
  }
  if (searchParams.has('max_apartment_area')) {
    params.max_apartment_area = searchParams.get('max_apartment_area')
  }
  if (searchParams.has('min_floor')) {
    params.min_floor = searchParams.get('min_floor')
  }
  if (searchParams.has('max_floor')) {
    params.max_floor = searchParams.get('max_floor')
  }
  if (searchParams.has('has_pets_allowed')) {
    params.has_pets_allowed = searchParams.get(
      'has_pets_allowed'
    ) as TBooleanToSrting
  }
  if (searchParams.has('has_children_allowed')) {
    params.has_children_allowed = searchParams.get(
      'has_children_allowed'
    ) as TBooleanToSrting
  }
  if (searchParams.has('has_tv')) {
    params.has_tv = searchParams.get('has_tv') as TBooleanToSrting
  }
  if (searchParams.has('has_dishwasher')) {
    params.has_dishwasher = searchParams.get(
      'has_dishwasher'
    ) as TBooleanToSrting
  }
  if (searchParams.has('has_public_utilities')) {
    params.has_public_utilities = searchParams.get(
      'has_public_utilities'
    ) as TBooleanToSrting
  }
  if (searchParams.has('without_deposit')) {
    params.without_deposit = searchParams.get(
      'without_deposit'
    ) as TBooleanToSrting
  }
  if (searchParams.has('has_conditioner')) {
    params.has_conditioner = searchParams.get(
      'has_conditioner'
    ) as TBooleanToSrting
  }
  if (searchParams.has('has_furniture')) {
    params.has_furniture = searchParams.get('has_furniture') as TBooleanToSrting
  }
  if (searchParams.has('has_fridge')) {
    params.has_fridge = searchParams.get('has_fridge') as TBooleanToSrting
  }
  if (searchParams.has('has_washing_machine')) {
    params.has_washing_machine = searchParams.get(
      'has_washing_machine'
    ) as TBooleanToSrting
  }
  if (searchParams.has('has_internet')) {
    params.has_internet = searchParams.get('has_internet') as TBooleanToSrting
  }
  if (searchParams.has('has_balcony')) {
    params.has_balcony = searchParams.get('has_balcony') as TBooleanToSrting
  }
  if (searchParams.has('has_elevator')) {
    params.has_elevator = searchParams.get('has_elevator') as TBooleanToSrting
  }
  if (searchParams.has('has_garbage_chute')) {
    params.has_garbage_chute = searchParams.get(
      'has_garbage_chute'
    ) as TBooleanToSrting
  }
  if (searchParams.has('bathroom_is_combined')) {
    params.bathroom_is_combined = searchParams.get(
      'bathroom_is_combined'
    ) as TBooleanToSrting
  }
  if (searchParams.has('shower_type')) {
    params.shower_type = searchParams.get('shower_type') as TShowerType
  }
  if (searchParams.has('plate_type')) {
    params.plate_type = searchParams.get('plate_type') as TPlateType
  }
  if (searchParams.has('gender')) {
    params.gender = searchParams.get('gender') as TGender
  }
  if (searchParams.has('against_alcohol')) {
    params.against_alcohol = searchParams.get(
      'against_alcohol'
    ) as TBooleanToSrting
  }
  if (searchParams.has('against_smoking')) {
    params.against_smoking = searchParams.get(
      'against_smoking'
    ) as TBooleanToSrting
  }
  return params
}
