import { Text, Btn, useClientWidth, adaptiveWidth } from 'shared'

import styles from './SearchAndResetFilters.module.scss'

import x_svg from '../../../assets/icons/functional_icons/x.svg'
import arrow from '../../../assets/icons/functional_icons/arrow.svg'

interface Props {
  onClickHide: () => void
  isFullDescrClicked: boolean
  onClickReset: () => void
  searchBtnText: string
  onSeachData?: () => void
}

const SearchAndResetFilters = ({
  onClickHide,
  isFullDescrClicked,
  onClickReset,
  searchBtnText = 'Поиск',
  onSeachData,
}: Props) => {
  const { getClientWidth } = useClientWidth()

  const checkShowFiltersState = (isFullDescrClicked: boolean) => {
    if (getClientWidth() < adaptiveWidth['800px']) {
      if (!isFullDescrClicked) {
        return (
          <>
            <div className={styles.adaptiveWrapper}>
              <div className={styles.reset} onClick={onClickReset}>
                <Text
                  type="paragraph"
                  color="secondary-black"
                  className={styles.reset__text}
                >
                  Сбросить
                </Text>
                <img src={x_svg} className={styles.x_svg} alt="cross" />
              </div>
              <div className={styles.more} onClick={onClickHide}>
                <Text type="paragraph" className={styles.more__text}>
                  Ещё фильтры
                </Text>
                <img src={arrow} alt="arrow" className={styles.more__arrow} />
              </div>
            </div>
          </>
        )
      } else {
        return (
          <>
            <div className={styles.adaptiveWrapper}>
              <div className={styles.reset} onClick={onClickReset}>
                <Text
                  type="paragraph"
                  color="secondary-black"
                  className={styles.reset__text}
                >
                  Сбросить
                </Text>
                <img src={x_svg} className={styles.x_svg} alt="cross" />
              </div>
              <div className={styles.more} onClick={onClickHide}>
                <Text type="paragraph" className={styles.more__text}>
                  Скрыть
                </Text>
                <img src={arrow} className={styles.arrow_up} alt="arrow" />
              </div>
            </div>
          </>
        )
      }
    } else {
      if (!isFullDescrClicked) {
        return (
          <div className={styles.more} onClick={onClickHide}>
            <Text type="paragraph" className={styles.more__text}>
              Ещё фильтры
            </Text>
            <img src={arrow} alt="arrow" className={styles.more__arrow} />
          </div>
        )
      } else {
        return (
          <div className={styles.more} onClick={onClickHide}>
            <Text type="paragraph" className={styles.more__text}>
              Скрыть
            </Text>
            <img src={arrow} className={styles.arrow_up} alt="arrow" />
          </div>
        )
      }
    }
  }
  return (
    <>
      <div className={styles.wrapper}>
        {checkShowFiltersState(isFullDescrClicked)}
        <div className={styles.btns}>
          {getClientWidth() < adaptiveWidth['800px'] ? null : (
            <div className={styles.reset} onClick={onClickReset}>
              <Text
                type="paragraph"
                color="secondary-black"
                className={styles.reset__text}
              >
                Сбросить фильтры
              </Text>
              <img src={x_svg} className={styles.x_svg} alt="cross" />
            </div>
          )}

          <Btn onClick={onSeachData} className={styles.btn}>
            {searchBtnText}
          </Btn>
        </div>
      </div>
    </>
  )
}

export default SearchAndResetFilters
