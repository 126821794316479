import styles from './CreateAdConclusion.module.scss';
import { Checkbox, Title } from "shared";
import { useAppDispatch } from "app/store";
import { toggleAgreement } from "../model/createAdConclusionSlice";


export const CreateAdConclusion = () => {
  const dispatch = useAppDispatch()
  return (
    <div className={styles.wrapper}>
      <Title type={'h3'}>Завершение</Title>
      <Checkbox className={styles.checkbox} name={'checked'} onClick={() => dispatch(toggleAgreement())}>Я соглашаюсь с политикой конфиденциальности</Checkbox>
    </div>
  );
};



