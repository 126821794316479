import { RegistrationSelectors } from "entities/EditAccount"
import { useSelector } from "react-redux"
import { Text } from "shared"
import sad_icon from '../../../../assets/icons/emoji/sad_icon.svg'
import smile_icon from '../../../../assets/icons/emoji/smile_icon.png'
import lovely_icon from '../../../../assets/icons/emoji/lovely_icon.png'
import fire_icon from '../../../../assets/icons/emoji/fire_icon.png'
import hundred_icon from '../../../../assets/icons/emoji/hundred_icon.png'

import styles from './StepBars.module.scss'

export const InfoBar = () => {
	const progress = useSelector(RegistrationSelectors.progress)
	let title = ''
	let subtitle = ''
	if (progress <= 20) {
		title = `Шанс найти соседа всего ${progress}%`
		subtitle = `Расскажи побольше, кто ты и что для тебя важно?`
	} else if (progress > 20 && progress <= 50) {
		title = `Шанс найти соседа ${progress}%`
		subtitle = `Расскажи побольше, кто ты и что для тебя важно?`
	} else if (progress > 50 && progress <= 90){
		title = `Шанс найти соседа ${progress}%`
		subtitle = `Расскажи, какое жилье ищешь, это увеличит твои шансы`
	} else if (progress === 100){
		title = `Шансы найти соседа все 100%`
		subtitle = `Уверены, ты точно найдешь своего соседа мечты`
	}
	return (
		<div className={styles.plate}>
			<div className={styles.container}>	
				<div className={styles.plate__wrapper}>
					<Text type='paragraph' weight='bold'>{title}</Text>
					<Text type='small' color="secondary-black">{subtitle}</Text>
				</div>
				{progress <=50 && <img src={smile_icon} alt="sad_icon" className={styles.icon}/>}
				{progress > 50 && progress <= 90 &&<img src={lovely_icon} alt="sad_icon" className={styles.icon}/>}
				{progress === 100 && <div>
					<img src={fire_icon} alt="sad_icon" className={styles.icon}/>
					<img src={hundred_icon} alt="sad_icon" className={styles.icon}/>
				</div>}
			</div>
		</div>
	)
}
export const AboutMeBar = () => {
	return (
		<div className={styles.plate}>
			<div className={styles.container}>	
				<div className={styles.plate__wrapper}>
					<Text type='paragraph' weight='bold'>Твои шансы найти соседа всего 1%</Text>
					{/* <Text type='small' color="secondary-black">Расскажи о себе.</Text> */}
					<Text type='small' color="secondary-black">Твою карточку не заметят, она ничем не отличается от 90% пользователей</Text>
				</div>
				<img src={sad_icon} alt="sad_icon" className={styles.icon}/>
			</div>
		</div>
	)
}

export const EducationBar = () => {
	const accountType = useSelector(RegistrationSelectors.accountType)
	const title = accountType === 'STUDENT' ? 'Расскажи, где ты учишься' : 'Расскажите, кем вы работаете'
	const subtitle = accountType === 'STUDENT' ? 'Часто студенты ищут соседей по схожим интересам' : 'У студентов будет больше доверия к тем, кого они знают'
	return (
		<div className={styles.plate}>
			<div className={styles.container}>
				<div className={styles.plate__wrapper}>
					<Text type='paragraph' weight='bold'>{title}</Text>
					<Text type='small' color="secondary-black">{subtitle}</Text>
				</div>
				<div style={{height: '40px', width: '32px'}} className={styles.icon}>
					<img src={smile_icon} alt="sad_icon" />
				</div>
			</div>
		</div>
	)
}


export const PreferencesBar = () => {
	return (
		<div className={styles.plate}>
			<div className={styles.container}>
				<div className={styles.plate__wrapper}>
					<Text type='paragraph' weight='bold'>Шансы найти соседа 80%</Text>
					<Text type='small' color="secondary-black">Расскажи, что ищешь, это увеличит твои шансы</Text>
				</div>
				<div style={{height: '40px', width: '32px'}} className={styles.icon}>
					<img src={lovely_icon} alt="lovely_icon"/>
				</div>
			</div>
			
		</div>
	)
}