import { Text } from 'shared'

import styles from './MultipleFormFilter.module.scss'
import mark_icon from '../../../../assets/icons/functional_icons/mark_icon.svg'
import * as ToggleGroup from '@radix-ui/react-toggle-group'
import { Controller, useFormContext } from 'react-hook-form'
import React, { useEffect, useState } from 'react'
import { ErrorMessage } from '@hookform/error-message'

export interface IMultipleItem<T = string> {
  text: string
  id: T // параметр, который заносим в один из filtersSlice и потом в параметры адресной строки
}
interface Props<T> {
  name: string
  padding?: string
  label?: string | JSX.Element
  className?: string
  items: IMultipleItem<T>[]
  onClick?: (val: IMultipleItem<string>) => void
  wrapperClass?: string
  itemClass?: string
  value?: null
  selectedValue?: string | number | boolean
  type?: 'registration' | 'wishes'
  isRequired?: boolean
}
// прописать дженетики для того чтобы можно было гибко настраивать каждый тип MultipleItem
// у который все filterId будут зависить от передаваемых типов (например, пол, предпочтения по сосдеу. преимущества и тд)
export const MultipleFormFilter = <P extends unknown>(
  props: Props<P>
): JSX.Element => {
  let {
    label,
    items,
    itemClass,
    wrapperClass,
    name,
    padding = '8px 30px',
    className,
    selectedValue,
    type,
    onClick,
    isRequired = true,
  } = props

  const { register, formState, trigger, watch, setValue, getFieldState, control } =
    useFormContext()
  const { errors } = formState
  const selectedOption = watch(name)

  useEffect(() => {
    if (
      selectedValue === 0 ||
      typeof selectedValue === 'boolean' ||
      selectedValue
    ) {
      setValue(name, selectedValue)
    }
  }, [setValue, selectedValue])

  let elements = items.map((item, ind) => {
    return (
      <label
        key={item.text}
        className={`${
          selectedOption == String(item.id) ? styles.active : styles.item
        } ${itemClass}`}
        onClick={() => {if (onClick) {
          setValue(name, selectedValue)
          return onClick(item as IMultipleItem<string>)
        }}}
      >
        <input
          name={name}
          type="radio"
          checked={item.id == selectedOption}
          value={item.id as string}
          {...register(name, {
            required: {
              value: isRequired,
              message: 'Обязательное поле',
            },
          })}
          style={{ display: 'none' }}
        />
        {item.text}
      </label>
    )
  })

  let isRegistration = type === 'registration'
  let lastElement = isRegistration
    ? items[items.length - 1]
    : { id: '', isSelected: false, text: '' }
  if (isRegistration) elements.pop()

  return (
    <>
      <div>
        <Text type="small" color="secondary-black">
          {label}
        </Text>
        <div
          className={`${styles.wrapper} ${wrapperClass}`}
          style={{
            background: getFieldState(name).invalid
              ? 'rgba(255, 0, 0, 0.05)'
              : '',
            border: getFieldState(name).invalid
              ? '1px solid var(--ff-0000-button-red, #F00)'
              : '',
          }}
        >
          <Controller 
            name={name}
            control={control}
            rules={{
              required: {
                value: isRequired,
                message: 'Обязательное поле',
              },
            }}
            render={() => (
             <>{elements}</>
            )}
          />
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <Text type="small" className={styles.error}>
                {message}
              </Text>
            )}
          />
        </div>
        {isRegistration && (
          <div>
            <label
              key={lastElement.text}
              className={`${styles.single_item} ${
                selectedOption == String(lastElement.id)
                  ? styles.single_active
                  : ''
              }`}
              onClick={() => onClick(lastElement as IMultipleItem<string>)}
            >
              <input
                name={name}
                type="radio"
                value={lastElement.id as string}
                {...register(name, {
                  required: {
                    value: true,
                    message: 'Обязательное поле',
                  },
                })}
                style={{ display: 'none' }}
              />
              <div className={styles.single_checkbox}>
                <img src={mark_icon} alt="mark_icon" />
              </div>
              {lastElement.text}
            </label>
          </div>
        )}
      </div>
    </>
  )
}
