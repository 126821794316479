import styles from './CheckboxesForm.module.scss';

import { Text } from 'shared'
import {FC, InputHTMLAttributes, memo, PropsWithChildren, useEffect, useState} from 'react'
import {useFormContext} from "react-hook-form";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string,
  setChecked?: boolean
}

export const CheckboxesForm: FC<PropsWithChildren<Props>> = (props) => {
  let { label, name, className, children, defaultChecked, ...rest } = props

  const { register, watch, setValue } = useFormContext();

  const isChecked = watch(name);
  const [checked, setChecked] = useState<boolean>(false);
  useEffect(() => {
    setChecked(defaultChecked)
    if (defaultChecked) {
      setValue(name, defaultChecked)
    }
    else {
      setValue(name, false)
    }
  }, [defaultChecked]);

  const onHandleClick = (e) => {
    setChecked(checked => !checked)
    setValue(name, e.target.checked ? true : false)
  }

  return (
    <>
      <div className={styles.element}>

        {
          label
          ?
            <Text type="small" color="secondary-black">
              {label}
            </Text>
          :
            null
        }

        <div className={styles.checkbox__wrapper}>
          <label className={`${className} ${isChecked ? styles.checkbox_selected : styles.checkbox} `}>
            <input
              checked={checked}
              name={name}
              type="checkbox"
              {...register(name)}
              onChange={(e) => onHandleClick(e)}
              className={styles.input}
            />
            <Text
              type="small"
              color={`${isChecked ? 'white-main' : 'black-main'}`}
            >
              {children}
            </Text>
          </label>

        </div>
      </div>
    </>
  )
}



