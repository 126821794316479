import {
  Icon,
  Btn,
  SquarePhoto,
  Text,
  useClientWidth,
  adaptiveWidth,
  routes,
  _logInUrl,
} from 'shared'
import type { INeighbour } from 'shared'
import MyAccountSettings from '../MyAccountSettings/MyAccountSettings'
import { ExitRegModal } from 'features/ExitRegModal'

import styles from './LeftColumnMyAccount.module.scss'
import { useState } from 'react'

interface Props {
  myData: INeighbour
  tooglePublishMeInList: () => JSX.Element
}

const LeftColumnMyAccount = (props: Props) => {
  const { myData, tooglePublishMeInList } = props
  const { photo } = myData
  const { getClientWidth } = useClientWidth()
  
  const [isExitRegModalOpen, setExitRegModalOpen] = useState(false)
	const openExitRegModalOpen = () => {
		setExitRegModalOpen(true)
	}
	const closeExitRegModalOpen = () => {
		setExitRegModalOpen(false)
	}

  const img = photo.length > 0 ? photo[photo.length - 1].image : null

  if (getClientWidth() < adaptiveWidth['1040px']) {
    return (
      <>
        <div className={styles.left__column}>
          <SquarePhoto
            img={img}
            className={styles.squarePhoto}
            abbreviationOfName={myData.last_name[0] + myData.first_name[0]}
          />
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className={styles.left__column}>
          <SquarePhoto
            img={img}
            className={styles.squarePhoto}
            abbreviationOfName={myData.last_name[0] + myData.first_name[0]}
          />
          <MyAccountSettings
            myData={myData}
            tooglePublishMeInList={tooglePublishMeInList}
          ></MyAccountSettings>
          <div className={styles.logOutBtn} onClick={openExitRegModalOpen}>
            <Text
              type="paragraph"
              color="secondary-black"
              className={styles.logOutText}
            >
              Выйти из аккаунта
            </Text>
            <Icon type="exit" className={styles.logOutIcon}></Icon>
          </div>
        </div>
        <ExitRegModal 
          isExitRegModalOpen={isExitRegModalOpen} 
          closeModal={closeExitRegModalOpen} 
          title='Выйти из аккаунта?'
          message=''
          exitLink={_logInUrl}
          isClearStorage={true}
			  />
      </>
    )
  }
}

export default LeftColumnMyAccount
