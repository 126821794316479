import { getMyAdStatus, IRoom, Text } from 'shared'
import onModerIcon from 'assets/icons/my_ads_icons/on_moderation.svg'
import lockIcon from 'assets/icons/my_ads_icons/lock.svg'
import hiddenIcon from 'assets/icons/my_ads_icons/hidden.svg'

import styles from './PlateCardStatus.module.scss'

interface Props {
  room: IRoom
}

const PlateCardStatus = (props: Props) => {
  const { room } = props
  let wrapperClass: string
  let textElement: JSX.Element
  let icon: string
  const status = getMyAdStatus(room)

  if (status === 'HIDDEN') {
    wrapperClass = styles.hidden
    textElement = <Text type="paragraph">Объявление снято с публикации</Text>
    icon = hiddenIcon
  } else if (status === 'ON_MODERATION') {
    wrapperClass = styles.onModeration
    textElement = <Text type="paragraph">На модерации</Text>
    icon = onModerIcon
  } else if (status === 'REJECTED') {
    wrapperClass = styles.rejected
    textElement = (
      <Text type="paragraph" color="white-main">
        Объявление не прошло модерацию
      </Text>
    )
    icon = lockIcon
  } else {
    wrapperClass = styles.visible
    textElement = <></>
    icon = ''
  }
  return (
    <>
      <div className={`${styles.plate} ${wrapperClass}`}>
        <img src={icon} alt="" />
        {textElement}
      </div>
    </>
  )
}

export default PlateCardStatus
