import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  isAgreed: boolean
}

const initialState: InitialState = {
  isAgreed: false
}

export const createAdAdditionalSlice = createSlice({
  name: 'createAdAdditional',
  initialState,
  reducers: {
    toggleIsAgreed: (state) => {
      state.isAgreed = !state.isAgreed
    }
  }
})
export const createAdAdditional = createAdAdditionalSlice.reducer;
export const { toggleIsAgreed } = createAdAdditionalSlice.actions