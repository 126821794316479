import { JSXElementConstructor, ReactNode, memo } from 'react'
import { PhotoProvider, PhotoView } from 'react-photo-view'
import { Img } from 'react-image'
import styles from './SquarePhoto.module.scss'
import { Title } from 'shared'

export interface IconProps {
  iconClassName: string
}

interface Props {
  wrapperClass?: string
  img: string | string[] | null
  size?: number | string
  className?: string
  icon?: (props: IconProps) => JSX.Element
  locationIcon?:
    | 'right-top'
    | 'right-bottom'
    // | 'right-middle'
    | 'left-top'
    | 'left-bottom'
  // | 'left-middle'
  abbreviationOfName?: string
  photoView?: boolean
}

const SquarePhoto = memo((props: Props) => {
  const {
    img,
    size,
    className,
    wrapperClass,
    icon,
    locationIcon,
    abbreviationOfName,
    photoView = false,
  } = props
  let customSize: object

  const getImgWithIconContainer = (children: JSX.Element | ReactNode) => {
    return (
      <div className={`${styles.wrapperPhoto} ${wrapperClass}`}>
        {icon({ iconClassName: styles[locationIcon] })}
        {children}
      </div>
    )
  }

  if (size) {
    if (typeof size === 'string') {
      customSize = { maxWidth: `${size}`, maxHeight: `${size}` }
    } else if (typeof size === 'number') {
      customSize = { maxWidth: `${size}px`, maxHeight: `${size}px` }
    }
  } else {
    customSize = {}
  }

  let customImg: JSX.Element
  if ((img === null || img === '') && abbreviationOfName) {
    if (icon && locationIcon) {
      const children = (
        <div className={`${styles.emptyPhoto} ${styles.photo} ${className}`}>
          <Title type="h4">{abbreviationOfName}</Title>
        </div>
      )
      customImg = getImgWithIconContainer(children)
    } else {
      customImg = (
        <div className={`${styles.emptyPhoto} ${styles.photo} ${className}`}>
          <Title type="h4">{abbreviationOfName}</Title>
        </div>
      )
    }
  } else if ((img === null || img === '') && !abbreviationOfName) {
    if (icon && locationIcon) {
      const children = (
        <div
          className={`${styles.emptyPhoto} ${styles.photo} ${className}`}
        ></div>
      )

      customImg = getImgWithIconContainer(children)
    } else {
      customImg = (
        <div
          className={`${styles.emptyPhoto} ${styles.photo} ${className}`}
        ></div>
      )
    }
  } else if (img) {
    customImg = (
      <>
        <Img
          className={`${styles.photo} ${className}`}
          src={img}
          style={customSize}
          loader={
            <div
              style={customSize}
              className={`${styles.skeleton__img} ${className}`}
            ></div>
          }
          container={(children) => {
            if (icon && locationIcon) {
              return <>{getImgWithIconContainer(children)}</>
            } else {
              return <div className={wrapperClass}>{children}</div>
            }
          }}
        />
      </>
    )
  }
  if (photoView) {
    if (Array.isArray(img) && img.length > 0) {
      return (
        <PhotoProvider>
          <div className={`${styles.photoWrapperPreview}`}>
            {img.map((item, index) => (
              <PhotoView key={index} src={item}>
                <img
                  className={`${styles.photo} ${styles.photo_preview} ${className}`}
                  src={item}
                  alt={``}
                />
              </PhotoView>
            ))}
          </div>
        </PhotoProvider>
      )
    } else {
      return (
        <>
          {' '}
          <div
            className={`${styles.emptyPhoto} ${styles.photo} ${className}`}
          ></div>
        </>
      )
    }
  } else {
    return <>{customImg}</>
  }
})

export default SquarePhoto
