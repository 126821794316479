import styles from './Container.module.scss'

interface Props {
  children: JSX.Element | JSX.Element[] | string
  className?: string
}

const Container = (props: Props) => {
  const { children, className } = props
  return (
    <>
      <div className={`${styles.container} ${className}`}>{children}</div>
    </>
  )
}

export default Container
