export const _apiUrl = process.env.REACT_APP_API_URL
export const _elkAuthUrl = process.env.REACT_APP_ELK_URL.replace(
  'api_url',
  process.env.REACT_APP_API_URL
)
export const _logInUrl = process.env.REACT_APP_LOGIN_URL.replace(
  'api_url',
  process.env.REACT_APP_API_URL
)

export const countDisplayedNeighbours = 25
export const countDisplayedRooms = 25

export const adaptiveWidth = {
  '800px': 800,
  '572px': 572,
  '1040px': 1040,
  '1280px': 1280,
  '950px': 950,
}
