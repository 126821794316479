import { Text, Btn, Container } from '../../index'

import styles from './MobileDialog.module.scss'

interface Props {
  isMobile: boolean
  children: JSX.Element
  onCloseDialog: () => void
}

const MobileDialog = (props: Props) => {
  const { onCloseDialog, isMobile } = props

  if (isMobile) {
    return (
      <>
        <div className={`${styles.overlay}`}>
          <Container className={styles.container}>{props.children}</Container>
        </div>
      </>
    )
  } else {
    return null
  }
}

export default MobileDialog
