import type { RootState } from 'app/store'
export const status = (state: RootState) => state.createAdFormSlice.status

export const photoStatus = (state: RootState) =>
  state.createAdFormSlice.photosStatus

export const postId = (state: RootState) => state.createAdFormSlice.id

export const photosId = (state: RootState) => state.createAdFormSlice.photosId

export const routes = (state: RootState) => state.createAdFormSlice.routes

export const address = (state: RootState) => state.createAdFormSlice.address

export const address_x = (state: RootState) => state.createAdFormSlice.address_x

export const address_y = (state: RootState) => state.createAdFormSlice.address_y

export const errorMessage = (state: RootState) =>
  state.createAdFormSlice.errorMessage

export const errorStatus = (state: RootState) =>
  state.createAdFormSlice.errorStatus
