import {
  createSlice,
  createAsyncThunk,
  SerializedError,
  PayloadAction,
} from '@reduxjs/toolkit'
import { _apiUrl } from 'shared'

import type { TStatusType } from 'shared'

export interface InitDataAddFourities {
  token: string
  id: number
  entity: 'neighbours' | 'rooms'
  typeRequest: 'like' | 'remove_like'
}

// это запрос на бек, который
// 1. добавляет и удаляет в избранное
// 2. любые сущности (пока это комнаты и соседи)
export const toogleFavourites = createAsyncThunk(
  'favouritesSlice/addToFavourites',
  async ({ token, id, entity, typeRequest }: InitDataAddFourities) => {
    let url: string
    if (entity === 'neighbours') {
      url = `/api/v1/user/${id}/${typeRequest}/`
    } else if (entity === 'rooms') {
      url = `/api/v1/room/${id}/${typeRequest}/`
    }
    try {
      const response = await fetch(`${_apiUrl}${url}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Token ${token}`,
        },
      })
      if (response.ok) {
        const data = await response.json()
        return { data, entity }
      } else {
        return response.status
      }
    } catch (error) {
      return error
    }
  }
)

export interface IFavouritiesState {
  neighbourLikeChangeStatus: TStatusType
  roomLikeChangeStatus: TStatusType
  errorFavourities: string | null | SerializedError
  errorStatus: number
}

const initialState: IFavouritiesState = {
  neighbourLikeChangeStatus: 'idle',
  roomLikeChangeStatus: 'idle',
  errorFavourities: null,
  errorStatus: null,
}

const favouritesSlice = createSlice({
  name: 'favouritesSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetchMyData
      .addCase(toogleFavourites.pending, (state) => {
        state.neighbourLikeChangeStatus = 'loading'
        state.roomLikeChangeStatus = 'loading'
      })
      // .addCase(toogleFavourites.rejected, (state, action) => {
      //   if ('error' in action.payload) {
      //     state.neighbourLikeChangeStatus = 'error'
      //     state.roomLikeChangeStatus = 'error'
      //     state.errorFavourities = action.payload.error
      //   }
      // })
      .addCase(toogleFavourites.fulfilled, (state, action) => {
        if (typeof action.payload === 'number') {
          state.errorStatus = action.payload
          const { entity } = action.meta.arg
          if (entity === 'neighbours') {
            state.neighbourLikeChangeStatus = 'error'
          } else if (entity === 'rooms') {
            state.roomLikeChangeStatus = 'error'
          }
        } else {
          if (action.meta.requestStatus === 'fulfilled') {
            const { entity } = action.meta.arg
            if (entity === 'neighbours') {
              state.neighbourLikeChangeStatus = 'success'
            } else if (entity === 'rooms') {
              state.roomLikeChangeStatus = 'success'
            }
          }
        }
      })
  },
})

export default favouritesSlice.reducer
