interface IIcon {
  type:
    | 'complain'
    | 'favorites-filled'
    | 'favorites-tonal'
    | 'share'
    | 'telegram'
    | 'vk'
    | 'phone'
    | 'copy'
    | 'edit'
    | 'car'
    | 'walker'
    | 'arrow'
    | 'exit'
    | 'no-smoking'
    | 'no-alcohol'
    | '1-branch-metro'
    | '2-branches-metro'
    | '3-branches-metro'
    | '4-branches-metro'
    | 'slider'
    | 'pixel'
    | 'tag'
    //
    | 'arrow'
    | 'burger'
    | 'calendar'
    | 'cross_rounded'
    | 'cross_square'
    | 'dots'
    | 'search'
    | 'trash'
    | 'email-letter'
  //
  size?: 'default' | 'large' | 'small' | 'tiny'
  variant?: 'tonal' | 'filled' | 'iconOnly'
  className?: string
  onClick?: () => void
  url?: 'complain' | 'other'
}

interface ISocialIcon {
  type: 'telegram' | 'vk' | 'ok'
  size?: 'default' | 'large' | 'small'
  variant?: 'tonal'
  className?: string
}

const chechIconType = () => {
  const isComplainType = (type: IIcon['type']): type is 'complain' => {
    return type === 'complain'
  }

  const isFavouritesFilledType = (
    type: IIcon['type']
  ): type is 'favorites-filled' => {
    return type === 'favorites-filled'
  }
  
  const isFavouritesTonalType = (
    type: IIcon['type']
  ): type is 'favorites-tonal' => {
    return type === 'favorites-tonal'
  }

  const isShareType = (type: IIcon['type']): type is 'share' => {
    return type === 'share'
  }

  const isTelegramType = (type: IIcon['type']): type is 'telegram' => {
    return type === 'telegram'
  }

  const isPhoneType = (type: IIcon['type']): type is 'phone' => {
    return type === 'phone'
  }

  const isCopyType = (type: IIcon['type']): type is 'copy' => {
    return type === 'copy'
  }

  const isEditType = (type: IIcon['type']): type is 'edit' => {
    return type === 'edit'
  }

  const isArrowType = (type: IIcon['type']): type is 'arrow' => {
    return type === 'arrow'
  }

  const isExitType = (type: IIcon['type']): type is 'exit' => {
    return type === 'exit'
  }

  const isSliderType = (type: IIcon['type']): type is 'slider' => {
    return type === 'slider'
  }

  const isTagType = (type: IIcon['type']): type is 'tag' => {
    return type === 'tag'
  }
  const isCalendarType = (type: IIcon['type']): type is 'calendar' => {
    return type === 'calendar'
  }

  const isTrashType = (type: IIcon['type']): type is 'trash' => {
    return type === 'trash'
  }

  const isSearchType = (type: IIcon['type']): type is 'search' => {
    return type === 'trash'
  }

  return {
    isComplainType,
    isFavouritesFilledType,
    isFavouritesTonalType,
    isShareType,
    isTelegramType,
    isPhoneType,
    isCopyType,
    isEditType,
    isArrowType,
    isExitType,
    isSliderType,
    isTagType,
    isCalendarType,
    isTrashType,
    isSearchType,
  }
}

export type { IIcon, ISocialIcon }
export { chechIconType }
