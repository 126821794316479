import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { roomFilterSelectors } from '..'
import {
  resetAllFilters,
  setAllFilters,
  setPriceMax,
  setPriceMin,
  setMinRoomArea,
  setMaxRoomArea,
  setMinApartmentArea,
  setMaxApartmentArea,
  setMinFloor,
  setMaxFloor,
  setAmountRoomsList,
  setRentalPeriodList,
  setPreferencesList,
  setConveniencesList,
  setBathroomList,
  setPlateList,
  setNeighbourist,
} from '..'
import { useAppDispatch } from 'app/store'
import { SearchAndResetFilters } from 'features/SearchAndResetFilters'
import {
  Range,
  MultipleFilter,
  Checkboxes,
  IMultipleItem,
  TMinimumRentalPeriod,
  useToken,
  adaptiveWidth,
  useClientWidth,
  MobileStickyBlock,
  Btn,
  MobileDialog,
  useRegistered,
} from 'shared'

import styles from './FindRoomFilters.module.scss'
import {
  fetchAllRooms,
  getSearchRoomsParams,
  roomSelectors,
} from 'entities/Room'
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom'
import { fromFiltersToSearhParams } from '../lib/fromFiltersToSearchParams'
import ruble from 'assets/icons/ruble_secondary.svg'

const FindRoomFilters = () => {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const searchParamsObj = getSearchRoomsParams(searchParams)
  const { getToken } = useToken()
  const { getClientWidth } = useClientWidth()

  const [isMobileFilters, setMobileFilters] = useState(false)

  const onCloseMobileFilters = () => {
    setMobileFilters(false)
  }

  const allRoomFilters = useSelector(roomFilterSelectors.allRoomFilters)
  const price_min = useSelector(roomFilterSelectors.price_min)
  const price_max = useSelector(roomFilterSelectors.price_max)
  const min_room_area = useSelector(roomFilterSelectors.min_room_area)
  const max_room_area = useSelector(roomFilterSelectors.max_room_area)
  const min_apartment_area = useSelector(roomFilterSelectors.min_apartment_area)
  const max_apartment_area = useSelector(roomFilterSelectors.max_apartment_area)
  const min_floor = useSelector(roomFilterSelectors.min_floor)
  const max_floor = useSelector(roomFilterSelectors.max_floor)
  const amountRoomsList = useSelector(roomFilterSelectors.amountRoomsList)
  const rentalPeriodList = useSelector(roomFilterSelectors.rentalPeriodList)
  const preferencesList = useSelector(roomFilterSelectors.preferencesList)
  const conveniencesList = useSelector(roomFilterSelectors.conveniencesList)
  const bathroomList = useSelector(roomFilterSelectors.bathroomList)
  const neighbourFilterList = useSelector(
    roomFilterSelectors.neighbourFilterList
  )
  const roomsArrayStatus = useSelector(roomSelectors.roomsArrayStatus)
  const errorStatus = useSelector(roomSelectors.errorStatus)
  const { redirectToAuth } = useRegistered()
  const plateList = useSelector(roomFilterSelectors.plateList)

  useEffect(() => {
    dispatch(resetAllFilters())
    dispatch(setAllFilters(searchParamsObj))
  }, [])

  const [isFullDescrClicked, setFullDescrClicked] = useState(false) // кнопка, отвечающая за развертывание фильтров

  const onSearchRooms = () => {
    const newParams = fromFiltersToSearhParams(
      allRoomFilters,
      searchParams.get['offset']
    )
    dispatch(
      fetchAllRooms({
        token: getToken(),
        searchParams: newParams,
      })
    )
    setSearchParams(newParams as unknown as URLSearchParamsInit)
  }
  if (errorStatus === 401) {
    redirectToAuth()
  }
  const isMobileVersion = getClientWidth() < adaptiveWidth['572px']

  const labelForPrice = (
    <>
      Стоимость, <img src={ruble} alt="" />
    </>
  )

  const content = (
    <>
      <div className={styles.wrapper}>
        <div className={styles.filters}>
          <MultipleFilter
            label="Комнатность"
            wrapperClass={`${styles.adaptiveFilter}`}
            items={amountRoomsList}
            onClick={(id) => {
              dispatch(setAmountRoomsList(id))
            }}
          />
          <Range
            minValue={+price_min}
            maxValue={+price_max}
            label={labelForPrice}
            maxDefaultValue={200000}
            step={100}
            onChangeMin={(item) => dispatch(setPriceMin(item.toString()))}
            onChangeMax={(item) => dispatch(setPriceMax(item.toString()))}
            wrapperClass={`${styles.adaptiveFilter}`}
            type='single'
          />
          <MultipleFilter
            label="Минимальный срок аренды"
            items={rentalPeriodList}
            onClick={(id) => {
              dispatch(setRentalPeriodList(id as TMinimumRentalPeriod))
            }}
            itemClass={`${styles.minArendaPeriodItem}`}
            wrapperClass={`${styles.adaptiveFilter}`}
          />
          <Range
            minValue={+min_room_area}
            maxValue={+max_room_area}
            label="Площадь комнаты, м²"
            maxDefaultValue={100}
            step={1}
            onChangeMin={(item) => dispatch(setMinRoomArea(item.toString()))}
            onChangeMax={(item) => dispatch(setMaxRoomArea(item.toString()))}
            wrapperClass={`${styles.adaptiveFilter}`}
            type='double'
          />
          <Range
            minValue={+min_apartment_area}
            maxValue={+max_apartment_area}
            label="Площадь квартиры, м²"
            maxDefaultValue={200}
            step={1}
            onChangeMin={(item) =>
              dispatch(setMinApartmentArea(item.toString()))
            }
            onChangeMax={(item) =>
              dispatch(setMaxApartmentArea(item.toString()))
            }
            wrapperClass={`${styles.adaptiveFilter}`}
            type='double'
          />
          <div>
            <Range
              minValue={+min_floor}
              maxValue={+max_floor}
              label="Этаж"
              maxDefaultValue={50}
              step={1}
              onChangeMin={(item) => dispatch(setMinFloor(item.toString()))}
              onChangeMax={(item) => dispatch(setMaxFloor(item.toString()))}
              wrapperClass={`${styles.adaptiveFilter}`}
              type='double'
            />
          </div>
        </div>
        <div
          className={styles.checkboxes}
          style={{ display: isFullDescrClicked ? 'flex' : 'none' }}
        >
          <Checkboxes
            label="Преимущества"
            items={preferencesList}
            onClick={(id) => {
              dispatch(setPreferencesList(id))
            }}
            wrapperClass={`${styles.adaptiveFilter} ${styles.checkboxesWrapper}`}
          />
          <Checkboxes
            label="Удобства"
            items={conveniencesList}
            onClick={(id) => {
              dispatch(setConveniencesList(id))
            }}
            wrapperClass={`${styles.adaptiveFilter} ${styles.checkboxesWrapper}`}
          />
          <div className={styles.bathroomAndPlate}>
            <Checkboxes
              label="Санузел"
              items={bathroomList}
              onClick={(id) => {
                dispatch(setBathroomList(id))
              }}
              wrapperClass={`${styles.adaptiveFilter} ${styles.checkboxesWrapper}`}
            />
            <Checkboxes
              label="Плита"
              items={plateList}
              onClick={(id) => {
                dispatch(setPlateList(id))
              }}
              wrapperClass={`${styles.adaptiveFilter} ${styles.checkboxesWrapper}`}
            />
          </div>
          <Checkboxes
            label="Сосед"
            items={neighbourFilterList}
            onClick={(id) => {
              dispatch(setNeighbourist(id))
            }}
            wrapperClass={`${styles.adaptiveFilter} ${styles.checkboxesWrapper}`}
          />
        </div>
        <SearchAndResetFilters
          onClickHide={() => setFullDescrClicked(!isFullDescrClicked)}
          onClickReset={() => {
            dispatch(resetAllFilters())
          }}
          onSeachData={() => {
            onCloseMobileFilters()
            onSearchRooms()
          }}
          isFullDescrClicked={isFullDescrClicked}
          searchBtnText="Показать комнаты"
        />
      </div>
    </>
  )

  if (isMobileVersion) {
    return (
      <>
        <MobileStickyBlock>
          <Btn
            className={styles.mobileFiltersBtn}
            onClick={() => {
              setMobileFilters(true)
            }}
          >
            Фильтры
          </Btn>
        </MobileStickyBlock>
        <MobileDialog
          isMobile={isMobileFilters}
          onCloseDialog={onCloseMobileFilters}
        >
          {content}
        </MobileDialog>
      </>
    )
  } else {
    return <>{content}</>
  }
}

export default FindRoomFilters
