import type { RootState } from 'app/store'

import { setFavotiteNeighbour } from './neighbourSlice'

export const countNeighboursPages = (state: RootState) =>
  state.neighbourSlice.countNeighboursPages

export const neighboursArray = (state: RootState) =>
  state.neighbourSlice.neighboursArray

export const neighbour = (state: RootState) => state.neighbourSlice.neighbour

export const neighboursArrayStatus = (state: RootState) =>
  state.neighbourSlice.neighboursArrayStatus

export const neighbourStatus = (state: RootState) =>
  state.neighbourSlice.neighbourStatus

export const error = (state: RootState) => state.neighbourSlice.error

export const errorStatus = (state: RootState) => state.neighbourSlice.errorStatus

export { setFavotiteNeighbour }
