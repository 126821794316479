import type { RootState } from 'app/store'
import { TNeighbourFilters } from '..'

export const statusForAllFilters = (state: RootState) =>
  state.neighbourFiltersSlice.statusForAllFilters

export const price_max = (state: RootState) =>
  state.neighbourFiltersSlice.price_max

export const price_min = (state: RootState) =>
  state.neighbourFiltersSlice.price_min

export const age_min = (state: RootState) => state.neighbourFiltersSlice.age_min

export const age_max = (state: RootState) => state.neighbourFiltersSlice.age_max

export const gender = (state: RootState) => state.neighbourFiltersSlice.gender

export const course_number = (state: RootState) =>
  state.neighbourFiltersSlice.course_number

export const level_education = (state: RootState) =>
  state.neighbourFiltersSlice.level_education

export const faculty = (state: RootState) => state.neighbourFiltersSlice.faculty

export const against_alcohol = (state: RootState) =>
  state.neighbourFiltersSlice.against_alcohol

export const against_smoking = (state: RootState) =>
  state.neighbourFiltersSlice.against_smoking

export const allNeigFilters = (state: RootState): TNeighbourFilters => {
  return {
    price_max: state.neighbourFiltersSlice.price_max,
    price_min: state.neighbourFiltersSlice.price_min,
    age_min: state.neighbourFiltersSlice.age_min,
    age_max: state.neighbourFiltersSlice.age_max,
    gender: state.neighbourFiltersSlice.gender,
    course_number: state.neighbourFiltersSlice.course_number,
    level_education: state.neighbourFiltersSlice.level_education,
    faculty: state.neighbourFiltersSlice.faculty,
    against_alcohol: state.neighbourFiltersSlice.against_alcohol,
    against_smoking: state.neighbourFiltersSlice.against_smoking,
  }
}

export const allFaculties = (state: RootState) =>
  state.neighbourFiltersSlice.allFaculties

export const allFacultiesStatus = (state: RootState) =>
  state.neighbourFiltersSlice.allFacultiesStatus

export const genderList = (state: RootState) =>
  state.neighbourFiltersSlice.genderList

export const coursesList = (state: RootState) =>
  state.neighbourFiltersSlice.coursesList

export const levelsEducationList = (state: RootState) =>
  state.neighbourFiltersSlice.levelsEducationList

export const wishesList = (state: RootState) =>
  state.neighbourFiltersSlice.wishesList
  export const errorStatus = (state: RootState) =>
  state.neighbourFiltersSlice.errorStatus

