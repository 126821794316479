import { memo, useMemo } from 'react'
import { Text, Title, Icon } from 'shared'
import { INeighbour } from 'shared'
import {
  getWhatNeighbour,
  getLabelBadHabits,
  useClientWidth,
  adaptiveWidth,
} from 'shared'
import getMonthPrice from '../../lib/heplers/getMonthPrice'

import ruble_symbol from 'assets/icons/ruble_symbol.svg'
import no_smoke from 'assets/icons/smoke_alchocol/no_smoke.svg'
import no_alcohol from 'assets/icons/smoke_alchocol/no_alcohol.svg'

import styles from './CardHousePreferences.module.scss'
import MetroIcon from 'shared/ui/Icon/MetroIcon'

interface Props {
  neighbour: INeighbour
}

const CardHousePreferences = memo((props: Props) => {
  const { neighbour } = props
  const { getClientWidth } = useClientWidth()
  const isMobileVersion = getClientWidth() < adaptiveWidth['800px']

  const labelBadHabits = getLabelBadHabits(neighbour)
  const desiredNeighbour = getWhatNeighbour(neighbour)

  const last_row: Element | JSX.Element =
    !desiredNeighbour && !labelBadHabits ? null : (
      <>
        <div className={styles.divider}></div>
        <div className={styles.last__row}>
          {labelBadHabits === null ? null : (
            <div className={styles.smoke__alchocol}>
              {neighbour.against_smoking ? (
                <Icon type="no-smoking" className={styles.icon__smoke} />
              ) : null}
              {neighbour.against_alcohol ? (
                <Icon type="no-alcohol" className={styles.icon__smoke} />
              ) : null}

              <Text type="small" className={styles.text}>
                {labelBadHabits}
              </Text>
            </div>
          )}

          {desiredNeighbour === null ? null : (
            <div className={styles.preferences__neighbours}>
              <div className={styles.icon}></div>
              <Text type="small" className={styles.text_preferences}>
                {desiredNeighbour}
              </Text>
            </div>
          )}
        </div>
      </>
    )
    const metroItems = neighbour.metro.map((station) => {
      return (
        <div className={styles.geo__object}>
          <MetroIcon metroLines={station?.metro_line}/>
          <Text type="small"> {station.name}</Text>
        </div>
      )})
  return (
    <>
      <div className={styles.card}>
        <Text type="small" color="secondary-black">
          Предпочтения по жилью
        </Text>
        <Title className={styles.card__header} type="h4">
          {getMonthPrice(neighbour)} <img src={ruble_symbol} alt="" /> в месяц
        </Title>
        <div className={styles.geo__objects}>
          {/* <div className={styles.geo__object}>
            <div className={styles.geo__icon}></div>
            <Text type={!isMobileVersion ? 'paragraph' : 'small'}>
              Бульвар Адмирала Ушакова
            </Text>
          </div>
          <div className={styles.geo__object}>
            <div className={styles.geo__icon}></div>
            <Text type="paragraph">Бульвар Адмирала Ушакова</Text>
          </div> */}
          {metroItems}
        </div>
        {last_row}
      </div>
    </>
  )
})

export default CardHousePreferences
