import styles from './MetroIcon.module.scss'
import './font_icons/icon_fonts.scss'
import { ReactComponent as MetroDot } from '../../../assets/icons/functional_icons/metro_dot.svg'
import { IMetroLine } from 'shared/types/neighbour'

interface Props {
  // count: number, // количество пересечений метро
  // colors: string[], // - массив цветов
  className?: string
  type?: 'small'
  metroLines?: IMetroLine[]
}

const MetroIcon = (props: Props) => {
  const { className, type, metroLines } = props
  let count = metroLines.length
  let colors = metroLines.map((color) => {
    return color.color_hex
  })
  if (
    count === 1 &&
    (metroLines[0].id === 18 ||
      metroLines[0].id === 19 ||
      metroLines[0].id === 20)
  ) {
    const styleWrapper = {
      backgroundColor: `#${metroLines[0].color_hex}`,
      width: type === 'small' ? '13px' : '',
      height: type === 'small' ? '11px' : '',
      borderRadius: type === 'small' ? '3px' : '',
    }
    return (
      <div className={styles.mcd} style={styleWrapper}>
        <p
          className={styles.mcd__text}
          style={
            type === 'small'
              ? {
                  fontSize: '6px',
                  lineHeight: '5px',
                  height: '7px',
                  width: '8px',
                }
              : {}
          }
        >
          {metroLines[0].name_line.slice(-1)}
        </p>
      </div>
    )
  } else {
    const generateElements = (colors) => {
      return colors.map((color, ind) => {
        return (
          // <span className={`icon-1-branch-metro`} style={{color: color}}></span>
          <MetroDot
            fill={`#${color}`}
            style={type === 'small' ? { width: '4px', height: '4px' } : {}}
            key={ind}
          />
        )
      })
    }
    let wrapper = ''
    switch (count) {
      case 1:
        wrapper = 'one_dot'
        break
      case 2:
        wrapper = 'two_dots'
        break
      case 3:
        wrapper = 'three_dots'
        break
      case 4:
        wrapper = 'four_dots'
        break
    }
    return (
      <>
        <div
          className={` ${className} ${styles.wrapper} ${styles[wrapper]}`}
          style={type === 'small' ? { width: '14px', height: '14px' } : {}}
        >
          {generateElements(colors).slice(0, 4)}
        </div>
      </>
    )
  }
}

export default MetroIcon
