import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INeighbour, TStatusType, _apiUrl } from "shared";
import { IInputData } from "../types";

interface IEditAccState {
	data: INeighbour
	dataStatus: TStatusType
	errorStatus: number
}

const initialState: IEditAccState = {
	data: null, 
	dataStatus: 'idle',
	errorStatus: null
}

export const fetchData = createAsyncThunk(
'EditAccount/fetchData',
  async ({ token }: IInputData) => {
    try {
      const response = await fetch(`${_apiUrl}/api/v1/me/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Token ${token}`,
        },
      })
      if (response.ok) {
        const data: INeighbour = await response.json()
        return data
      } else {
        return response.status
      }
    } catch (error) {
      return error
    }
  }
)

const EditAccountSlice = createSlice({
	name: 'registration',
	initialState,
	reducers: {
		resetEditErrorStatus: (state) => {
			state.errorStatus = null;
		  }
	},
	extraReducers: (builder) => {
		builder.addCase(fetchData.pending, (state) => {
			state.dataStatus = 'loading'
		  })
		builder.addCase(fetchData.rejected, (state) => {
		state.dataStatus = 'error'
		})
		builder.addCase(
			fetchData.fulfilled,
			(state, action: PayloadAction<INeighbour>) => {
			  if (typeof action.payload === 'number') {
				state.errorStatus = action.payload
				state.dataStatus = 'error'
			  } else {
				state.data = action.payload
				state.dataStatus = 'success'
			  }
			}
		  )
	}
})
const {actions} = EditAccountSlice
export const {
	resetEditErrorStatus,
} = actions
export default EditAccountSlice.reducer