import { useState } from 'react'
import { PlatePageStatus } from 'entities/MyAds'
import { DeleteAdModal } from 'features/DeleteAdModal'
import { IRoom } from 'shared'

interface Props {
  room: IRoom
}

const PlateMyAdStatus = (props: Props) => {
  const { room } = props
  const [isShowDeleteMyAd, setIsShowDeleteMyAd] = useState(false)

  const closeDeleteAdModal = () => {
    setIsShowDeleteMyAd(false)
  }

  const openDeleteAdModal = () => {
    setIsShowDeleteMyAd(true)
  }

  return (
    <>
      <PlatePageStatus
        room={room}
        openDeleteAdModal={openDeleteAdModal}
      ></PlatePageStatus>
      < DeleteAdModal
        id={room.id}
        isDeleteAdModal={isShowDeleteMyAd}
        handleCloseModal={closeDeleteAdModal}
      />
    </>
  )
}

export default PlateMyAdStatus
