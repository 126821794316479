import styles from './CreateAdAdditionals.module.scss';
import {CheckboxesForm, TextareaInput, Title} from "shared";
import {useSelector} from "react-redux";
import {roomSelectors} from "../../../entities/Room";


export const CreateAdAdditionals = () => {
  const room = useSelector(roomSelectors.room)

  return (
    <div>
      <Title type={'h3'}>Дополнительно</Title>

      <div className={styles.wrapper}>

        <div className={styles.checkboxWrapper}>
          <CheckboxesForm name={'furniture'}  defaultChecked={room?.furniture} className={styles.checkbox}>Мебель</CheckboxesForm>
          <CheckboxesForm name={'washing_machine'}  defaultChecked={room?.washing_machine} className={styles.checkbox}>Стиральная машина</CheckboxesForm>
          <CheckboxesForm name={'tv'} defaultChecked={room?.tv}  className={styles.checkbox}>Телевизор</CheckboxesForm>
          <CheckboxesForm name={'dishwasher'}  defaultChecked={room?.dishwasher} className={styles.checkbox}>Посудомойка</CheckboxesForm>
        </div>

        <div className={styles.checkboxWrapper}>
          <CheckboxesForm name={'fridge'}  defaultChecked={room?.fridge} className={styles.checkbox}>Холодильник</CheckboxesForm>
          <CheckboxesForm name={'internet'}  defaultChecked={room?.internet} className={styles.checkbox}>Интернет</CheckboxesForm>
          <CheckboxesForm name={'conditioner'}  defaultChecked={room?.conditioner} className={styles.checkbox}>Кондиционер</CheckboxesForm>
          <CheckboxesForm name={'balcony'}  defaultChecked={room?.balcony} className={styles.checkbox}>Балкон</CheckboxesForm>
        </div>

        <div className={styles.checkboxWrapper}>
          <CheckboxesForm name={'freight_elevator'}   defaultChecked={room?.freight_elevator} className={styles.checkbox}>Пассажирский лифт</CheckboxesForm>
          <CheckboxesForm name={'passenger_elevator'}   defaultChecked={room?.passenger_elevator} className={styles.checkbox}>Грузовой лифт</CheckboxesForm>
          <CheckboxesForm name={'garbage_chute'}  defaultChecked={room?.garbage_chute}  className={styles.checkbox}>Мусоропровод</CheckboxesForm>
        </div>

        <div className={styles.checkboxWrapper}>
          <CheckboxesForm name={'pets_allowed'}  defaultChecked={room?.pets_allowed} className={styles.checkbox}>Можно с животными</CheckboxesForm>
          <CheckboxesForm name={'children_allowed'}  defaultChecked={room?.children_allowed} className={styles.checkbox}>Можно с детьми</CheckboxesForm>
        </div>

        <div className={styles.textareaWrapper}>
          <TextareaInput name={'about'} label={'Описание'} className={styles.textarea} defaultValue={room?.about}/>
        </div>

      </div>
    </div>
  );
};

