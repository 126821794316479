import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import {
  _apiUrl,
  IFaculty,
  ILevelsEducation,
  IMultipleItem,
  INeighbourSearchParams,
  ISelectItem,
  TBooleanToSrting,
  TCourse,
  TGender,
  TIdLevelsEducation,
  TLevelsEducation,
  TStatusType,
} from 'shared'

export type TNeighbourFilters = Required<Omit<INeighbourSearchParams, 'offset'>>

function isFacultiesList(
  faculties: IFaculty[] | Error
): faculties is IFaculty[] {
  // console.log(faculties)
  return 'name' in faculties[0]
}

interface IInputData {
  token: string
}

interface IReturnData<T> {
  count: number
  next: any
  previous: any
  results: T
}

export const fetchAllFaculties = createAsyncThunk(
  'neighbourFilters/fetchAllFaculties',
  async ({ token }: IInputData) => {
    try {
      const response = await fetch(`${_apiUrl}/api/v1/faculty/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Token ${token}`,
        },
      })
      if (response.ok) {
        const data: IReturnData<IFaculty[]> = await response.json()
        return data.results
      } else {
        return response.status
      }
    } catch (error) {
      return error
    }
  }
)

type TAgainstWishes = 'against_alcohol' | 'against_smoking'

interface InitialState extends TNeighbourFilters {
  statusForAllFilters: TStatusType
  allFaculties: ISelectItem<string>[]
  allFacultiesStatus: TStatusType

  genderList: IMultipleItem<TNeighbourFilters['gender']>[]
  coursesList: IMultipleItem<TNeighbourFilters['course_number']>[]
  levelsEducationList: ISelectItem<TIdLevelsEducation>[]
  wishesList: IMultipleItem<TAgainstWishes>[]
  errorStatus: number
}

const initialState: InitialState = {
  price_max: '0',
  price_min: '0',
  age_min: '0',
  age_max: '0',
  gender: null,
  course_number: null,
  level_education: null,
  faculty: null,
  against_alcohol: null,
  against_smoking: null,
  statusForAllFilters: 'idle',
  allFaculties: [],
  allFacultiesStatus: 'idle',
  levelsEducationList: [
    {
      value: '1', // id
      label: 'Бакалавриат',
    },
    {
      value: '2', // id
      label: 'Магистратура',
    },
    {
      value: '3', // id
      label: 'Специалитет',
    },
    {
      value: '4', // id
      label: 'Аспирантура',
    },
  ],

  genderList: [
    {
      id: 'MAN',
      text: 'Парень',
      isSelected: false,
    },
    {
      id: 'WOMAN',
      text: 'Девушка',
      isSelected: false,
    },
    {
      id: null,
      text: 'Не важно',
      isSelected: true,
    },
  ],
  coursesList: [
    { id: '1', text: '1', isSelected: false },
    { id: '2', text: '2', isSelected: false },
    { id: '3', text: '3', isSelected: false },
    { id: '4', text: '4', isSelected: false },
    { id: '5', text: '5', isSelected: false },
    {
      id: '7',
      text: 'Выпусник',
      isSelected: false,
    },
  ],
  wishesList: [
    {
      id: 'against_alcohol',
      text: 'Не пьет',
      isSelected: false,
    },
    {
      id: 'against_smoking',
      text: 'Не курит',
      isSelected: false,
    },
  ],
  errorStatus: null,
}

const neighbourFiltersSlice = createSlice({
  name: 'neighbourFilters',
  initialState,
  reducers: {
    resetAllFilters: (state) => {
      state.statusForAllFilters = 'loading'
      state.price_max = initialState.price_max
      state.price_min = initialState.price_min
      state.age_min = initialState.age_min
      state.age_max = initialState.age_max
      state.gender = initialState.gender
      state.course_number = initialState.course_number
      state.level_education = initialState.level_education
      state.faculty = initialState.faculty
      state.against_alcohol = initialState.against_alcohol
      state.against_smoking = initialState.against_smoking
      state.levelsEducationList = initialState.levelsEducationList
      state.genderList = initialState.genderList
      state.coursesList = initialState.coursesList
      state.wishesList = initialState.wishesList

      state.statusForAllFilters = 'success'
    },
    setAllFilters: (state, action: PayloadAction<INeighbourSearchParams>) => {
      // console.log(action.payload)
      state.statusForAllFilters = 'loading'
      if (action.payload.price_max) {
        state.price_max = action.payload.price_max
      }
      if (action.payload.price_min) {
        state.price_min = action.payload.price_min
      }
      if (action.payload.age_min) {
        state.age_min = action.payload.age_min
      }
      if (action.payload.age_max) {
        state.age_max = action.payload.age_max
      }
      if (action.payload.gender) {
        // dispatch()
        state.genderList = state.genderList.map((gender) => {
          return {
            ...gender,
            isSelected: gender.id === action.payload.gender ? true : false,
          }
        })
        state.gender = action.payload.gender
      }
      if (action.payload.course_number) {
        // course_number - '1,2,3,4'

        const courseNumberArray: TCourse[] = action.payload.course_number.split(
          ','
        ) as TCourse[]
        state.coursesList = state.coursesList.map((course) => {
          const isCourseInAddressBar = courseNumberArray.includes(course.id)
          return {
            ...course,
            isSelected: isCourseInAddressBar,
          }
        })
        state.course_number = action.payload.course_number
      }
      if (action.payload.level_education) {
        state.level_education = action.payload.level_education
      }
      if (action.payload.faculty) {
        state.faculty = action.payload.faculty
      }
      if (action.payload.against_alcohol) {
        state.wishesList = state.wishesList.map((wish) => {
          return {
            ...wish,
            isSelected:
              wish.id === 'against_alcohol'
                ? !wish.isSelected
                : wish.isSelected,
          }
        })
        state.against_alcohol = action.payload.against_alcohol
      }
      if (action.payload.against_smoking) {
        state.wishesList = state.wishesList.map((wish) => {
          return {
            ...wish,
            isSelected:
              wish.id === 'against_smoking'
                ? !wish.isSelected
                : wish.isSelected,
          }
        })
        // console.log(state.wishesList)
        state.against_smoking = action.payload.against_smoking
      }
      state.statusForAllFilters = 'success'
    },
    setPriceMax: (
      state,
      action: PayloadAction<TNeighbourFilters['price_max']>
    ) => {
      state.price_max = action.payload
    },
    setPriceMin: (
      state,
      action: PayloadAction<TNeighbourFilters['price_min']>
    ) => {
      state.price_min = action.payload
    },
    setAgeMin: (state, action: PayloadAction<TNeighbourFilters['age_min']>) => {
      state.age_min = action.payload
    },
    setAgeMax: (state, action: PayloadAction<TNeighbourFilters['age_max']>) => {
      state.age_max = action.payload
    },
    setLevelEducation: (
      state,
      action: PayloadAction<TNeighbourFilters['level_education']>
    ) => {
      state.level_education = action.payload
    },
    setFaculty: (
      state,
      action: PayloadAction<TNeighbourFilters['faculty']>
    ) => {
      state.faculty = action.payload
    },
    setAgainstAlcohol: (state) => {
      state.wishesList = state.wishesList.map((wish) => {
        if (wish.id === 'against_alcohol') {
          if (wish.isSelected) {
            state.against_alcohol = 'false'
          } else {
            state.against_alcohol = 'true'
          }
          return {
            ...wish,
            isSelected: !wish.isSelected,
          }
        } else {
          return wish
        }
      })
    },
    setAgainstSmoking: (state) => {
      state.wishesList = state.wishesList.map((wish) => {
        if (wish.id === 'against_smoking') {
          if (wish.isSelected) {
            state.against_smoking = 'false'
          } else {
            state.against_smoking = 'true'
          }
          return {
            ...wish,
            isSelected: !wish.isSelected,
          }
        } else {
          return wish
        }
      })
      // console.log(state.wishesList)
    },
    setGenderList: (
      state,
      action: PayloadAction<TNeighbourFilters['gender']>
    ) => {
      state.genderList = state.genderList.map((gender) => {
        return {
          ...gender,
          isSelected: gender.id === action.payload ? true : false,
        }
      })
      state.gender = action.payload
    },
    setCoursesList: (
      state,
      action: PayloadAction<TNeighbourFilters['course_number']>
    ) => {
      // тут всё изменить, чтобы был малтипл выбор

      state.coursesList = state.coursesList.map((course) => {
        const isRelevantCourse = course.id === action.payload
        return {
          ...course,
          isSelected: isRelevantCourse ? !course.isSelected : course.isSelected,
        }
      })
      state.course_number = state.coursesList
        .filter((course) => course.isSelected)
        .map((course) => course.id)
        .join(',') as TCourse

      // console.log(state.course_number)
    },
  },
  extraReducers: (builder) => {
    // fetchAllFaculties
    builder.addCase(fetchAllFaculties.pending, (state) => {
      state.allFacultiesStatus = 'loading'
    })
    builder.addCase(fetchAllFaculties.rejected, (state) => {
      state.allFacultiesStatus = 'error'
    })
    builder.addCase(
      fetchAllFaculties.fulfilled,
      (state, action: PayloadAction<IFaculty[] | number>) => {
        if (typeof action.payload === 'number') {
          state.errorStatus = action.payload
          state.allFacultiesStatus = 'error'
        } else {
          if (isFacultiesList(action.payload)) {
            state.allFaculties = action.payload.map((facul) => {
              return {
                value: facul.id.toString(),
                label: facul.name,
              }
            })
          }
          // state.allFaculties = action.payload
          state.allFacultiesStatus = 'success'
        }
      }
    )
  },
})

export default neighbourFiltersSlice.reducer

export const {
  resetAllFilters,
  setAllFilters,
  setPriceMax,
  setPriceMin,
  setAgeMin,
  setAgeMax,

  setLevelEducation,
  setFaculty,
  setAgainstAlcohol,
  setAgainstSmoking,
  setGenderList,
  setCoursesList,
} = neighbourFiltersSlice.actions
