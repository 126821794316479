import type { RootState } from 'app/store'

export const myData = (state: RootState) => state.myDataSlice.myData

export const myDataStatus = (state: RootState) => state.myDataSlice.myDataStatus

export const editMyDataStatus = (state: RootState) =>
  state.myDataSlice.editMyDataStatus

export const myFavoriteNeighbours = (state: RootState) =>
  state.myDataSlice.myFavoriteNeighbours

export const myFavoriteNeighboursStatus = (state: RootState) =>
  state.myDataSlice.myFavoriteNeighboursStatus

export const error = (state: RootState) => state.myDataSlice.errorMyData

export const errorEditMyData = (state: RootState) =>
  state.myDataSlice.errorEditMyData

export const errorMyData = (state: RootState) =>
  state.myDataSlice.errorMyData

export const errorStatus = (state: RootState) =>
  state.myDataSlice.errorStatus

