import { Suspense } from 'react'

import { BrowserRouter as Router } from 'react-router-dom'

import { useLayoutEffect, useState } from 'react'
import { createBrowserHistory } from 'history'
import { Title } from 'shared' // ~ shared/ui/sping

export const withRouter = (component: () => React.ReactNode) => () =>
  (
    <Router>
      <Suspense fallback={null}>{component()}</Suspense>
    </Router>
  )
