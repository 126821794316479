import { INeighbour } from 'shared'

const getWhatNeighbour = (neighbour: INeighbour): string | null => {
  const { what_neighbor } = neighbour
  if (what_neighbor === 'MAN') {
    return `Ищу соседа`
  } else if (what_neighbor === 'WOMAN') {
    return `Ищу соседку`
  } else if (what_neighbor === 'NO_MATTER') {
    return 'Без предпочтений к соседу'
  }
}

export default getWhatNeighbour
