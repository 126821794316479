import { INeighbourSearchParams } from 'shared'

// ВАЖНО!!!
// При изменении интерфейса INeighbourSearchParams ОБЯЗАТЕЛЬНО нужно прописать ветку if для добавления/удаления параметра.
// Так как наличие параметров проверяется с помощью фун-ции has(), то нельзя ошибаться в написании параметров в has()

// Функция, которая вытаскивает все параметры из адресной строки и формирует из этого объект

export const concatSearchParams = (
  searchParams: INeighbourSearchParams
): string => {
  let params: string = ''

  if (searchParams.offset) {
    params = params + `&offset=${searchParams.offset}`
  }
  if (searchParams.price_max) {
    params = params + `&price_max=${searchParams.price_max}`
  }
  if (searchParams.price_min) {
    params = params + `&price_min=${searchParams.price_min}`
  }
  if (searchParams.age_min) {
    params = params + `&age_min=${searchParams.age_min}`
  }
  if (searchParams.age_max) {
    params = params + `&age_max=${searchParams.age_max}`
  }
  if (searchParams.gender) {
    params = params + `&gender=${searchParams.gender}`
  }
  if (searchParams.course_number) {
    params = params + `&course_number=${searchParams.course_number}`
  }
  if (searchParams.level_education) {
    params = params + `&level_education=${searchParams.level_education}`
  }
  if (searchParams.faculty) {
    params = params + `&faculty=${searchParams.faculty}`
  }
  if (searchParams.against_alcohol) {
    params = params + `&against_alcohol=${searchParams.against_alcohol}`
  }

  if (searchParams.against_smoking) {
    params = params + `&against_smoking=${searchParams.against_smoking}`
  }

  return params
}
