import { useRef, useEffect } from 'react'

import styles from './Overlay.module.scss'

interface Props {
  children: JSX.Element | JSX.Element[]
  closeOverlay: () => void
  isOverlayOpen: boolean
  className?: string
}

const Overlay = (props: Props) => {
  const { closeOverlay, className, isOverlayOpen } = props
  const overlayRef = useRef<HTMLDivElement>(null)
  // console.log('rerender overlay')
  const handleClick = (event: Event) => {
    // console.log(overlayRef.current === event.target)
    if (overlayRef.current === event.target) {
      closeOverlay()
    }
  }

  useEffect(() => {
    // этот эффект закрывает модалку при нажатии юзера за пределы мобилки
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  return (
    <>
      {isOverlayOpen ? (
        <div
          className={`${styles.overlay} ${className ? className : ''}`}
          ref={overlayRef}
        >
          {props.children}
        </div>
      ) : null}
    </>
  )
}

export default Overlay
