import type { RootState } from "app/store"

export const checkAuthStatus = (state: RootState) =>
	state.AuthSlice.checkAuthStatus

export const errorStatus = (state: RootState) =>
	state.AuthSlice.errorStatus

export const was_registered = (state: RootState) =>
	state.AuthSlice.was_registered

export const token = (state: RootState) =>
	state.AuthSlice.token

export const data = (state: RootState) =>
	state.AuthSlice.data

export const authElkStatus = (state: RootState) =>
	state.AuthSlice.authElkStatus
