import {
  createSlice,
  createAsyncThunk,
  SerializedError,
  PayloadAction,
} from '@reduxjs/toolkit'
import { concatSearchParams } from '../lib/heplers/concatSearchParams'
import { _apiUrl, INeighbourSearchParams } from 'shared'
import type { INeighbour, TStatusType } from 'shared'
import { countDisplayedNeighbours } from 'shared'

interface NeighboursData {
  count: number
  next: string | null
  previous: string | null
  results: INeighbour[]
}

interface InitDataFetchAllNeig {
  token: string
  searchParams: INeighbourSearchParams
}

export const fetchAllNeighbours = createAsyncThunk(
  'neighbours/fetchAllNeighbours',
  async ({ token, searchParams }: InitDataFetchAllNeig) => {
    try {
      const response = await fetch(
        `${_apiUrl}/api/v1/user/?limit=${countDisplayedNeighbours}${concatSearchParams(
          searchParams
        )}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Authorization: `Token ${token}`,
          },
        }
      )
      if (response.ok) {
        const data: NeighboursData = await response.json()
        return data
      } else {
        return response.status
      }
    } catch (error) {
      return error
    }
  }
)

interface IFetchOneNeighbour {
  id: number
  token: string
}

export const fetchOneNeighbour = createAsyncThunk(
  'neighbours/fetchOneNeighbour',
  async ({ id, token }: IFetchOneNeighbour) => {
    try {
      const response = await fetch(`${_apiUrl}/api/v1/user/${id.toString()}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Token ${token}`,
        },
      })
      if (response.ok) {
        const data: INeighbour = await response.json()
        return data
      } else {
        return response.status
      }
    } catch (error) {
      return error
    }
  }
)

export interface INeighboursState {
  // хз почему ts заставил записать тип Error в массив соседей
  countNeighboursPages: number
  neighboursArray: INeighbour[]
  neighbour: INeighbour
  neighbourStatus: TStatusType
  neighboursArrayStatus: TStatusType
  error: string | null | SerializedError
  errorStatus: number
}

const initialState: INeighboursState = {
  countNeighboursPages: 0,
  neighboursArray: [],
  neighbour: null,
  neighbourStatus: 'idle',
  neighboursArrayStatus: 'idle',
  error: null,
  errorStatus: null,
}

const neighbourSlice = createSlice({
  name: 'neighbours',
  initialState,
  reducers: {
    setFavotiteNeighbour: (
      state,
      action: PayloadAction<{ id: number; is_favorite: boolean }>
    ) => {
      if (state?.neighbour?.id === action.payload.id) {
        state.neighbour.is_favorite = action.payload.is_favorite
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllNeighbours.pending, (state) => {
        state.neighboursArrayStatus = 'loading'
      })
      .addCase(fetchAllNeighbours.rejected, (state, action) => {
        state.neighboursArrayStatus = 'error'
        state.error = action.error
      })
      .addCase(
        fetchAllNeighbours.fulfilled,
        (state, action: PayloadAction<NeighboursData | number>) => {


          if (
            typeof action.payload !== 'number' &&
            'results' in action.payload
          ) {
            state.neighboursArray = action.payload.results
            state.countNeighboursPages = Math.ceil(
              action.payload.count / countDisplayedNeighbours
            )
            state.neighboursArrayStatus = 'success'
          } else if (
            typeof action.payload == 'number' &&
            action.payload >= 400
          ) {
            state.neighboursArrayStatus = 'error'
            state.errorStatus = action.payload
          }
        }
      )
      .addCase(fetchOneNeighbour.pending, (state) => {
        state.neighbourStatus = 'loading'
      })
      .addCase(fetchOneNeighbour.rejected, (state, action) => {
        state.neighbourStatus = 'error'
        state.error = action.error
      })
      .addCase(
        fetchOneNeighbour.fulfilled,
        (state, action: PayloadAction<INeighbour | number>) => {
          if (typeof action.payload === 'number') {
            state.errorStatus = action.payload
            state.neighbourStatus = 'error'
          } else {
            state.neighbour = action.payload
            state.neighbourStatus = 'success'
          }
        }
      )
  },
})

const { setFavotiteNeighbour } = neighbourSlice.actions

export { setFavotiteNeighbour }

export default neighbourSlice.reducer
