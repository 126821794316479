import { ReactNode, memo, useEffect } from 'react'
import { Overlay } from '../../index'
import x_svg from 'assets/icons/functional_icons/x.svg'

import styles from './ModalWraper.module.scss'

interface Props {
  isModalOpen: boolean
  showCloseBtn?: boolean
  closeModal?: () => void
  children: ReactNode
  wrapperClass?: string
  closeButtonVisibility?: boolean,
}

const ModalWraper = memo((props: Props) => {
  const { isModalOpen, showCloseBtn = true, closeModal, wrapperClass } = props

  return (
    <>
      {isModalOpen ? (
        <Overlay closeOverlay={closeModal} isOverlayOpen={isModalOpen}>
          <div className={`${styles.modal} ${wrapperClass}`}>
            {showCloseBtn ? (
              <div onClick={closeModal} className={styles.close}>
                <img src={x_svg} alt="" />
              </div>
            ) : null}
            {props.children}
          </div>
        </Overlay>
      ) : null}
    </>
  )
})

export default ModalWraper
