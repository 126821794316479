import { memo } from 'react'
import { IIcon, chechIconType } from './types'
import styles from './Icon.module.scss'
import './font_icons/icon_fonts.scss'
import './font_icons/icon_fonts_2.scss'
import './font_icons/icon_fonts_3.scss'

import slider_svg from 'assets/icons/functional_icons/slider.svg'
import tag_icon from 'assets/icons/tag_icon.svg'
import against_alcohol from 'assets/icons/smoke_alchocol/against_alcohol.svg'
import against_smoking from 'assets/icons/smoke_alchocol/against_smoking.svg'

const Icon = memo((props: IIcon) => {
  const {
    type,
    size = 'default',
    variant = 'iconOnly',
    className,
    onClick,
    url
  } = props
  const {
    isComplainType,
    isFavouritesFilledType,
    isFavouritesTonalType,
    isShareType,
    isEditType,
    isTelegramType,
    isPhoneType,
    isCopyType,
    isExitType,
    isSliderType,
    isTagType,
    isArrowType,
    isCalendarType,
    isTrashType,
    isSearchType,
  } = chechIconType()
  let sizeClass: string = ''
  let variantClass: string
  let colorClass: string

  if (size === 'large') {
    sizeClass = styles.large
  } else if (size === 'small') {
    sizeClass = styles.small
  } else if (size === 'tiny'){
    sizeClass = styles.tiny
  }

  if (variant === 'tonal') {
    variantClass = `${styles.icon__wrapper} ${styles.icon__wrapper_tonal}`
  } else if (variant === 'filled') {
    variantClass = `${styles.icon__wrapper} ${styles.icon__wrapper_fill}`
  } else if (variant === 'iconOnly') {
    variantClass = `${styles.icon__wrapper_iconOnly}`
  } 
  if (
    isComplainType(type) ||
    isFavouritesTonalType(type) ||
    isShareType(type) ||
    isEditType(type) ||
    isFavouritesFilledType(type) ||
    isPhoneType(type) ||
    isTagType(type) ||
    isArrowType(type)
  ) {
    colorClass = styles.purple__color
  } else if (
    isCopyType(type) ||
    isExitType(type) ||
    isCalendarType(type) ||
    isTrashType(type) ||
    isSearchType(type)
  ) {
    colorClass = styles.black__color
  } else if (
    isTelegramType(type)
  ) {
    colorClass = styles.telegram_icon
  }

  let content
  if (isSliderType(type)) {
    content = (
      <>
        <div
          className={`${variantClass} ${sizeClass} ${className}`}
          onClick={onClick}
        >
          <img src={slider_svg} alt="" />
          {/* <span className={`icon-${type} ${colorClass}`}>
            <span className="path1"></span>
            <span className="path2"></span>
          </span> */}
        </div>
      </>
    )
  } else if (isTagType(type)) {
    content = (
      <>
        <div
          className={`${variantClass} ${sizeClass} ${className}`}
          onClick={onClick}
        >
          <img src={tag_icon} alt="" />
        </div>
      </>
    )
  } else if (type === 'no-alcohol' || type === 'no-smoking') {
    content = (
      <>
        <div
          className={`${variantClass} ${sizeClass} ${className}`}
          onClick={onClick}
        >
          <img
            src={type === 'no-alcohol' ? against_alcohol : against_smoking}
            alt=""
          />
        </div>
      </>
    )
  } else {
    content = (
      <>
        {url === 'complain' ? 
          <>
          <a 
          href="https://docs.google.com/forms/d/e/1FAIpQLSdbELaCHwpEbXmImeiW8ZeFiY6ztg3uxgBa9NpcTcAVwuQGlw/viewform?usp=sharing" 
          target="_blank" 
          className={styles.noLink}
          rel="noopener noreferrer">
        <div
          className={`${variantClass} ${sizeClass} ${className}`}
          onClick={onClick}
        >
            <span className={`icon-${type} ${colorClass}`}></span> 
        </div> 
        </a></>
        :         <div
        className={`${variantClass} ${sizeClass} ${className}`}
        onClick={onClick}
      >
      
        
          <span className={`icon-${type} ${colorClass}`}></span>
       
        
      </div> }
      </>
    )
  }
  return <>{content} </>
})

export default Icon
