import styles from './UtilitiesPlate.module.scss'

const UtilitiesPlate = (props) => {
	const { className = '' } = props
	return (
		<>
			<div className={`${styles.UtilitiesPlate} ${className}`}>
			{props.children}
			</div>
		</>
	)
}

export default UtilitiesPlate