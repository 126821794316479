import styles from './Toggle.module.scss'
interface Props {
  initialState: boolean | 'disabled'
  onClick?: () => void
}

const Toggle = (props: Props) => {
  const { initialState, onClick } = props
  let toggleStateClass: string
  if (initialState === 'disabled') {
    toggleStateClass = styles.toogle_disabled
  } else if (initialState) {
    toggleStateClass = styles.toogle_on
  } else if (!initialState) {
    toggleStateClass = styles.toogle_off
  }

  return (
    <>
      <div
        className={`${styles.toogle} ${toggleStateClass}`}
        onClick={() => {
          if (initialState !== 'disabled') {
            onClick()
          }
        }}
      >
        <div className={`${styles.circle}`} />
      </div>
    </>
  )
}

export default Toggle
