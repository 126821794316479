import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { _apiUrl } from 'shared/const'
import { TStatusType } from 'shared/types/common'

interface IAuthStatus {
  checkAuthStatus: TStatusType
  authElkStatus: TStatusType
  errorStatus: number | string
  was_registered: boolean
  token: string
  data: Omit<authData, 'token' | 'was_registered'>
}

const initialState: IAuthStatus = {
  checkAuthStatus: 'idle',
  authElkStatus: 'idle',
  errorStatus: null,
  was_registered: false,
  token: '',
  data: null,
}

interface authData {
  first_name: string
  last_name: string
  birthday: string | null
  token: string
  was_registered: boolean
}

export const fetchAuthCode = createAsyncThunk(
  'auth/fetchAuthCode',
  async (authCode: string) => {
    try {
      const response = await fetch(
        `${_apiUrl}/api/v1/auth/elk_auth/${authCode}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
        }
      )
      if (response.ok) {
        const data: authData = await response.json()
        return data
      } else {
        return response.status
      }
    } catch (error) {
      return error
    }
  }
)

export const checkToken = createAsyncThunk(
  'checkAuthSlice',
  async (token: string) => {
    try {
      const response = await fetch(`${_apiUrl}/api/v1/check_auth/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Token ${token}`,
        },
      })
      if (response.ok) {
        const wasRegisteredObj: { was_registered: boolean } =
          await response.json()
        return wasRegisteredObj.was_registered
      } else {
        return response.status
      }
    } catch (error) {
      return error
    }
  }
)
const checkAuthSlice = createSlice({
  name: 'checkAuth',
  initialState,
  reducers: {
    setTokenStatus: (state, action: PayloadAction<TStatusType>) => {
      state.checkAuthStatus = action.payload
    },
    setWasRegistered: (state, action: PayloadAction<boolean>) => {
      state.was_registered = action.payload
    },
    resetAuthErrorStatus: (state) => {
      state.errorStatus = null;
    },
    resetCheckAuthStatus: (state) => {
      state.checkAuthStatus = 'idle'
    }
  },
  extraReducers: (builder) => {
    // checkToken
    builder
      .addCase(checkToken.pending, (state) => {
        state.checkAuthStatus = 'loading'
      })
      .addCase(checkToken.rejected, (state, action) => {
        state.checkAuthStatus = 'error'
        state.errorStatus = action.error.message
      })
      .addCase(
        checkToken.fulfilled,
        (state, action: PayloadAction<boolean | number>) => {
          if (typeof action.payload === 'boolean') {
            state.checkAuthStatus = 'success'
            state.was_registered = action.payload
          } else {
            state.errorStatus = action.payload
            state.checkAuthStatus = 'error'
          }
        }
      )
      // fetchAuthCode
      .addCase(fetchAuthCode.pending, (state) => {
        state.authElkStatus = 'loading'
      })
      .addCase(fetchAuthCode.rejected, (state, action) => {
        state.authElkStatus = 'error'
        state.errorStatus = action.error.message
      })
      .addCase(
        fetchAuthCode.fulfilled,
        (state, action: PayloadAction<authData | number>) => {
          if (typeof action.payload !== 'number') {
            state.token = action.payload.token
            state.was_registered = action.payload.was_registered
            state.authElkStatus = 'success'
            state.data = {
              first_name: action.payload.first_name,
              last_name: action.payload.last_name,
              birthday:
                action.payload.birthday === 'null' || !action.payload.birthday
                  ? null
                  : action.payload.birthday,
            }
            // dispatch(registrationDataUpdate({fieldName: name, value: action.payload.name}))
          } else if (
            typeof action.payload == 'number' &&
            action.payload >= 400
          ) {
            state.authElkStatus = 'error'
            state.errorStatus = action.payload
          }
        }
      )
  },
})
export const { setWasRegistered, setTokenStatus, resetAuthErrorStatus, resetCheckAuthStatus } = checkAuthSlice.actions
export default checkAuthSlice.reducer
