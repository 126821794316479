import { memo, useState } from 'react'
import { ContactsModal } from 'features/ContactsModal'
import { AddAndRemoveFavourites } from 'features/AddAndRemoveFavourites'
import type { FavouritiesProps } from 'features/AddAndRemoveFavourites'
import { NeighbourCard as Card } from 'entities/Neighbour'

import { INeighbour } from 'shared'

interface Props {
  neighbour: INeighbour
}

const NeighbourCard = (props: Props) => {
  const { neighbour } = props
  const { tg_username, phone } = neighbour
  const [isContactsModalOpen, setContactsModalOpen] = useState(false)

  const openContactsModal = () => {
    setContactsModalOpen(true)
  }

  const closeContactsModal = () => {
    setContactsModalOpen(false)
  }

  const contacts = {
    telegram: tg_username,
    phone,
    isShowPhone: neighbour.show_phone,
  }

  const addAndRemoveFavourites = (props: FavouritiesProps) => {
    return (
      <AddAndRemoveFavourites
        entity={props.entity}
        isFavorite={props.isFavorite}
        id={props.id}
        className={props.className}
        toastText="Сосед удален из избранного"
      />
    )
  }
  return (
    <>
      <Card
        addAndRemoveFavourites={addAndRemoveFavourites}
        neighbour={neighbour}
        openContactsModal={openContactsModal}
      />
      <ContactsModal
        contacts={contacts}
        isContactsModalOpen={isContactsModalOpen}
        closeModal={closeContactsModal}
      />
    </>
  )
}

export default NeighbourCard
