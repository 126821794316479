import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'app/store'
import { MyAdsCard } from 'entities/MyAds'
import { RoomCard } from 'entities/Room'
import { fetchMyRoomAds, myAdsSelectors } from 'entities/MyAds'
import {
  useToken,
  Title,
  useRegistered,
  Container,
  useClientWidth,
  adaptiveWidth,
} from 'shared'

import styles from './MyAdsList.module.scss'

const MyAdsList = () => {
  const dispatch = useAppDispatch()
  const allMyRoomAds = useSelector(myAdsSelectors.allMyRoomAds)
  const allMyRoomAdsStatus = useSelector(myAdsSelectors.allMyRoomAdsStatus)
  const { getClientWidth } = useClientWidth()
  const isMobileVersion = getClientWidth() < adaptiveWidth['800px']
  const { getToken } = useToken()

  useEffect(() => {
    dispatch(fetchMyRoomAds({ token: getToken() }))
  }, [])
  const { redirectToAuth } = useRegistered()
  const errorStatus = useSelector(myAdsSelectors.errorStatus)
  if (errorStatus === 401) {
    redirectToAuth()
  }

  let content: JSX.Element[] | JSX.Element
  if (allMyRoomAdsStatus === 'success' && allMyRoomAds.length !== 0) {
    content = allMyRoomAds.map((room, ind) => (
      <RoomCard key={ind} room={room} />
      // <MyAdsCard key={room.id} room={room} />
    ))
  } else if (allMyRoomAdsStatus === 'success' && allMyRoomAds.length === 0) {
    if (isMobileVersion) {
      content = (
        <Container>
          <Title type="h4">Не добавлено объявлений</Title>
        </Container>
      )
    } else {
      content = <Title type="h3">Не добавлено объявлений</Title>
    }
  } else if (allMyRoomAdsStatus === 'error') {
    if (isMobileVersion) {
      content = (
        <Container>
          <Title type="h4">Упс... Что-то пошло не так</Title>
        </Container>
      )
    }
    content = <Title type="h3">Упс... Что-то пошло не так</Title>
  }

  if (isMobileVersion) {
    return <div className={styles.cards}>{content}</div>
  } else
    return (
      <Container>
        <div className={styles.cards}>{content}</div>
      </Container>
    )
}

export default MyAdsList
