import { forwardRef, HTMLAttributes, PropsWithChildren } from "react";
import styles from './ErrorMessage.module.scss'

interface Props extends HTMLAttributes<HTMLDivElement> {
  title: string;
}

export const ErrorMessage = forwardRef<HTMLDivElement, PropsWithChildren<Props>>((props, ref) => {
  const { children, className, title } = props
  return (
    <div className={`${className} ${styles.wrapper} `}>
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.text}>{children}</p>
    </div>
  );
});

