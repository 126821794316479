import { memo } from 'react'
import {
  Btn,
  SquarePhoto,
  Icon,
  Text,
  useClientWidth,
  adaptiveWidth,
} from 'shared'
import type { IPhoto } from 'shared'

import styles from './LeftColumnRoom.module.scss'

interface Props {
  photo: IPhoto[]
  shareEntity: (props: { url: string }) => JSX.Element
  contactsModal: JSX.Element
  openContactsModal: () => void
  addAndRemoveFavourites: () => JSX.Element
}
const LeftColumnRoom = memo((props: Props) => {
  const {
    photo,
    contactsModal,
    openContactsModal,
    shareEntity,
    addAndRemoveFavourites,
  } = props
  const { getClientWidth } = useClientWidth()
  const url = window.location.href

  const photos =
    photo.length > 0
      ? photo.map((photo) => {
          return photo.image
        })
      : null

  if (getClientWidth() < adaptiveWidth['1040px']) {
    return (
      <>
        <div className={styles.left__column}>
          <SquarePhoto img={photos} photoView className={styles.squarePhoto} />
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className={styles.left__column}>
          <SquarePhoto img={photos} photoView className={styles.squarePhoto} />
          <Btn
            width={'100%'}
            onClick={() => {
              openContactsModal()
            }}
          >
            <Text type="paragraph" weight="bold" color="white-main">
              Связаться с владельцем
            </Text>
          </Btn>
          {contactsModal}
          <div className={styles.icons}>
            {addAndRemoveFavourites()}
            <Icon url='complain' type={'complain'} variant="tonal" />
            {shareEntity({ url })}
          </div>
        </div>
      </>
    )
  }
})

export default LeftColumnRoom
