import { INeighbour } from 'shared'

const getGender = (neighbour: INeighbour): string => {
  const { gender } = neighbour

  if (gender === 'MAN') {
    return `Парень`
  } else if (gender === 'WOMAN') {
    return `Девушка`
  }
}

export default getGender
