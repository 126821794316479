const getCurrentDate = (): string => {
  let today = new Date()
  let month = String(today.getDate()).padStart(2, '0')
  let day = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
  let year = today.getFullYear()

  return day + '-' + month + '-' + year
}

function diff_years(dt2, dt1) {
  var diff = (dt1.getTime() - dt2.getTime()) / 1000
  diff /= 60 * 60 * 24
  return Math.abs(Math.floor(diff / 365))
}

function getNumericalAge(birthDate: string) {
  const newBirthDate: Date = new Date(birthDate)
  const currentDate = new Date(getCurrentDate())

  let years = currentDate.getFullYear() - newBirthDate.getFullYear()

  if (
    currentDate.getMonth() < newBirthDate.getMonth() ||
    (currentDate.getMonth() === newBirthDate.getMonth() &&
      currentDate.getDate() < newBirthDate.getDate())
  ) {
    years--
  }

  return years
}

function getAge(birthDate: string): string {
  if (birthDate){
    let d1 = new Date(birthDate)
      let d2 = new Date()
  const age = +diff_years(d1, d2).toString()
  // const age: number = getNumericalAge(birthDate)
  let txt: string
  let count: number = age % 100
  if (count >= 5 && count <= 20) {
    txt = 'лет'
  } else {
    count = count % 10
    if (count === 1) {
      txt = 'год'
    } else if (count >= 2 && count <= 4) {
      txt = 'года'
    } else {
      txt = 'лет'
    }
  }
  return age + ' ' + txt
  } else return null
}

export default getAge
