import styles from './Logo.module.scss'
import logo from 'assets/logo/logo_main.svg'

interface IProps {
  className?: string
  logoClassName?: string
}

const Logo = ({ className, logoClassName }: IProps) => {
  return (
    <>
      <picture>
        <source className={`${styles.logo} ${className}`} srcSet={logo} />
        <img className={`${styles.logo} ${className} ${logoClassName}`} src={logo} alt="logo" />
      </picture>
    </>
  )
}

export default Logo
