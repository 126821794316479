import { useSelector } from 'react-redux'

import { useAppDispatch } from 'app/store'
import { myAdsSelectors, editOneMyAdRoom } from 'entities/MyAds'
import {
  Btn,
  IRoom,
  Text,
  getMyAdStatus,
  useToken,
  useClientWidth,
  adaptiveWidth,
  Container,
  Icon,
  useRegistered,
} from 'shared'

import onModerIcon from 'assets/icons/my_ads_icons/on_moderation.svg'
import lockIcon from 'assets/icons/my_ads_icons/lock.svg'
import hiddenIcon from 'assets/icons/my_ads_icons/hidden.svg'
// import trashIcon from 'assets/icons/functional_icons/trash.svg'

import styles from './PlatePageStatus.module.scss'
import { Link, useNavigate, useParams } from 'react-router-dom'

interface Props {
  room: IRoom
  className?: string
  openDeleteAdModal: () => void
}

const PlatePageStatus = (props: Props) => {
  const navigate = useNavigate()
  const { room, className, openDeleteAdModal } = props
  const dispatch = useAppDispatch()
  const { getToken } = useToken()
  const { getClientWidth } = useClientWidth()
  const { myAdsId } = useParams()
  const status = getMyAdStatus(room)
  const editMyAdStatus = useSelector(myAdsSelectors.editMyAdStatus)
  const errorStatus = useSelector(myAdsSelectors.errorStatus)
  const { redirectToAuth } = useRegistered()
  const handleTogglePublishAd = async (type: 'hide' | 'publish') => {
    // скрываем объявление, если hide и публикуем, если publish
    await dispatch(
      editOneMyAdRoom({
        method: 'PATCH',
        id: room.id,
        token: getToken(),
        data: {
          is_published: type === 'hide' ? false : true,
        },
      })
    )
  }
  if (errorStatus === 401) {
    redirectToAuth()
  }
  let rightPannel: JSX.Element

  if (status === 'ON_MODERATION') {
    rightPannel = (
      <>
        <div className={styles.rightPannel}>
          <div className={styles.textWrapper}>
            <img src={onModerIcon} alt="" />
            <Text type="paragraph">Объявление на модерации</Text>
          </div>
        </div>
      </>
    )
  } else if (status === 'HIDDEN') {
    if (getClientWidth() < adaptiveWidth['950px']) {
      rightPannel = (
        <>
          <div className={styles.textWrapper}>
            <img src={hiddenIcon} alt="" />
            <Text type="paragraph">Объявление снято с публикации</Text>
          </div>

          <div className={styles.btnsGroup}>
            <Btn
              className={styles.btn}
              onClick={() => {
                handleTogglePublishAd('publish')
              }}
              type="tonal"
            >
              Опубликовать
            </Btn>
            <Btn
              className={`${styles.btn} ${styles.btnText}`}
              onClick={() => navigate(`/edit-ad/${myAdsId}`)}
              type="text"
              color="purple-main"
            >
              Редактировать
            </Btn>
          </div>
        </>
      )
    } else {
      rightPannel = (
        <>
          <div className={styles.rightPannel}>
            <div className={styles.textWrapper}>
              <img src={hiddenIcon} alt="" />
              <Text type="paragraph">Объявление снято с публикации</Text>
            </div>
            <div className={styles.btnsGroup}>
              <Btn
                className={styles.btn}
                onClick={() => {
                  handleTogglePublishAd('publish')
                }}
                type="tonal"
              >
                Опубликовать
              </Btn>
              <Btn
                className={`${styles.btn} ${styles.btnText}`}
                onClick={() => navigate(`/edit-ad/${myAdsId}`)}
                type="text"
                color="purple-main"
              >
                Редактировать
              </Btn>
            </div>
          </div>
        </>
      )
    }
  } else if (status === 'PUBLISHED') {
    // if (getClientWidth() < adaptiveWidth['950px']) {
    // } else {
    // }
    rightPannel = (
      <>
        <div className={styles.btnsGroup}>
          <Btn
            className={`${styles.btn} ${styles.btnText}`}
            type="text"
            color="purple-main"
            onClick={() => navigate(`/edit-ad/${myAdsId}`)}
          >
            Редактировать
          </Btn>
          <Btn
            className={`${styles.btn} ${styles.btnText}`}
            type="text"
            color="purple-main"
            onClick={() => {
              handleTogglePublishAd('hide')
            }}
          >
            Снять с публикации
          </Btn>
        </div>
      </>
    )
  } else if (status === 'REJECTED') {
    if (getClientWidth() < adaptiveWidth['950px']) {
    } else {
    }
    rightPannel = (
      <>
        <div className={`${styles.rightPannel_rejected} ${styles.rightPannel}`}>
          <div className={styles.rejected}>
            <img src={lockIcon} alt="" />
            <Text type="paragraph" color="white-main">
              Объявление не прошло модерацию
            </Text>
          </div>
          <Btn
            className={styles.btn}
            type="tonal"
            onClick={() => navigate(`/edit-ad/${myAdsId}`)}
          >
            Редактировать
          </Btn>
        </div>
      </>
    )
  }

  const plate = (
    <div className={`${styles.plateWrapper} ${className}`}>
      {rightPannel}
      <Btn
        onClick={openDeleteAdModal}
        className={styles.deleteBtn}
        type="text"
        color="secondary-black"
      >
        <div className={`${styles.textWrapper} ${styles.deleteText}`}>
          Удалить объявление
          <Icon type="trash" className={styles.deleteIcon} />
          {/* <img src={trashIcon} alt="" /> */}
        </div>
      </Btn>
    </div>
  )
  if (getClientWidth() < adaptiveWidth['1280px']) {
    return <Container>{plate}</Container>
  } else {
    return <>{plate}</>
  }
}

export default PlatePageStatus
