import { useFormContext } from 'react-hook-form'
import { memo, TextareaHTMLAttributes, useEffect, useState } from 'react'
import { ErrorMessage } from '@hookform/error-message'
import { registrationDataUpdate } from 'entities/Registration/model/RegistrationSlice'

import { Text } from 'shared'

import styles from './TextareaInput.module.scss'

interface TextareaValues extends TextareaHTMLAttributes<HTMLTemplateElement> {
  name: string
  label: string
  onChange?: (event) => void
  defaultValue?: string
}

const TextareaInput = memo((props: TextareaValues) => {
  const { register, formState, getFieldState, trigger, watch, setValue } =
    useFormContext()
  const { errors } = formState
  const { label, name, onChange, className, defaultValue } = props

  const [isFocused, setIsFocused] = useState(false)
  const setValidation = async (e) => await trigger(e.target.name)
  const handleFocus = () => {
    setIsFocused(true)
  }
  const handleBlur = (e) => {
    if (e.target.value === '') {
      setIsFocused(false)
    } else {
      setIsFocused(true)
    }
    setValidation(e)
  }

  const labelStyles =
    isFocused || formState.dirtyFields[name] || defaultValue
      ? {
          left: '12px',
          top: '-18px',
          fontSize: '12px',
          fontWeight: '400',
          lineHeight: '20px',
          color: getFieldState(name).invalid ? '#FF0000' : '#5D5B66',
        }
      : {
          left: '12px',
          top: '16px',
          fontSize: '16px',
          fontWeight: '400',
          lineHeight: '24px',
          color: getFieldState(name).invalid ? '#FF0000' : '#A1A6B1',
        }

  const inputStyles = {
    background: getFieldState(name).invalid ? 'rgba(255, 0, 0, 0.05)' : '',
    border: getFieldState(name).invalid
      ? '1px solid var(--ff-0000-button-red, #F00)'
      : '',
  }

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue)
    }
  }, [defaultValue, setValue])

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <label htmlFor={name} style={labelStyles} className={styles.label}>
        {label}
      </label>
      <textarea
        {...register(name, {
          required: 'Обязательное поле',
        })}
        key={name}
        id={name}
        className={styles.input}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onInput={onChange}
        style={inputStyles}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <Text type="small" className={styles.error}>
            {message}
          </Text>
        )}
      />
    </div>
  )
})
export default TextareaInput
