// Этот компонент должен быть фичей, но почему-то у меня нет прав доступа перенести его туда, лол

import styles from './CreateAdRoomParams.module.scss'
import {
  Title,
  TextInput,
  MultipleFormFilter,
  Checkbox,
  ImgInput,
  SingleCheckbox,
} from 'shared'
import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { roomSelectors } from '../../../entities/Room'
import { useParams } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'

const periodFilter = [
  { id: '2', text: '2 месяца' },
  { id: '6', text: 'Полгода' },
  { id: '12', text: 'Год' },
]

const sex = [
  { id: 'MAN', text: 'Парень' },
  { id: 'WOMAN', text: 'Девушка' },
  { id: 'NO_MATTER', text: 'Не важно' },
]

interface Props {
  files?: any
  setFiles?: any
}

export const CreateAdRoomParams: FC<Props> = (props) => {
  const { files, setFiles } = props
  const room = useSelector(roomSelectors.room)
  const roomStatus = useSelector(roomSelectors.roomStatus)
  const { id } = useParams()
  const [isSelectedDeposit, setSelectedDeposit] = useState(false)
  const [depositValue, setDepositValue] = useState('')
  const { setValue } = useFormContext()
  const onInputDeposit = (e) => {
    setDepositValue(e.target.value)
    if (e.target.value) {
      setSelectedDeposit(false)
    }
  }

  const onSetDeposit = () => {
    setSelectedDeposit((deposit) => !deposit)
    if (depositValue && !isSelectedDeposit) {
      setValue('deposit', 0)
    }
  }

  return (
    <div className={styles.room}>
      <Title type="h3">О комнате</Title>

      <div className={styles.wrapper}>
        <div className={styles.priceWrapper}>
          <div className={styles.inputWrapper}>
            <MultipleFormFilter
              label={'Минимальный срок аренды'}
              items={periodFilter}
              name={'minimum_rental_period'}
              wrapperClass={styles.multiple}
              itemClass={styles.multiple__item}
              selectedValue={room?.minimum_rental_period}
            />
          </div>

          <div className={styles.inputWrapper}>
            <TextInput
              name={'room_area'}
              label={'Площадь комнаты, м²'}
              type={'number'}
              wrapperClass={styles.squareWrapper}
              className={styles.squareInputWrapper}
              defaultValue={room?.room_area}
            />
          </div>
        </div>

        <div className={styles.price}>
          <TextInput
            name={'price'}
            label={'Цена за аренду в месяц, ₽'}
            type={'number'}
            wrapperClass={styles.priceInput}
            className={styles.priceInput}
            defaultValue={room?.price}
          />
          <div className={styles.checkboxes}>
            <Checkbox
              name="public_utilities"
              defaultChecked={room?.public_utilities}
            >
              Коммунальные включены
            </Checkbox>
          </div>
        </div>
        <div className={styles.deposit}>
            <TextInput name='deposit' label='Залог' type="number" onHandleChange={onInputDeposit} value={depositValue}/>
            <SingleCheckbox onClick={onSetDeposit} isSelected={isSelectedDeposit} label='Без залога'/>
        </div>
      </div>
      <div className={styles.imgInput}>
        {/*@ts-ignore*/}
        <ImgInput
          name={'photo'}
          files={files}
          setFiles={setFiles}
          photos={room?.photo}
        />
      </div>
    </div>
  )
}
