import { configureStore, combineReducers, createStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { neighbourFiltersSlice } from 'widgets/FindNeighbourFilters'
import { favouritesSlice } from 'features/AddAndRemoveFavourites'
import { myAdsSlice } from 'entities/MyAds'
import { neighbourSlice, myDataSlice } from 'entities/Neighbour'
import { roomSlice } from 'entities/Room'
import { roomFilterSlice } from 'widgets/FindRoomFilters'
import { RegistrationSlice } from 'widgets/RegistrationForm'
import { createAdFormSlice } from 'widgets/CreateAdForm'
import { createAdConclusionSlice } from 'entities/CreateAdConclusion'
import { createAdAdditional as createAdAdditionalSlice } from 'features/CreateAdAdditionals'
import { EditAccountSlice } from 'entities/EditAccount'
// import { headerSlice } from 'widgets/Header'
import AuthSlice from 'shared/model/AuthSlice'

import { 
  persistStore, 
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
 } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const rootReducer = combineReducers({
  AuthSlice, // запрос без токена и поправил
  neighbourSlice, // сделал
  roomSlice, // сделал
  myDataSlice, // сделал
  favouritesSlice, // сделал
  myAdsSlice, // сделал
  neighbourFiltersSlice, // сделал
  roomFilterSlice, // нету фетчей
  RegistrationSlice, // сделал
  createAdFormSlice, // сделал
  createAdConclusionSlice, // нету фетчей
  createAdAdditionalSlice, // нету фетчей
  EditAccountSlice,
})

// redux-persist
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['RegistrationSlice', 'AuthSlice', 'EditAccountSlice']
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

// глобальное хранилище, в котором хранятся необходимые данные для взаимодействия между разными компонентами
// characters, modals - reducers из разных файлов
const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(store)
export default store

type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export type RootState = ReturnType<typeof store.getState>
