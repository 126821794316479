import type { RootState } from 'app/store'
import { strictEqual } from 'assert'

export const data = (state: RootState) =>
  state.RegistrationSlice.data

// export const currentEducation = (state: RootState) => 
// 	state.RegistrationSlice.data.type_account
	
export const currentStepIndex = (state: RootState) =>
  state.RegistrationSlice.currentStepIndex

export const isFirstStep = (state: RootState) => 
	state.RegistrationSlice.isFirstStep

export const isLastStep = (state: RootState) => 
	state.RegistrationSlice.isLastStep

export const coursesList = (state: RootState) =>
	state.RegistrationSlice.coursesList

export const levelsEducationList = (state: RootState) =>
	state.RegistrationSlice.levelsEducationList

export const facultiesList = (state: RootState) => 
	state.RegistrationSlice.facultiesList

export const eduProgramsList = (state: RootState) =>
	state.RegistrationSlice.eduProgramsList
	
export const eduProgramsListStatus = (state: RootState) =>
state.RegistrationSlice.eduProgramsListStatus

export const allFaculties = (state: RootState) =>
state.RegistrationSlice.facultiesList

export const allFacultiesStatus = (state: RootState) =>
	state.RegistrationSlice.facultiesListStatus

export const levelEducation = (state: RootState) => 
	state.RegistrationSlice.data.level_education

export const faculty = (state: RootState) => 
	state.RegistrationSlice.data.faculty

export const accountType = (state: RootState) => 
state.RegistrationSlice.data.type_account

export const age = (state: RootState) => 
	state.RegistrationSlice.data.age

export const whatNeighbourList = (state: RootState) => 
	state.RegistrationSlice.whatNeighbourList

export const wishesList = (state: RootState) => 
	state.RegistrationSlice.wishesList

export const priceMax = (state: RootState) => 
	state.RegistrationSlice.data.preferences.price_max

export const priceMin = (state: RootState) => 
	state.RegistrationSlice.data.preferences.price_min

export const isPublished = (state: RootState) => 
	state.RegistrationSlice.data.is_published

export const isAgree = (state: RootState) => 
	state.RegistrationSlice.data.is_agree

export const metroList = (state: RootState) => 
	state.RegistrationSlice.metroList

export const metro = (state: RootState) => 
	state.RegistrationSlice.data.preferences.metro

export const districtsList = (state: RootState) => 
	state.RegistrationSlice.districtsList

export const district = (state: RootState) => 
	state.RegistrationSlice.data.preferences.district

export const countriesList = (state: RootState) => 
state.RegistrationSlice.countriesList

export const country = (state: RootState) => 
	state.RegistrationSlice.data.preferences.county

export const postUserDataStatus = (state: RootState) => 
	state.RegistrationSlice.postUserDataStatus

export const first_name = (state: RootState) => 
	state.RegistrationSlice.data.first_name

export const last_name = (state: RootState) => 
	state.RegistrationSlice.data.last_name

export const birthday = (state: RootState) => 
	state.RegistrationSlice.data.birthday

export const photoStatus = (state: RootState) => 
	state.RegistrationSlice.photoStatus
export const errorStatus = (state: RootState) => 
	state.RegistrationSlice.errorStatus
export const facultiesListStatus = (state: RootState) => 
	state.RegistrationSlice.facultiesListStatus
export const metroListStatus = (state: RootState) => 
	state.RegistrationSlice.metroListStatus
export const show_phone = (state: RootState) => 
	state.RegistrationSlice.data.show_phone

export const photo = (state: RootState) => 
	state.RegistrationSlice.data.photo

export const course_number = (state: RootState) => 
	state.RegistrationSlice.data.course_number
export const edu_program = (state: RootState) => 
	state.RegistrationSlice.data.edu_program
export const job = (state: RootState) => 
	state.RegistrationSlice.data.job

export const preferences = (state: RootState) => 
	state.RegistrationSlice.data.preferences

export const accountDataStatus = (state: RootState) => 
	state.RegistrationSlice.accountDataStatus
export const admission_year = (state: RootState) => 
	state.RegistrationSlice.data.admission_year
export const tagsList = (state: RootState) =>
	state.RegistrationSlice.tagsList

export const progress = (state: RootState) => 
	state.RegistrationSlice.progress