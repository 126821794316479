import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  _apiUrl,
  IAd,
  IRoom,
  IPhoto,
  TStatusType,
  IRoute,
  clearMetroName,
} from 'shared'

interface InitialState {
  status: TStatusType
  photosStatus: TStatusType
  id: string | number | null
  photosId: number[]
  routes: IRoute[]
  address_y: number
  address_x: number
  image: IPhoto | null
  address: string | null
  errorMessage: string | null
  errorStatus: number
}

const initialState: InitialState = {
  status: 'idle',
  photosStatus: 'idle',
  id: null,
  photosId: [],
  routes: [],
  address_y: null,
  address_x: null,
  image: null,
  address: null,
  errorMessage: null,
  errorStatus: null,
}

interface IInitialPostData {
  token: string
  body: IAd
}

interface IInitialPatchData {
  token: string
  body: IAd
  id: string
}

interface IPhotosBody {
  room: number
  photos: number[]
}

interface IInitialPhotosData {
  token: string
  body: IPhotosBody
}

interface IPhotoBody {
  image: FormData
  id?: number
}

interface IInitialPhotoData {
  token: string
  body?: FormData
}

interface IError {
  type: 'error'
  errorStatus: number
}

interface ISetRoute {
  route: IRoute
  address: string
}

interface ISetAddress {
  address: string
  address_y: number
  address_x: number
}

const isErrorPhoto = (
  val: IError | number | string | unknown
): val is IError => {
  return typeof val !== 'number' && typeof val !== 'string'
}

export const postCreateAdForm = createAsyncThunk(
  'ad/postCreateAdForm',
  async ({ token, body }: IInitialPostData) => {
    try {
      const response = await fetch(`${_apiUrl}/api/v1/room/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify(body),
      })
      if (response.ok) {
        const data = await response.json()
        return data.id
      } else {
        const data: IError = {
          type: 'error',
          errorStatus: response.status,
        }
        return data
      }
    } catch (e) {
      return e
    }
  }
)

export const putCreateAdForm = createAsyncThunk(
  'ad/putCreateAdForm',
  async ({ token, body, id }: IInitialPatchData) => {
    try {
      const response = await fetch(`${_apiUrl}/api/v1/room/${id}/`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify(body),
      })
      if (response.ok) {
        const data = await response.json()
        return data.id
      } else {
        const data: IError = {
          type: 'error',
          errorStatus: response.status,
        }
        return data
      }
    } catch (e) {
      return e
    }
  }
)

export const postPhotoAdForm = createAsyncThunk(
  'ad/postPhotoAdForm',
  async ({ token, body }: IInitialPhotoData) => {
    try {
      const response = await fetch(`${_apiUrl}/api/v1/room/photo/`, {
        method: 'POST',
        headers: {
          Authorization: `Token ${token}`,
        },
        body: body,
      })
      if (response.ok) {
        const data = await response.json()
        return data
      } else {
        const data: IError = {
          type: 'error',
          errorStatus: response.status,
        }
        return data
      }
    } catch (e) {
      return e
    }
  }
)

export const postPhotosAdForm = createAsyncThunk(
  'ad/postPhotosAdForm',
  async ({ token, body }: IInitialPhotosData) => {
    try {
      const response = await fetch(`${_apiUrl}/api/v1/room/photos/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify(body),
      })
      if (response.ok) {
        const data = await response.json()
        return data
      } else {
        const data: IError = {
          type: 'error',
          errorStatus: response.status,
        }
        return data
      }
    } catch (e) {
      return e
    }
  }
)

export const deleteAdPhoto = createAsyncThunk(
  'ad/deleteAdPhoto',
  async ({ id, token }: { id: number; token: string }) => {
    try {
      const response = await fetch(`${_apiUrl}/api/v1/room/photo/${id}/`, {
        method: 'delete',
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      // console.log(response.ok)
      if (response.ok || response.status === 204) {
        // возвращаем id удаленной комнаты, тк data пустой
        return id
      } else {
        const data: IError = {
          type: 'error',
          errorStatus: response.status,
        }
        return data
      }
    } catch (e) {
      throw new Error(`Произошла ошибка, попробуйте позже.`)
    }
  }
)

const createAdFormSlice = createSlice({
  name: 'createAdForm',
  initialState,
  reducers: {
    filterIds: (state, action) => {
      state.photosId.filter((item) => item !== action.payload)
    },
    resetCustomForm: (state) => {
      state = initialState
    },
    setAddress: (state, action: PayloadAction<ISetAddress>) => {
      // console.log(action.payload)
      state.address = action.payload.address
      state.address_x = action.payload.address_x
      state.address_y = action.payload.address_y
      // обнуляем массив маршрутов, если чел выбрал новый маршрут
      state.routes = []
    },
    setRouteToMetro: (state, action: PayloadAction<IRoute>) => {
      // console.log(action.payload)
      if ('type' in action.payload && action.payload.time) {
        // console.log(action.payload)
        const isSameMetro = state.routes.some(
          (route) => route.destination === action.payload.destination
        )

        if (isSameMetro) {
          state.routes = state.routes.map((route: IRoute) => {
            //находим повторяющийся маршрут
            if (route.destination === action.payload.destination) {
              // console.log('destination same')
              // если этоот маршрут короче по времени, то записываем его. Используем сейчас только ПЕШКОМ или НА ОБЩЕСТВЕННОМ
              if (action.payload.time < route.time) {
                // console.log('new time less than previous')
                // console.log({
                //   ...action.payload,
                //   destination: route.destination,
                // })
                return { ...action.payload, destination: route.destination }
              } else if (action.payload.time === route.time) {
                // если время одинаковое, то ставим тип пешком для юзеров
                return { ...action.payload, type: 'ON_FOOT' }
              } else {
                return { ...route }
              }
            } else {
              return { ...route }
            }
          })
        } else {
          state.routes.push(action.payload)
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // postData
      .addCase(postCreateAdForm.pending, (state) => {
        state.status = 'loading'
        state.id = null
        state.errorMessage = null
      })
      .addCase(
        postCreateAdForm.fulfilled,
        (state, action: PayloadAction<number | string | IError>) => {
          if (isErrorPhoto(action.payload)) {
            state.errorStatus = action.payload.errorStatus
            state.status = 'error'
          } else {
            state.status = 'success'
            state.id = action.payload
            state.errorMessage = null
          }
        }
      )
      .addCase(postCreateAdForm.rejected, (state, action) => {
        state.status = 'error'
        state.id = null
        state.errorMessage = action.error.message
      })

      // postPhotos
      .addCase(postPhotosAdForm.pending, (state) => {
        state.photosStatus = 'loading'
        state.errorMessage = null
      })
      .addCase(
        postPhotosAdForm.fulfilled,
        (state, action: PayloadAction<number | string | IError>) => {
          if (isErrorPhoto(action.payload)) {
            state.errorStatus = action.payload.errorStatus
            state.photosStatus = 'error'
          } else {
            state.photosStatus = 'success'
            state.errorMessage = null
          }
        }
      )
      .addCase(postPhotosAdForm.rejected, (state, action) => {
        state.status = 'error'
        state.errorMessage = action.error.message
      })

      // postPhoto
      .addCase(postPhotoAdForm.pending, (state) => {
        state.photosStatus = 'loading'
        state.errorMessage = null
      })
      .addCase(
        postPhotoAdForm.fulfilled,
        (state, action: PayloadAction<IError | IPhoto>) => {
          if ('image' in action.payload) {
            state.photosStatus = 'success'
            state.image = action.payload
            state.photosId.push(action.payload.id)
            state.errorMessage = null
          } else {
            state.errorStatus = action.payload.errorStatus
            state.photosStatus = 'error'
          }
        }
      )
      .addCase(postPhotoAdForm.rejected, (state, action) => {
        state.photosStatus = 'error'
        state.errorMessage = action.error.message
      })
      // deletePhoto
      .addCase(deleteAdPhoto.pending, (state) => {
        state.photosStatus = 'loading'
        state.errorMessage = null
      })
      .addCase(
        deleteAdPhoto.fulfilled,
        (state, action: PayloadAction<IError | number>) => {
          if (typeof action.payload === 'number') {
            // удалилось успешно и венулся id комнаты

            state.photosStatus = 'success'
            state.errorMessage = null
            state.photosId = state.photosId.filter(
              (imgId) => imgId !== action.payload
            )
          } else {
            state.errorStatus = action.payload.errorStatus
            state.photosStatus = 'error'
          }
        }
      )
      .addCase(deleteAdPhoto.rejected, (state, action) => {
        state.photosStatus = 'error'
        state.errorMessage = action.error.message
      })
      // putPhoto
      .addCase(putCreateAdForm.pending, (state) => {
        state.status = 'loading'
        state.errorMessage = null
      })
      .addCase(
        putCreateAdForm.fulfilled,
        (state, action: PayloadAction<IError | string | number>) => {
          if (isErrorPhoto(action.payload)) {
            state.errorStatus = action.payload.errorStatus
            state.status = 'error'
          } else {
            state.status = 'success'
            state.errorMessage = null
            state.id = action.payload
          }
        }
      )
      .addCase(putCreateAdForm.rejected, (state, action) => {
        state.status = 'error'
        state.errorMessage = action.error.message
      })
  },
})

export default createAdFormSlice.reducer
export const { filterIds, setRouteToMetro, setAddress, resetCustomForm } =
  createAdFormSlice.actions
