import { Text } from 'shared'
import { useEffect, useRef } from 'react'
import Slider from "@mui/material/Slider";

import RangeSlider from 'react-range-slider-input'
import rangeSlider from 'range-slider-input' // npm install range-slider-input
import 'react-range-slider-input/dist/style.css'
import './Range.css'
import { AnyIfEmpty } from 'react-redux'

interface Props {
  label: string | JSX.Element
  minValue: number // значение, которое стоит по дефолту у меньшего бегунка
  maxValue: number // значение, которое стоит по дефолту у бОльшего бегунка
  maxDefaultValue: number
  step?: number
  wrapperClass?: string
  inputClass?: string
  width?: string
  onChangeMin: (value: number) => void
  onChangeMax: (value: number) => void
  defaultValue?: string[]
  type: 'single' | 'double'
}

const Range = (props: Props) => {
  let {
    maxDefaultValue,
    step,
    label,
    minValue,
    maxValue,
    onChangeMin,
    onChangeMax,
    wrapperClass,
    inputClass,
    width,
    type,
  } = props
  const inputMin = useRef(null)
  const inputMax = useRef(null)
  function changeMin(event) {
    const newValue = parseFloat(event.target.value.replace(/\s/g, '')); // Преобразовать в число
    if (!isNaN(newValue)) {
      inputMin.current.style.width = (newValue.toString().length * 9 + 13) + 'px';
      onChangeMin(newValue);
    }
  }
  
  function changeMax(event) {
    const newValue = parseFloat(event.target.value.replace(/\s/g, '')); // Преобразовать в число
    if (!isNaN(newValue)) {
      // inputMax.current.style.width = (newValue.toString().length * 9 + 13) + 'px';
      onChangeMax(newValue);
    }
  }
  const onThumbDrag = (e) => {
    // inputMin.current.style.width = inputMin.current.value.length * 9 + 13 + 'px'
    // inputMax.current.style.width = inputMax.current.value.length * 9 + 13 + 'px'
    onChangeMin(e[0])
    onChangeMax(e[1])
  }


  return (
    <>
      <div>
        <Text type="small" color="secondary-black">
          {label}
        </Text>
        <div
          className={`input-wrapper ${wrapperClass}`}
          style={{ width: width }}
        >
          {type === 'double' && <div className="input">
            <Text type="paragraph" color="secondary-black" className="text">
              от
            </Text>
            <input
              type="text"
              ref={inputMin}
              onChange={changeMin}
              className={`input__item ${inputClass}`}
              value={
                String(minValue).replace(
                  /(\d)(?=(\d{3})+([^\d]|$))/g,
                  '$1 '
                ) /* minValue */
              }
              // defaultValue={defaultValue[0]}
            />
          </div>}
          {type === 'double' && <div className="divider"></div>}
          <div className="input">
            <Text type="paragraph" color="secondary-black" className="text">
              до
            </Text>
            <input
              type="text"
              ref={inputMax}
              onChange={changeMax}
              className={`input__item ${inputClass}`}
              value={
                String(maxValue).replace(
                  /(\d)(?=(\d{3})+([^\d]|$))/g,
                  '$1 '
                ) /* maxValue */
              }
              // defaultValue={defaultValue[1]}
            />
          </div>
          {type === 'single' && <div className="input__text">
            Тысяч рублей
          </div>}
        </div>
        <div className={`slider-wrapper ${wrapperClass}`}>
          <RangeSlider
            className="slider"
            min={0}
            max={maxDefaultValue}
            step={step}
            value={[minValue, maxValue]}
            thumbsDisabled={type === 'single' ? [true, false] : [false, false]}
            rangeSlideDisabled={type === 'single' ? true : false}
            onInput={onThumbDrag}
            // defaultValue={defaultValue}
          />
        </div>
      </div>
    </>
  )
}
export default Range
