import { Icon, Btn, SquarePhoto, useClientWidth, adaptiveWidth } from 'shared'
import type { INeighbour } from 'shared'

import styles from './LeftColumnNeighbour.module.scss'

interface Props {
  neighbour: INeighbour
  shareEntity: (props: { url: string }) => JSX.Element
  contactsModal: JSX.Element
  openContactsModal: () => void
  addAndRemoveFavourites: () => JSX.Element
}

const LeftColumnNeighbour = (props: Props) => {
  const {
    neighbour,
    contactsModal,
    openContactsModal,
    shareEntity,
    addAndRemoveFavourites,
  } = props
  const url = window.location.href
  const { photo } = neighbour
  const { getClientWidth } = useClientWidth()
  const img = photo.length > 0 ? photo[photo.length - 1].image : null

  if (getClientWidth() < adaptiveWidth['1040px']) {
    return (
      <>
        <div className={styles.left__column}>
          <SquarePhoto
            img={img}
            className={styles.squarePhoto}
            abbreviationOfName={
              neighbour.last_name[0] + neighbour.first_name[0]
            }
          />
        </div>
      </>
    )
  }
  return (
    <>
      <div className={styles.left__column}>
        <SquarePhoto
          img={img}
          className={styles.squarePhoto}
          abbreviationOfName={neighbour.last_name[0] + neighbour.first_name[0]}
        />
        <Btn width={'100%'} onClick={openContactsModal}>
          Показать контакты
        </Btn>
        {contactsModal}
        <div className={styles.icons}>
          {addAndRemoveFavourites()}
          <Icon url='complain' type={'complain'} variant="tonal" />
          {shareEntity({ url })}
        </div>
      </div>
    </>
  )
}

export default LeftColumnNeighbour
