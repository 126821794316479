import { memo, useState } from 'react'

import { ShareEntity } from 'features/ShareEntity'
import { Text, Title, Plate, Icon } from 'shared'
import type { INeighbour } from 'shared'

import {
  getAge,
  getEducationRow,
  getFullName,
  getGender,
  useClientWidth,
  adaptiveWidth,
} from 'shared'
import trimNeighbourDescr from '../../lib/heplers/trimNeighbourDescr'

import styles from './NeighborDescr.module.scss'

interface Props {
  neighbour: INeighbour
}

const NeighborDescr = memo((props: Props) => {
  const { neighbour } = props
  const { is_my_account } = neighbour
  const [isShowAllDescr, setShowAllDescr] = useState(false)
  const { getClientWidth } = useClientWidth()
  const isMobileVersion = getClientWidth() < adaptiveWidth['800px']
  let age = getAge(neighbour.birthday)
  const getDescr = (  
    isShowAllDescr: boolean,
    isMobileVersion: boolean
  ): JSX.Element => {
    const textType = isMobileVersion ? 'small' : 'paragraph'

    let descrSliced = ''
    if (neighbour.about !== null)
      descrSliced =
        neighbour.about.length > 64
          ? neighbour.about.slice(0, 65) + '...'
          : neighbour.about
    return isShowAllDescr ? (
      <div className={styles.descr}>
        <Text type="paragraph" color="secondary-black">
          {neighbour.about}
        </Text>
        <div
          className={styles.arrow__wrapper}
          onClick={() => {
            setShowAllDescr(false)
          }}
        >
          <Text
            type="paragraph"
            color="purple-main"
            className={styles.arrow__text}
          >
            Свернуть
          </Text>
          <Icon className={styles.arrow_up} type="arrow"></Icon>
        </div>
      </div>
    ) : (
      <>
        <div className={styles.descr}>
          <Text type="paragraph" color="secondary-black">
            {descrSliced}
          </Text>
          <div
            className={styles.arrow__wrapper}
            onClick={() => {
              setShowAllDescr(true)
            }}
            style={neighbour.about.length < 62 ? { display: 'none' } : {}}
          >
            <Text
              type="paragraph"
              color="purple-main"
              className={styles.arrow__text}
            >
              Развернуть
            </Text>
            <Icon className={styles.arrow_down} type="arrow"></Icon>
          </div>
        </div>
      </>
    )
  }

  const local = window.location
  const url = `${local.protocol}//${local.host}/neighbours/${neighbour.id}`

  if (isMobileVersion) {
    return (
      <>
        <div className={styles.wrapper}>
          <Title type={'h4'}>{getFullName(neighbour)}</Title>

          <div className={styles.info__row}>
            <Text type="small">{getGender(neighbour)}</Text>
            {age ? 
            <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
              <div className={styles.divider}></div>
              <Text type="small">{age}</Text>
            </div> : null}
            <Text type="small">{getEducationRow(neighbour)}</Text>
          </div>
          {neighbour.job ? (
            <>
              <Plate className={styles.plate}>
                <Text type="small">{neighbour.job}</Text>
              </Plate>
            </>
          ) : null}

          {neighbour.about && getDescr(isShowAllDescr, isMobileVersion)}
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className={styles.wrapper}>
          <div className={styles.firstRow}>
            <Title type={'h3'}>{getFullName(neighbour)}</Title>
            {is_my_account ? <ShareEntity position="left" url={url} /> : null}
          </div>

          <div className={styles.info__row}>
            <Text type="paragraph">{getGender(neighbour)}</Text>
            {age ? 
             <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
              <div className={styles.divider}></div>
              <Text type="paragraph">{age}</Text>
            </div> : null}
            {/* <div className={styles.divider}></div> */}
            {/* <Text type="paragraph">{getEducationRow(neighbour)}</Text> */}
          </div>

          {neighbour.job ? (
            <>
              {' '}
              <Plate className={styles.plate}>
                <Text type="paragraph">{neighbour.job}</Text>
              </Plate>
            </>
          ) : null}

          {neighbour.about && getDescr(isShowAllDescr, isMobileVersion)}
        </div>
      </>
    )
  }
})

export default NeighborDescr
