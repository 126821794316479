import { ShareEntity } from 'features/ShareEntity'
import {
  CardHousePreferences,
  NeighborDescr,
  MyAccountSettings,
} from 'entities/Neighbour'
import type { INeighbour } from 'shared'
import { routes, useClientWidth, adaptiveWidth } from 'shared'
import styles from './NeighborPageInfo.module.scss'

interface Props {
  neighbour: INeighbour
  isMyAccount?: boolean
  tooglePublishMeInList?: () => JSX.Element
}

const NeighborPageInfo = (props: Props) => {
  const { neighbour, isMyAccount = false, tooglePublishMeInList } = props
  const { getClientWidth } = useClientWidth()
  const isTabletVersion = getClientWidth() < adaptiveWidth['1040px']
  const url: string =
    window.location.protocol +
    '//' +
    window.location.host +
    routes.neighbourPage.parentPage +
    `/${neighbour.id}`
  // console.log(isMyAccount)
  return (
    <>
      <div className={styles.info__wrapper}>
        <NeighborDescr neighbour={neighbour} />
        {neighbour.is_my_account && isTabletVersion ? (
          <MyAccountSettings
            myData={neighbour}
            tooglePublishMeInList={tooglePublishMeInList}
          />
        ) : null}
        <div className={styles.card}>
          <CardHousePreferences neighbour={neighbour} />
        </div>
        {isMyAccount ? (
          <ShareEntity position="left" url={url} className={styles.share} />
        ) : null}
      </div>
    </>
  )
}

export default NeighborPageInfo
