import { FormProvider, useForm } from "react-hook-form";

interface Props {
	children: JSX.Element,
	onSubmit: (any) => void,
	id: string, 
	mode?: 'onSubmit' | 'onBlur' | 'onChange' | 'onTouched' | 'all',
	className?: string,
}
const Form = (props: Props) => {
	const {
		children,
		onSubmit,
		mode = 'onSubmit',
		id,
		className,
	} = props;

	const methods = useForm({mode: mode})
	
	const {
		handleSubmit,
	} = methods

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)} id={id} className={`${className}`}>
				{children}
			</form>
		</FormProvider>
	)
}

export default Form