import { Link } from 'react-router-dom'
import {
  Btn,
  Text,
  Avatar,
  Container,
  routes,
  useClientWidth,
  adaptiveWidth,
  Icon,
  INeighbour,
  getFullName,
  Overlay,
} from 'shared'
import styles from './Navigation.module.scss'

interface Props {
  myData: INeighbour
  img: string | null
  onCloseNavigation: () => void
  isNavigation: boolean
}

const Navigation = (props: Props) => {
  const { img, myData, isNavigation, onCloseNavigation } = props
  const { getClientWidth } = useClientWidth()
  const customImg = img ? img : null

  const content = (
    <div className={styles.navigationContent}>
      <div className={styles.userWrapper}>
        <div className={styles.userInfo}>
          <Link
            className={styles.noLink}
            to={routes.myAccount}
            onClick={onCloseNavigation}
          >
            <Avatar
              img={customImg}
              className={styles.avatar}
              abbreviationOfName={myData.last_name[0] + myData.first_name[0]}
            />
          </Link>

          <Link
            className={styles.noLink}
            to={routes.myAccount}
            onClick={onCloseNavigation}
          >
            <Text type={'paragraph'}>{getFullName(myData)}</Text>
          </Link>
        </div>
        <Link
          className={styles.noLink}
          to={routes.myAccount}
          onClick={onCloseNavigation}
        >
          <Btn className={`${styles.btn}`} color="icons-fill">
            Перейти в профиль
          </Btn>
        </Link>
      </div>
      <div className={styles.links}>
        <Link
          className={styles.noLink}
          to={routes.findNeighbourPage}
          onClick={onCloseNavigation}
        >
          <Text type={'paragraph'}>Соседи</Text>
        </Link>
        <Link
          className={styles.noLink}
          to={routes.roomPage.parentPage}
          onClick={onCloseNavigation}
        >
          <Text type={'paragraph'}>Квартиры и комнаты</Text>
        </Link>
        <Link
          className={styles.noLink}
          to={routes.myAdsPage.allAdsPage}
          onClick={onCloseNavigation}
        >
          <Text type={'paragraph'}>Мои объявления</Text>
        </Link>
      </div>
      <Link
        className={styles.noLink}
        to={routes.createAdPage}
        onClick={onCloseNavigation}
      >
        <Btn className={styles.btn}>Разместить комнату</Btn>
      </Link>
    </div>
  )

  if (getClientWidth() < adaptiveWidth['572px']) {
    return (
      <>
        {isNavigation ? (
          <Overlay
            className={`${styles.overlay}`}
            closeOverlay={onCloseNavigation}
            isOverlayOpen={isNavigation}
          >
            <Container className={styles.navigationСontainer}>
              {content}
            </Container>
          </Overlay>
        ) : null}
      </>
    )
  } else {
    return (
      <>
        {isNavigation ? (
          <Overlay
            className={`${styles.overlay}`}
            closeOverlay={onCloseNavigation}
            isOverlayOpen={isNavigation}
          >
            <div className={styles.navigationСontainer}>{content}</div>
          </Overlay>
        ) : null}
      </>
    )
  }
}

export default Navigation
