import styles from './RegistrationHeader.module.scss'

import { Logo, Text, Btn, _logInUrl, Title, Icon, useClientWidth, adaptiveWidth, SocialIcon} from 'shared'
import { useEffect, useState } from 'react'
import { ExitRegModal } from 'features/ExitRegModal'
import { useSelector } from 'react-redux'

const RegistrationHeader = () => {
	let followedLinkStatus
	useEffect(() => {
		followedLinkStatus = localStorage.getItem('followedLink')
	}, [followedLinkStatus])
	const [isExitRegModalOpen, setExitRegModalOpen] = useState(false)
	const openExitRegModalOpen = () => {
		setExitRegModalOpen(true)
	}
	const closeExitRegModalOpen = () => {
		setExitRegModalOpen(false)
	}
	const { getClientWidth } = useClientWidth()
	let title : JSX.Element

	if (getClientWidth() > adaptiveWidth['1040px']){
		title = (
			<Title type={'h4'} color='white-main'>Получай новые анкеты соседей и советы по аренде жилья</Title>
		)
	} else {
		title = (
			<p className={styles.registration_header__title} >Новые анкеты и советы по аренде</p>
		)
	}
	return (
		<header
		className={styles.registration_header__container}
		>
			<div
			className={styles.registration_header}
			>
				<div className={styles.registration_header__wrapper}>
				<Logo logoClassName={styles.logo}/>
				{/* <Btn type='outlined' height={36} width={173} onClick={openExitRegModalOpen} className={styles.btn}><Text type='small' color="purple-main">Выйти из аккаунта</Text></Btn> */}
				<Btn type='outlined' height={36} width={64} onClick={openExitRegModalOpen} className={styles.btn_slim}><Text type='small' color="purple-main">Выйти</Text></Btn>
				<ExitRegModal 
				isExitRegModalOpen={isExitRegModalOpen} 
				closeModal={closeExitRegModalOpen} 
				message='Вся заполненная информация не сохранится, придется вводить заново при входе'
				title='Выйти из аккаунта?'
				exitLink={_logInUrl}
				isClearStorage={true}
				/>
				</div>
			</div>
		</header>
	)
}
export default RegistrationHeader