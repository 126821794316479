import { RootState } from "app/store";
import { ReactElement, useEffect, useState } from "react"
import { useSelector, useDispatch } from 'react-redux';
import { registrationFormStep,
		registrationIsFirstStep,
		registrationIsLastStep, 
	} from '../../Registration/model/RegistrationSlice'

const useMultistepForm = (steps: ReactElement[], pageNames: string[], stepBars: ReactElement[]) => {
	const dispatch = useDispatch();
  	let currentStepIndex = useSelector((state: RootState) => state.RegistrationSlice.currentStepIndex);
	function next() {
		if (currentStepIndex > steps.length - 1){
			dispatch(registrationFormStep(currentStepIndex))
		} else {
			dispatch(registrationFormStep(currentStepIndex + 1))
		}
	window.scroll(0, 0);
	}
	function back() {
		if (currentStepIndex <= 0){
			dispatch(registrationFormStep(currentStepIndex))
		} else {
			dispatch(registrationFormStep(currentStepIndex - 1))
		}
		window.scroll(0, 0);
	}
	function goTo(index: number) {
		dispatch(registrationFormStep(index))
	}
	function setStep(step){
		dispatch(registrationFormStep(step))
	}
	const isFirstStep = currentStepIndex === 0
	const isLastStep = currentStepIndex === steps.length - 1
	const isBeforeLastStep = currentStepIndex === steps.length - 2
	useEffect(() => {
		dispatch(registrationIsFirstStep(currentStepIndex === 0));
		dispatch(registrationIsLastStep(currentStepIndex === steps.length - 1));
	  }, [currentStepIndex]);

	return {
		currentStepIndex,
		step: steps[currentStepIndex],
		pageName: pageNames[currentStepIndex],
		stepBar: stepBars[currentStepIndex],
		goTo,
		next,
		back,
		steps,
		isFirstStep,
		isBeforeLastStep,
		isLastStep,
		setStep,
	}
}

export default useMultistepForm