import { TMyAdStatus } from 'shared'
import { IRoom } from 'shared'

export const getMyAdStatus = (room: IRoom): TMyAdStatus => {
  if (!room.is_published && room.moderation_status === 'APPROVED') {
    return 'HIDDEN'
  } else if (room.is_published && room.moderation_status === 'APPROVED') {
    return 'PUBLISHED'
  } else if (room.moderation_status === 'ON_MODERATION') {
    return 'ON_MODERATION'
  } else if (room.moderation_status === 'REJECTED') {
    return 'REJECTED'
  }
}
