import { IRoomSearchParams } from 'shared'
import { TRoomsFilters } from '../types'

export const fromFiltersToSearhParams = (
  filters: TRoomsFilters,
  offset: string
): IRoomSearchParams => {
  let params: IRoomSearchParams = {} as IRoomSearchParams
  Object.assign(params, filters)

  let filter: keyof IRoomSearchParams
  for (filter in params) {
    if (params[filter] === null) {
      delete params[filter]
    }
    if (
      (filter === 'price_min' ||
        filter === 'price_max' ||
        filter === 'min_apartment_area' ||
        filter === 'max_apartment_area' ||
        filter === 'min_room_area' ||
        filter === 'max_room_area' ||
        filter === 'min_floor' ||
        filter === 'max_floor') &&
      params[filter] === '0'
    ) {
      delete params[filter]
    }
  }
  return params
}
