import { memo } from 'react'
import { SquarePhoto, Icon } from 'shared'
import type { IPhoto } from 'shared'

import styles from './LeftColumnMyAd.module.scss'

interface Props {
  photo: IPhoto[]
}

const LeftColumnMyAd = memo((props: Props) => {
  const { photo } = props
  const photos =
    photo.length > 0
      ? photo.map((photo) => {
          return photo.image
        })
      : null

  return (
    <>
      <div className={styles.LeftColumn}>
        <SquarePhoto img={photos} photoView className={styles.squarePhoto} />
      </div>
    </>
  )
})

export default LeftColumnMyAd
