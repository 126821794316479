import { RootState, useAppDispatch } from 'app/store'
import { memo, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  RadioInput,
  MultipleFormFilter,
  SelectInput,
  SelectFilter,
  TCourse,
  ISelectItem,
  TLevelsEducation,
  TIdLevelsEducation,
  useToken,
  TextInput,
  TextareaInput,
  YearInput,
  IMultipleItem,
  useRegistered,
} from 'shared'
import {
  fetchAllFaculties,
  setCoursesList,
  setEduProgram,
  setFaculty,
  setLevelEducation,
  fetchAllEduPrograms,
  // filterEduProgramsByLevel,
  // filterEduProgramsByFaculty,
  registrationDataUpdate,
  setEduProgramsList,
  fetchAllEduLevels,
} from 'entities/Registration/model/RegistrationSlice'

import styles from './EducationStep.module.scss'
import 'react-datepicker/dist/react-datepicker.css'
import { IEduProgram } from 'entities/EditAccount/types'
import { RegistrationSelectors } from 'entities/EditAccount'
const EducationStep = () => {
  const dispatch = useAppDispatch()
  const { getToken } = useToken()
  
  useEffect(() => {
    dispatch(fetchAllFaculties({ token: getToken() }))
  }, [])
  useEffect(() => {
    dispatch(fetchAllEduPrograms({ token: getToken() }))
  }, [])
  useEffect(() => {
    dispatch(fetchAllEduLevels({ token: getToken() }))
  }, [])

  const currentAccountType = useSelector(RegistrationSelectors.accountType)
  const coursesList = useSelector(RegistrationSelectors.coursesList)
  const educationSteps = useSelector(RegistrationSelectors.levelsEducationList)
  const allFaculties = useSelector(RegistrationSelectors.allFaculties)
  const allEduProgramsList = useSelector(RegistrationSelectors.eduProgramsList)

  const currentLevelEducation = useSelector(
    RegistrationSelectors.levelEducation
  )
  const currentFaculty = useSelector(RegistrationSelectors.faculty)

  const onSetCourse = (val: IMultipleItem<string>) => {
    // курс
    dispatch(setCoursesList(val))
  }

  const onChangeEducationStep = (stepEdu: ISelectItem<string>) => {
    // образования (бакалавриат и тд)
    dispatch(setLevelEducation(stepEdu))
  }

  const onFilterList = (
    levelEducation: ISelectItem<string>,
    faculty: ISelectItem<string>,
    array: IEduProgram<string>[]
  ) => {
    if (levelEducation !== null) {
      array = array.filter((program) => {
        return program.level_education.value === levelEducation.value
      })
    }
    if (faculty !== null) {
      array = array.filter((program) => program.faculty.value === faculty.value)
    }
    return array
  }
  
  const onChangeAllFaculty = (faculty: ISelectItem<string>) => {
    // факультет
    dispatch(setFaculty(faculty))
  }

  const onChangeAllEduProgram = (eduProgram: IEduProgram<string>) => {
    // образовательная программа
    dispatch(setEduProgram(eduProgram))
  }

  const setAdmissionYear = (date: string) => {
    // год поступления
    dispatch(
      registrationDataUpdate({ fieldName: 'admission_year', value: date })
    )
  }

  const setJob = (event) => {
    // работа
    dispatch(
      registrationDataUpdate({ fieldName: 'job', value: event.target.value })
    )
  }

  const setAccountType = (event) => {
    dispatch(
      registrationDataUpdate({
        fieldName: 'type_account',
        value: event.target.value,
      })
    )
  }
  const type_account = useSelector(RegistrationSelectors.accountType)
  const course_number = useSelector(RegistrationSelectors.course_number)
  const level_education = useSelector(RegistrationSelectors.levelEducation)
  const faculty = useSelector(RegistrationSelectors.faculty) 
  const edu_program = useSelector(RegistrationSelectors.edu_program)
  const job = useSelector(RegistrationSelectors.job)
  const admission_year = useSelector(RegistrationSelectors.admission_year)

  const errorStatus = useSelector(RegistrationSelectors.errorStatus)
  const { redirectToAuth } = useRegistered()
  if (errorStatus === 401) {
    redirectToAuth()
  }
  const checkCurrentEducation = (currentAccountType) => {
    if (currentAccountType === 'STUDENT') {
      return (
        <div className={styles.wrapper}>
          <MultipleFormFilter
            name="educationLevel"
            label="Курс"
            items={coursesList}
            wrapperClass={styles.wrapper_reg}
            itemClass={styles.wrapper_reg__item}
            type="registration"
            onClick={onSetCourse}
            selectedValue={course_number?.value}
          />
          <SelectInput
            name="educationStage"
            label="Ступень образования"
            items={educationSteps}
            onClick={onChangeEducationStep}
            defaultValue={level_education}
          />
          <SelectInput
            name="faculty"
            label="Факультет"
            items={allFaculties}
            onClick={onChangeAllFaculty}
            defaultValue={faculty}
          />
          <SelectInput
            name="eduProgram"
            label="Образовательная программа"
            items={onFilterList(
              currentLevelEducation,
              currentFaculty,
              allEduProgramsList
            )}
            onClick={onChangeAllEduProgram}
            isSearchable={true}
            defaultValue={edu_program}
          />
          <div style={{ marginTop: '10px' }}>
            <TextInput
              maxlength={86}
              required={false}
              name="job"
              label="Работа"
              onHandleChange={setJob}
              type="everything"
              defaultValue={job}
            />
          </div>
        </div>
      )
    }
    if (currentAccountType === 'EMPLOYEE') {
      return (
        <div className={styles.wrapper}>
          <TextInput
            name="employee_job"
            label="Кем вы работаете"
            onHandleChange={setJob}
            maxLength={86}
            defaultValue={job}
          />
          <YearInput
            name="admissionYear"
            label="Год начала работы"
            onClick={setAdmissionYear}
            type="year"
            defaultValue={admission_year}
          />
        </div>
      )
    }
  }

  return (
    <div className={styles.wrapper}>
      <RadioInput
        id="accountType"
        items={[
          { label: 'Студент / выпускник', value: 'STUDENT' },
          { label: 'Сотрудник', value: 'EMPLOYEE' },
        ]}
        defaultValue={type_account}
        onChange={setAccountType}
      />
      <div>{checkCurrentEducation(currentAccountType)}</div>
    </div>
  )
}

export default EducationStep
