import { forwardRef, HTMLAttributes } from "react";
import styles from './Divider.module.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
}

export const Divider = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    className,
  } = props
  return (
    <div ref={ref} className={`${styles.divider} ${className}`}/>
  );
});

Divider.displayName = 'Divider';
