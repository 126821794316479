import styles from './InputWithSearch.module.scss'
import React, {
  forwardRef,
  InputHTMLAttributes,
  PropsWithoutRef,
  useEffect,
  useState,
} from 'react'
import { ErrorMessage } from '@hookform/error-message'
import { Icon, Text } from 'shared'
import { useFormContext } from 'react-hook-form'
import search from '../../../../assets/icons/search.svg'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  wrapperClassName?: string
  onClickSearch: () => void
}

export const InputWithSearch = forwardRef<
  HTMLInputElement,
  PropsWithoutRef<Props>
>((props, ref) => {
  const {
    name,
    type,
    className,
    wrapperClassName,
    label,
    placeholder,
    onClickSearch,
    defaultValue,
  } = props
  const { register, formState, trigger, getFieldState, setValue, watch } =
    useFormContext()

  const { errors, isValid } = formState

  const regularExpr = /,\s((?:(?!,).)+),\s([^,]+)\s?$/

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue)
    }
  }, [defaultValue, setValue])

  const value = watch(name)
  const { ref: refForm, ...rest } = register(name, {
    required: {
      value: true,
      message: 'Обязательное поле',
    },
  })

  const [isFocused, setIsFocused] = useState(false)

  const setValidation = async (e) => await trigger(e.target.name)

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = (e) => {
    if (e.target.value === '') {
      setIsFocused(false)
    } else {
      setIsFocused(true)
    }
    setValidation(e)
  }

  const labelStyles =
    isFocused || formState.dirtyFields[name]
      ? {
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '20px',
          color: getFieldState(name).invalid && '#FF0000',
        }
      : {
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '20px',
          color: getFieldState(name).invalid && '#FF0000',
        }

  const inputStyles = {
    background: getFieldState(name).invalid ? 'rgba(255, 0, 0, 0.05)' : '',
    border: getFieldState(name).invalid
      ? '1px solid var(--ff-0000-button-red, #F00)'
      : '',
  }

  return (
    <>
      <div className={`${styles.wrapper} ${wrapperClassName} `}>
        <label style={labelStyles} htmlFor={name} className={styles.label}>
          {label}
        </label>

        <input
          {...rest}
          name={name}
          ref={(e) => {
            refForm(e)
            // @ts-ignore
            ref.current = e
          }}
          style={inputStyles}
          key={name}
          id={name}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={`${styles.input} ${className}`}
          autoComplete="off"
          placeholder={'Москва, Покровский бульвар, 11с1'}
          type={type}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault()

              onClickSearch()
            }
          }}
        />
        <input type="text" className={styles.hidden} />
        <input type="text" className={styles.hidden} />

        <Icon
          type="search"
          className={styles.search}
          onClick={onClickSearch}
        ></Icon>

        {/* <img
          className={styles.search}
          src={search}
          alt="search_icon"
          onClick={onClickSearch}
        /> */}

        {errors ? (
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <Text type="small" className={styles.error}>
                {message}
              </Text>
            )}
          />
        ) : null}
      </div>
    </>
  )
})
