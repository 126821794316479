import { ISelectItem, Text } from 'shared'
import { memo } from 'react'
import './Checkboxes.css'
export interface ICheckboxesItem<T> {
  text: string
  isSelected: boolean
  id: T // параметр, который заносим в один из filtersSlice и потом в параметры адресной строки
}
interface Props<T> {
  label?: string
  className?: string
  items: ICheckboxesItem<T>[]
  onClick?: (id) => void
  wrapperClass?: string
  itemClass?: string
  value?: null
}

const Checkboxes = <P extends unknown>(props: Props<P>) => {
  let { label, onClick, items, wrapperClass } = props
  const checkboxes = items.map((item, ind) => {
    return (
      <div
        key={ind}
        className={`${item.isSelected ? 'checkbox_selected' : 'checkbox'}`}
        onClick={() => onClick(item.id)}
      >
        <Text
          type="small"
          color={`${item.isSelected ? 'white-main' : 'black-main'}`}
        >
          {item.text}
        </Text>
      </div>
    )
  })
  return (
    <>
      <div className="element">
        <Text type="small" color="secondary-black">
          {label}
        </Text>
        <div className={`checkbox__wrapper ${wrapperClass}`}>{checkboxes}</div>
      </div>
    </>
  )
}

export default Checkboxes
