import { EditAccountSelectors, useMultistepForm } from 'entities/EditAccount'
import {
  AboutMeStep,
  EducationStep,
  ProfileCard,
  RegistrationSelectors,
  PreferencesStep,
  CompletionStep,
  AboutMeBar,
  EducationBar,
  PreferencesBar,
  EndRegistrationCard,
} from 'entities/EditAccount'
import {
  Container,
  Btn,
  Title,
  Text,
  AvatarInput,
  useToken,
  useRegistered,
  AuthSelectors,
  routes,
} from 'shared'

import {
  useForm,
  FormProvider,
  useFormContext,
  SubmitHandler,
  SubmitErrorHandler,
} from 'react-hook-form'
import { FormEvent, memo, useEffect, useState } from 'react'

import styles from './RegistrationForm.module.scss'
import { ContactsModal } from 'features/ContactsModal'
import { ExitRegModal } from 'features/ExitRegModal'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'app/store'
import { fetchAccountData, fetchAllEduLevels, putCreateUser, setPostUserDataStatus } from 'entities/Registration/model/RegistrationSlice'
import { fetchData } from 'entities/EditAccount/model/EditAccountSlice'
import { useNavigate } from 'react-router-dom'
import { setWasRegistered } from 'shared/model/AuthSlice'


const RegistrationForm = memo(() => {
  useEffect(() => {
    dispatch(fetchAllEduLevels({token: getToken()}))
    dispatch(fetchAccountData({token: getToken()}))
  }, [])


  let steps = [
    <AboutMeStep/>,
    <EducationStep/>,
    <PreferencesStep/>,
   ]
  const {
    step,
    pageName,
    isFirstStep,
    isBeforeLastStep,
    isLastStep,
    back,
    next,
    stepBar,
    currentStepIndex,
    setStep,
  } = useMultistepForm(
    steps,
    ['О себе', 'Образование', 'Предпочтения по квартире'],
    [<AboutMeBar />, <EducationBar />, <PreferencesBar />]
  )
  const data = useSelector(RegistrationSelectors.data)
  const errorStatus = useSelector(RegistrationSelectors.errorStatus)
  const { redirectToAuth } = useRegistered()
  const {
    first_name,
    last_name,
    birthday,
    type_account,
    show_phone,
    is_published,
    gender,
    about_me,
    tg_username,
    phone,
    preferences,
    job,
    course_number,
    admission_year,
    edu_program,
  } = data
  const methods = useForm({ mode: 'all' })
  const { formState, trigger } = methods
  const { isValid } = formState
  const dispatch = useAppDispatch()
  const { getToken } = useToken()
  let sendingData = null
  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (isValid && !isLastStep) next()
  }
  const navigate = useNavigate()

  // Создание валидной даты
  let newBirthday = new Date(birthday)
  let resultBirthday = `${newBirthday.getFullYear()}-${(newBirthday.getMonth()+1).toString().replace( /^([0-9])$/, '0$1' )}-${newBirthday.getDate().toString().replace( /^([0-9])$/, '0$1' )}`

  const onSetUser = async () => {
    trigger()
    if (isLastStep && isValid) {
      // нужно сделать sendingData типом INeighbourEdit, а также добавить сюда поле program_education
      sendingData = {
        birthday: resultBirthday,
        type_account,
        is_published,
        gender,
        about: about_me,
        tg_username,
        phone,
        show_phone,
        // ...preferences,
        what_neighbor: preferences.what_neighbor,
        against_alcohol: preferences.against_alcohol,
        against_smoking: preferences.against_smoking,
        price_max: preferences.price_max,
        price_min: preferences.price_min,
        job,
        county: preferences.county.map((county) => county.value),
        district: preferences.district.map((district) => district.value),
        metro: preferences.metro.map((metro) => metro.value),
      }
      if (type_account === 'STUDENT') {
        sendingData.course_number = +course_number.value
        // устанавливаем edu_program.value тк это является id образовательной программы
        sendingData.program_education = edu_program ? +edu_program.value : ''
      }
      if (type_account === 'EMPLOYEE') {
        sendingData.year_start_job = admission_year.match(/\d?\d\d\d/)[0]
      }
      await dispatch(putCreateUser({ token: getToken(), body: sendingData }))
    }
  }

  const postUserDataStatus = useSelector(
    RegistrationSelectors.postUserDataStatus
  )
  if (errorStatus === 401) {
    redirectToAuth()
  }
  if (postUserDataStatus === 'success') {
    dispatch(setWasRegistered(true))
    dispatch(setPostUserDataStatus('idle'))
    navigate('/')
    setStep(0)
  }

  const [isExitRegModalOpen, setExitRegModalOpen] = useState(false)

  const openExitRegModalOpen = () => {
    setExitRegModalOpen(true)
  }
  const closeExitRegModalOpen = () => {
    setExitRegModalOpen(false)
  }

  const onUpdateUser = () => {
    onSetUser()
    setStep(0)
  }
    return (
      <>
        <div className={styles.header}>
          {!isFirstStep && (
            <button type="button" onClick={back} className={styles.backButton}>
              Назад
            </button>
          )}
          {isFirstStep && (
            <Btn
              type="outlined"
              height={36}
              width={108}
              className={styles.btnToMainPage}
              onClick={openExitRegModalOpen}
            >
              <Text type="small" color="purple-main">
                На главную
              </Text>
            </Btn>
          )}
          <ExitRegModal
            isExitRegModalOpen={isExitRegModalOpen}
            closeModal={closeExitRegModalOpen}
            title='Выйти на главную?'
            message='Изменённые данные не сохранятся, придется редактировать их заново'
            exitLink={routes.findNeighbourPage}
            isClearStorage={false}
          />
          <div className={styles.header__title}>  
            <Title type="h3">Редактирование профиля</Title>
            <div className={styles.header__wrapper}>
              <p className={styles.slash}>/</p>
              <Text
                type="paragraph"
                weight="bold"
                className={styles.header__subtitle}
              >
                {pageName}
              </Text>
            </div>
          </div>
          <div className={styles.progressBar}>
            <div
              className={styles.progressLine}
              style={{ width: `${10 + currentStepIndex * 40}%` }}
            ></div>
          </div>
        </div>

        <div className={styles.wrapper}>
          <div className={styles.stepsWrapper}>
            <FormProvider {...methods}>
              <form id="registrationForm" noValidate onSubmit={onSubmit}>
                {step}
              </form>
            </FormProvider>
          </div>
          <div>
            <ProfileCard
            />
            {stepBar}
          </div>
        </div>

        {currentStepIndex === 2 ? null : (
          <button
            form="registrationForm"
            className={currentStepIndex === 2 ? styles.submitButton : styles.nextButton}
            type="submit"
            onClick={() => {
              trigger()
            }}
          >
            <Text type="paragraph" color="white-main">
              {'Продолжить'}
            </Text>
          </button>
        )}
        {isLastStep ? (
          <button
            form="registrationForm"
            className={styles.submitButton}
            onClick={onUpdateUser}
            type="submit"
          >
            <Text type="paragraph" color="white-main">
              Сохранить и завершить
            </Text>
          </button>
        ) : null}
      </>
    )
  })

export default RegistrationForm
