import { memo } from 'react'

import { getRoomFirstRow } from 'entities/Room/lib/getRoomFirstRow'
import { getApartmentRow } from 'entities/Room/lib/getApartmentRow'
import {
  getDepositPrice,
  getMonthPrice,
  isDeposit,
  isPublicUtilities,
} from 'entities/Room/lib/getPriceRow'
import {
  Title,
  Text,
  UtilitiesPlate,
  Icon,
  Plate,
  IRoom,
  routes as routesService,
  useClientWidth,
  adaptiveWidth,
  Container,
} from 'shared'

import styles from './RoomCardDescr.module.scss'
import ruble_symbol from 'assets/icons/ruble_symbol.svg'

interface Props {
  room: IRoom
  isMyAd?: boolean
  shareEntity?: ({
    url,
    className,
  }: {
    url: string
    className: string
  }) => JSX.Element
}

const RoomCardDescr = memo((props: Props) => {
  const { room, isMyAd = false, shareEntity } = props
  const { routes } = room
  const { getClientWidth } = useClientWidth()
  const isMobileVersion = getClientWidth() < adaptiveWidth['800px']
  const isTabletVersion = getClientWidth() < adaptiveWidth['1040px']

  // http://localhost:3000/rooms/1
  const urlForMyAd = `${window.location.protocol}//${window.location.host}${routesService.roomPage.parentPage}/${room.id}`

  const renderPublicUtilities = (
    isPublicUtilities: boolean,
    isMobileVersion: boolean
  ) => {
    const typeText = isMobileVersion ? 'small' : 'paragraph'
    if (isPublicUtilities) {
      return (
        <div className={''}>
          <UtilitiesPlate className={''}>
            <Icon type="tag" className={styles.info__icon} />
            <Text type={typeText} weight="regular" color="white-main">
              С учетом коммуналки
            </Text>
          </UtilitiesPlate>
        </div>
      )
    } else {
      return <></>
    }
  }

  // const metroPlate = routes[0].destination
  //   ? routes.map((route) => {
  //       return (
  //         <>
  //           <Plate className={styles.plate}>
  //             <div className={styles.plate__metro}>
  //               <div className={styles.plate__metro__name}>
  //                 <Icon type="pixel" className={styles.plate__metro__icon} />
  //                 <Text type="paragraph">{route.destination}</Text>
  //               </div>
  //             </div>
  //           </Plate>
  //         </>
  //       )
  //     })
  //   : null

  const metroPlate = routes[0]?.destination ? (
    <>
      {' '}
      <Plate className={styles.plate}>
        {routes.map((route, ind) => {
          if (route.destination) {
            return (
              <div key={ind} className={styles.plate__metro}>
                <div className={styles.plate__metro__name}>
                  <Icon type="pixel" className={styles.plate__metro__icon} />
                  <Text type="paragraph">{route.destination}</Text>
                </div>
                <div className={styles.divider}></div>
                <div className={styles.plate__metro__time}>
                  <Icon
                    type={route.type === 'IN_PUBLIC' ? 'car' : 'walker'}
                    size="large"
                    className={
                      route.type === 'IN_PUBLIC'
                        ? styles.plate__transport
                        : styles.plate__walker
                    }
                  />
                  <Text type="paragraph">{route.time} мин</Text>
                </div>
              </div>
            )
          }
        })}
      </Plate>
    </>
  ) : null

  if (isMobileVersion) {
    return (
      <>
        <div className={styles.wrapper}>
          <Container>
            <div className={styles.firstRow}>
              <Title type={'h3'} className={styles.headerMobile}>
                {getRoomFirstRow(room)}
              </Title>
              {/* {isMyAd ? (
                <>
                  {shareEntity({
                    url: urlForMyAd,
                    className: '',
                  })}
                </>
              ) : null} */}
            </div>

            <Text type="small" color="secondary-black">
              {getApartmentRow(room)}
            </Text>
            {metroPlate}

            <div className={styles.info__wrapper}>
              <div className={styles.info__row}>
                <Title type={'h4'} className={styles.headerMobile}>
                  {getMonthPrice(room)} <img src={ruble_symbol} alt="" /> в
                  месяц
                </Title>
              </div>
              {isDeposit(room) ? (
                <>
                  <Text
                    type={'paragraph'}
                    weight="bold"
                    color="secondary-black"
                  >
                    {getDepositPrice(room)} залог
                  </Text>
                </>
              ) : null}
              {renderPublicUtilities(room.public_utilities, isMobileVersion)}
            </div>
          </Container>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className={styles.wrapper}>
          <div className={styles.firstRow}>
            <Title type={'h3'}>{getRoomFirstRow(room)}</Title>
            {isMyAd && !isTabletVersion ? (
              <>
                {shareEntity({
                  url: urlForMyAd,
                  className: '',
                })}
              </>
            ) : null}
          </div>

          <Text type="paragraph" color="secondary-black">
            {getApartmentRow(room)}
          </Text>
          {metroPlate}

          <div className={styles.info__wrapper}>
            <div className={styles.info__row}>
              <Title type={'h4'}>
                {getMonthPrice(room)} <img src={ruble_symbol} alt="" /> в месяц
              </Title>
              {isDeposit(room) ? (
                <>
                  <div className={styles.divider}></div>
                  <Title type={'h4'}>{getDepositPrice(room)} залог</Title>
                </>
              ) : null}
            </div>
            {renderPublicUtilities(room.public_utilities, isMobileVersion)}
          </div>
        </div>
      </>
    )
  }
})

export default RoomCardDescr
