import {
  INeighbourSearchParams,
  TBooleanToSrting,
  TGender,
  TIdLevelsEducation,
} from 'shared'
import { TLevelsEducation, TAlcohol, TSmoking, TCourse } from 'shared'

// ВАЖНО!!!
// При изменении интерфейса INeighbourSearchParams ОБЯЗАТЕЛЬНО нужно прописать ветку if для добавления/удаления параметра.
// Так как наличие параметров проверяется с помощью фун-ции has(), то нельзя ошибаться в написании параметров в has()

// Функция, которая вытаскивает все параметры из адресной строки и формирует из этого объект
export const getSearchNeigboursParams = (
  searchParams: URLSearchParams
): INeighbourSearchParams => {
  let params: INeighbourSearchParams = { offset: '0' }
  if (searchParams.has('offset')) {
    params.offset = searchParams.get('offset')
  }
  if (searchParams.has('price_max')) {
    params.price_max = searchParams.get('price_max')
  }
  if (searchParams.has('price_min')) {
    params.price_min = searchParams.get('price_min')
  }
  if (searchParams.has('age_min')) {
    params.age_min = searchParams.get('age_min')
  }
  if (searchParams.has('age_max')) {
    params.age_max = searchParams.get('age_max')
  }
  if (searchParams.has('gender')) {
    params.gender = searchParams.get('gender') as TGender
  }
  if (searchParams.has('course_number')) {
    params.course_number = searchParams.get('course_number') as TCourse
  }
  if (searchParams.has('level_education')) {
    params.level_education = searchParams.get(
      'level_education'
    ) as TIdLevelsEducation
  }
  if (searchParams.has('faculty')) {
    params.faculty = searchParams.get('faculty')
  }
  if (searchParams.has('against_alcohol')) {
    params.against_alcohol = searchParams.get(
      'against_alcohol'
    ) as TBooleanToSrting
  }
  if (searchParams.has('against_smoking')) {
    params.against_smoking = searchParams.get(
      'against_smoking'
    ) as TBooleanToSrting
  }
  return params
}
