import { memo, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'app/store'

import { Text, SquarePhoto, Btn, Title, Plate, Icon } from 'shared'

import styles from './ProfileCard.module.scss'
import { RegistrationSelectors } from 'entities/EditAccount'
import ruble from '../../../../assets/icons/ruble_symbol.svg'
import MetroIcon from 'shared/ui/Icon/MetroIcon'

interface Props {
  type?: 'registration' | 'edit-account',
}

const ProfileCard = memo((props: Props) => {
  const {
    type = 'edit-account'
  } = props
  const isReg = type === 'registration'
  const [isShowAllDescr, setShowAllDescr] = useState(false)
  const currentData = useSelector(RegistrationSelectors.data)
  const {
    first_name,
    last_name,
    gender,
    age,
    preferences,
    photo,
  } = currentData // так же телефон и номер телефона в соответствующих переменных

  function formatYears(number) {
    if (number === 1) {
      return number + ' год'
    } else if (number % 10 === 1 && number % 100 !== 11) {
      return number + ' год'
    } else if (number % 10 >= 2 && number % 10 <= 4) {
      return number + ' года'
    } else {
      return number + ' лет'
    }
  }
  let metroItems = null
  if (preferences.metro.length !== 0) {
    metroItems = preferences.metro.map((station) => {
      if (station) {
        return (
          <div className={styles.metroWrapper} key={station.value}>
            <MetroIcon metroLines={station?.metro_line} type="small" />
            <Text type="tiny"> {station?.label}</Text>
          </div>
        )
      }
    })
  }
  const checkHabits = (preferences) => {
    if (preferences.against_alcohol && !preferences.against_smoking) {
      return (
        <div className={styles.checkHabits}>
          {' '}
          <Icon type="no-alcohol" size="tiny" />{' '}
          <Text type="tiny">Против алкоголя</Text>
        </div>
      )
    }
    if (!preferences.against_alcohol && preferences.against_smoking) {
      return (
        <div className={styles.checkHabits}>
          {' '}
          <Icon type="no-smoking" size="tiny" />{' '}
          <Text type="tiny">Против курения</Text>
        </div>
      )
    }
    if (preferences.against_alcohol && preferences.against_smoking) {
      return (
        <div className={styles.checkHabits}>
          {' '}
          <Icon type="no-smoking" size="tiny" />
          <Icon
            className={styles.icon_habits}
            type="no-alcohol"
            size="tiny"
          />{' '}
          <div style={{ transform: 'translateX(-10px)' }}>
            <Text type="tiny">Против курения и алкоголя</Text>
          </div>
        </div>
      )
    }
    return null
  }
  const checkWhatNeighbour = (preferences) => {
    if (
      preferences.what_neighbor &&
      preferences.what_neighbor !== 'NO_MATTER'
    ) {
      if (preferences.what_neighbor == 'MAN')
        return (
          <>
            <Icon type="pixel" size="tiny" />
            <Text type="tiny">Хочет жить с соседом парнем</Text>
          </>
        )
      if (preferences.what_neighbor == 'WOMAN')
        return (
          <>
            <Icon type="pixel" size="tiny" />
            <Text type="tiny">Хочет жить с соседкой девушкой</Text>
          </>
        )
    }
    return null
  }

  return (
    <div className={styles.component}>
      <Text type="small" color="secondary-black" className={styles.comment}>
        Так будет выглядеть твоя будущая страница
      </Text>
      <div className={styles.card}>
        <div className={styles.card__left}>
          {photo?.image ? (
            <div className={styles.imageWrap}>
              <img
                src={photo?.image}
                alt={photo?.id.toString()}
                className={styles.image}
              />
            </div>
          ) : (
            <div className={styles.card__photo}></div>
          )}
        </div>
        <div>
          {first_name || last_name ? (
            <Text
              type="paragraph"
              weight="bold"
            >{`${first_name} ${last_name}`}</Text>
          ) : (
            <div className={styles.name}></div>
          )}
          <div className={styles.info__row}>
            {gender ? (
              <Text type="tiny">{gender === 'MAN' ? 'Парень' : 'Девушка'}</Text>
            ) : (
              <div className={styles.row_descr}></div>
            )}
            <div style={{ minWidth: '27px' }}>
              {age ? (
                <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                <div className={styles.divider}></div>
                <Text type="tiny">{formatYears(age)}</Text>
                </div>
              ) : null}
            </div>
          </div>
          {preferences ? (
            <div className={styles.preferences_active}>
              <Text type="tiny" color="secondary-black">
                Предпочтения по жилью
              </Text>

              {preferences.price_max !== '0' ? (
                <Text type="paragraph" weight="bold">
                 до{' '}
                  {String(preferences.price_max).replace(
                    /(\d)(?=(\d{3})+([^\d]|$))/g,
                    '$1 '
                  )}{' '}
                  <img src={ruble} style={{ height: '12px' }} /> в месяц
                </Text>
              ) : (
                <div className={styles.preferences__price}></div>
              )}

              <div
                style={{
                  display: 'flex',
                  gap: '7px',
                  flexWrap: 'wrap',
                  marginTop: '4px',
                }}
              >
                {metroItems ? (
                  metroItems
                ) : (
                  <div className={styles.preferences__underground}></div>
                )}
              </div>

              <div className={styles.preferences__devider}></div>
              {checkHabits(preferences) || checkWhatNeighbour(preferences) ? (
                <div
                  className={`${styles.preferences__wrapper} ${styles.preferences__wrapper_active}`}
                >
                  {checkHabits(preferences)}
                  {checkWhatNeighbour(preferences)}
                </div>
              ) : null}
            </div>
          ) : (
            <div className={styles.preferences}>
              {/* <Text type="tiny" color='secondary-black'>Предпочтения по жилью</Text> */}
              <div className={styles.preferences__price}></div>
              <div className={styles.preferences__underground}></div>
              <div className={styles.preferences__devider}></div>
              <div className={styles.preferences__wrapper}>
                <div className={styles.preferences__habits}></div>
                <div className={styles.preferences__text}></div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
})

export default ProfileCard
