import { Icon, useClientWidth, adaptiveWidth } from 'shared'
import ReactPaginate from 'react-paginate'

import styles from './Pagination.module.scss'

interface Props {
  initialPage?: number
  pageCount: number
  onPageChange: (event: { selected: number }) => void
}

const Pagination = (props: Props) => {
  const { pageCount, onPageChange, initialPage } = props
  const { getClientWidth } = useClientWidth()
  const isMobile = getClientWidth() < adaptiveWidth['572px']
  return (
    <>
      <ReactPaginate
        initialPage={initialPage}
        className={styles.paginagiotContainer}
        pageLinkClassName={styles.liItem}
        activeLinkClassName={styles.liItem_active}
        previousLinkClassName={`${styles.arrow} ${styles.prevArrow}`}
        nextLinkClassName={`${styles.arrow} ${styles.nextArrow}`}
        breakLabel="..."
        previousLabel={<Icon type="arrow" />}
        nextLabel={<Icon type="arrow" />}
        onPageChange={onPageChange}
        pageRangeDisplayed={1}
        pageCount={pageCount}
        renderOnZeroPageCount={null}
        marginPagesDisplayed={isMobile ? 1 : 2}
      />
    </>
  )
}

export default Pagination
