import { memo } from 'react'
import { Link } from 'react-router-dom'
import nextId from 'react-id-generator'
import {
  Btn,
  SquarePhoto,
  Text,
  Icon,
  Plate,
  Title,
  IconProps,
  adaptiveWidth,
} from 'shared'
import { INeighbour } from 'shared'

import { routes } from 'shared'
import {
  getAge,
  getJobOrEducation,
  getFullName,
  getGender,
  getLabelBadHabits,
  getWhatNeighbour,
  useClientWidth,
} from 'shared'

import trimNeighbourDescr from '../../lib/heplers/trimNeighbourDescr'
import getMonthPrice from '../../lib/heplers/getMonthPrice'

import styles from './NeighbourCard.module.scss'

import ruble_symbol from 'assets/icons/ruble_symbol.svg'
import MetroIcon from 'shared/ui/Icon/MetroIcon'

interface IFavouritiesProps {
  entity: 'neighbours'
  isFavorite: boolean
  id: number
  className?: string
}

interface Props {
  neighbour: INeighbour
  addAndRemoveFavourites: (props: IFavouritiesProps) => JSX.Element
  openContactsModal: () => void
}

const NeighbourCard = memo((props: Props) => {
  const { neighbour, openContactsModal, addAndRemoveFavourites } = props
  const { photo } = neighbour
  const { getClientWidth } = useClientWidth()
  let age = getAge(neighbour.birthday)

  const labelBadHabits = getLabelBadHabits(neighbour)
  const desiredNeighbour = getWhatNeighbour(neighbour)
  const lastRow = (
    <div className={styles.last__row}>
      {!labelBadHabits ? null : (
        <div className={styles.smoke__alchocol}>
          <div className={styles.last__row__icons}>
            {neighbour.against_smoking ? <Icon type="no-smoking" /> : null}
            {neighbour.against_alcohol ? <Icon type="no-alcohol" /> : null}
          </div>

          <Text type="small" className={styles.text}>
            {labelBadHabits}
          </Text>
        </div>
      )}

      {!desiredNeighbour ? null : (
        <div className={styles.preferences__neighbours}>
          <Icon type="pixel" />
          <Text type="small" className={styles.text_preferences}>
            {desiredNeighbour}
          </Text>
        </div>
      )}
    </div>
  )

  const img = photo.length > 0 ? photo[photo.length - 1].image : null

  if (getClientWidth() < adaptiveWidth['800px']) {
    return (
      <AdaptiveNeighbourCard
        neighbour={neighbour}
        addAndRemoveFavourites={addAndRemoveFavourites}
        openContactsModal={openContactsModal}
        lastRow={lastRow}
      />
    )
  }

  const metroItems = neighbour.metro.map((station, ind) => {
    if (station) {
      return (
        <div className={styles.geo__object} key={ind + 100}>
          <MetroIcon metroLines={station?.metro_line} key={ind} />
          <Text type="small" key={ind + 200}>
            {station.name}
          </Text>
        </div>
      )
    }
  })

  return (
    <>
      <div className={styles.neighbour}>
        <div className={styles.left__column}>
          <Link
            preventScrollReset={true}
            className={styles.none__underline}
            to={`${routes.neighbourPage.parentPage}/${neighbour.id}`}
          >
            <SquarePhoto
              size={240}
              img={img}
              className={styles.squarePhoto}
              abbreviationOfName={
                neighbour.last_name[0] + neighbour.first_name[0]
              }
            />
          </Link>
          <Btn onClick={openContactsModal} width={'100%'}>
            <Text type="paragraph" weight="bold" color="white-main">
              Показать контакты
            </Text>
          </Btn>
        </div>
        <div className={styles.neighbour__info}>
          <div className={''}>
            <div className={styles.fioAndLikeRow}>
              <Link
                preventScrollReset={true}
                className={styles.none__underline}
                to={`${routes.neighbourPage.parentPage}/${neighbour.id}`}
              >
                <Title type={'h4'}>{getFullName(neighbour)}</Title>
              </Link>
              {addAndRemoveFavourites({
                entity: 'neighbours',
                isFavorite: neighbour.is_favorite,
                id: neighbour.id,
              })}
            </div>
            <Link
              preventScrollReset={true}
              className={styles.none__underline}
              to={`${routes.neighbourPage.parentPage}/${neighbour.id}`}
            >
              <div className={styles.info__row}>
                <Text type="paragraph">{getGender(neighbour)}</Text>
               {age ? 
               <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                <div className={styles.divider}></div>
                <Text type="paragraph">{age}</Text>
                </div> : null}
              </div>

              {neighbour?.about ? <Text
                className={styles.descr}
                type="paragraph"
                color="secondary-black"
              >
                {trimNeighbourDescr(neighbour, 'card')}
              </Text> : null}
            </Link>
          </div>

          <div>
            <Link
              className={styles.none__underline}
              preventScrollReset={true}
              to={`${routes.neighbourPage.parentPage}/${neighbour.id}`}
            >
              <div className={styles.card}>
                <Text type="small" color="secondary-black">
                  Предпочтения по жилью
                </Text>
                <Title className={styles.card__header} type="h4">
                  {getMonthPrice(neighbour)} <img src={ruble_symbol} alt="" /> в
                  месяц
                </Title>

                <div className={styles.geo__objects}>{metroItems}</div>

                {lastRow}
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
})

interface AdaptiveProps {
  neighbour: INeighbour
  addAndRemoveFavourites: (props: IFavouritiesProps) => JSX.Element
  openContactsModal: () => void
  lastRow: JSX.Element
}

const AdaptiveNeighbourCard = (props: AdaptiveProps) => {
  const { neighbour, openContactsModal, addAndRemoveFavourites, lastRow } =
    props
  const { photo } = neighbour
  const img = photo.length > 0 ? photo[0].image : null
  let age = getAge(neighbour.birthday)

  const icon = (props: IconProps) => {
    return addAndRemoveFavourites({
      entity: 'neighbours',
      isFavorite: neighbour.is_favorite,
      id: neighbour.id,
      className: props.iconClassName,
    })
  }
  const metroItems = neighbour.metro.map((station, ind) => {
    return (
      <div className={styles.geo__object} key={ind + 1000}>
        <MetroIcon metroLines={station?.metro_line} key={ind + 2000} />
        <Text type="small" key={ind + 3000}>
          {' '}
          {station.name}
        </Text>
      </div>
    )
  })
  return (
    <>
      <>
        <div className={styles.neighbour}>
          {/* <Link
            preventScrollReset={true}
            className={styles.none__underline}
            to={`${routes.neighbourPage.parentPage}/${neighbour.id}`}
          > */}
          <SquarePhoto
            size={240}
            img={img}
            className={styles.squarePhoto}
            icon={icon}
            locationIcon="right-top"
            abbreviationOfName={
              neighbour.last_name[0] + neighbour.first_name[0]
            }
          />
          {/* </Link> */}

          <div className={styles.neighbour__info}>
            <Link
              preventScrollReset={true}
              className={styles.none__underline}
              to={`${routes.neighbourPage.parentPage}/${neighbour.id}`}
            >
              <div className={styles.fioEducationAndDescr}>
                <div className={styles.fioAndLikeRow}>
                  <Title type={'h4'}>{getFullName(neighbour)}</Title>
                </div>

                <div className="">
                  <div className={styles.info__row}>
                    <Text type="small">{getGender(neighbour)}</Text>
                   {age ? 
                   <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                      <div className={styles.divider}></div>
                      <Text type="small">{age}</Text>
                    </div>: null}
                  </div>
                </div>
              </div>
            </Link>
           {neighbour.about ?  <Text className={styles.descr} type="small" color="secondary-black">
              {trimNeighbourDescr(neighbour, 'card')}
            </Text> : null}

            <div>
              <Link
                className={styles.none__underline}
                preventScrollReset={true}
                to={`${routes.neighbourPage.parentPage}/${neighbour.id}`}
              >
                <div className={styles.card}>
                  <Text type="mobile-small" color="secondary-black">
                    Предпочтения по жилью
                  </Text>
                  <Title
                    className={`${styles.card__header} ${styles.price}`}
                    type="h4"
                  >
                    {getMonthPrice(neighbour)} <img src={ruble_symbol} alt="" />{' '}
                    в месяц
                  </Title>
                  <div className={styles.geo__objects}>{metroItems}</div>
                  {lastRow}
                </div>
              </Link>
            </div>
            <Btn onClick={openContactsModal} width={'100%'}>
              <Text type="paragraph" weight="bold" color="white-main">
                Показать контакты
              </Text>
            </Btn>
          </div>
        </div>
      </>
    </>
  )
}

export default NeighbourCard
