import { useState, useEffect, SetStateAction } from 'react'
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'app/store'

import { TNeighbourFilters, neighbourFiltersSelectors } from '..'
import {
  resetAllFilters,
  setAllFilters,
  setPriceMax,
  setPriceMin,
  setAgeMin,
  setAgeMax,
  setLevelEducation,
  setFaculty,
  setAgainstAlcohol,
  setAgainstSmoking,
  fetchAllFaculties,
  setGenderList,
  setCoursesList,
} from '..'
import { SearchAndResetFilters } from 'features/SearchAndResetFilters'
import {
  getSearchNeigboursParams,
  fetchAllNeighbours,
  neighbourSelector,
} from 'entities/Neighbour'
import {
  Range,
  MultipleFilter,
  SelectFilter,
  ISelectItem,
  useToken,
  TLevelsEducation,
  TGender,
  TCourse,
  TIdLevelsEducation,
  useClientWidth,
  adaptiveWidth,
  MobileStickyBlock,
  Btn,
  MobileDialog,
  useRegistered,
} from 'shared'
import { fromFiltersToSearhParams } from '../lib/fromFiltersToSearhParams'
import ruble from 'assets/icons/ruble_secondary.svg'

import styles from './FindNeighbourFilters.module.scss'

const FindNeighbourFilters = () => {
  const dispatch = useAppDispatch()
  let [searchParams, setSearchParams] = useSearchParams()
  const { getToken } = useToken()
  const allFacultiesStatus = useSelector(
    neighbourFiltersSelectors.allFacultiesStatus
  )
  const { getClientWidth } = useClientWidth()
  const [isMobileFilters, setMobileFilters] = useState(false)

  const onCloseMobileFilters = () => {
    setMobileFilters(false)
  }

  useEffect(() => {
    dispatch(resetAllFilters())
    const searchParamsObj = getSearchNeigboursParams(searchParams)
    // устанавливаю все фильтры в соответствии с параметрами из адресной строки
    dispatch(setAllFilters(searchParamsObj))
    // получаю все факультеты с бека
    dispatch(fetchAllFaculties({ token: getToken() }))
  }, [])
  const allFaculties = useSelector(neighbourFiltersSelectors.allFaculties)
  const statusForAllFilters = useSelector(
    neighbourFiltersSelectors.statusForAllFilters
  )
  const errorNeighbourFiltersSelectorsStatus = useSelector(
    neighbourFiltersSelectors.errorStatus
  )
  const price_max = useSelector(neighbourFiltersSelectors.price_max)
  const price_min = useSelector(neighbourFiltersSelectors.price_min)
  const age_min = useSelector(neighbourFiltersSelectors.age_min)
  const age_max = useSelector(neighbourFiltersSelectors.age_max)
  const level_education = useSelector(neighbourFiltersSelectors.level_education)
  const faculty = useSelector(neighbourFiltersSelectors.faculty)
  const genderList = useSelector(neighbourFiltersSelectors.genderList)
  const coursesList = useSelector(neighbourFiltersSelectors.coursesList)
  const levelsEducationList = useSelector(
    neighbourFiltersSelectors.levelsEducationList
  )
  const wishesList = useSelector(neighbourFiltersSelectors.wishesList)
  const allNeigFilters = useSelector(neighbourFiltersSelectors.allNeigFilters)

  const [isFullDescrClicked, setFullDescrClicked] = useState(false) // кнопка, отвечающая за развертывание фильтров

  type TAgainstWishes = 'against_alcohol' | 'against_smoking'

  const [reactLevelEducation, setReactLevelEducation] =
    useState<ISelectItem<TIdLevelsEducation> | null>(null)
  const [reactFaculty, setReactFaculty] = useState<ISelectItem<string> | null>(
    null
  )
  const neighboursArrayStatus = useSelector(
    neighbourSelector.neighboursArrayStatus
  )
  const errorStatus = useSelector(neighbourSelector.errorStatus)
  const { redirectToAuth } = useRegistered()

  useEffect(() => {
    if (statusForAllFilters === 'success') {
      setReactLevelEducation(
        levelsEducationList.find((level) => level.value === level_education)
      )
    }
  }, [statusForAllFilters])

  useEffect(() => {
    if (statusForAllFilters === 'success' && allFacultiesStatus === 'success') {
      setReactFaculty(allFaculties.find((facul) => facul.value === faculty))
    }
  }, [statusForAllFilters, allFacultiesStatus])

  const onSearchNeighbours = () => {
    const newParams = fromFiltersToSearhParams(
      allNeigFilters,
      searchParams.get['offset']
    )
    dispatch(
      fetchAllNeighbours({
        token: getToken(),
        searchParams: newParams,
      })
    )
    setSearchParams(newParams as unknown as URLSearchParamsInit)
  }

  if (statusForAllFilters === 'success') {
    const onSetGender = (id: string) => {
      dispatch(setGenderList(id as TGender))
    }

    const onSetCourse = (id: string) => {
      dispatch(setCoursesList(id as TCourse))
    }

    const onChangeEducationStage = (
      levelEdu: ISelectItem<TLevelsEducation>
    ) => {
      dispatch(
        setLevelEducation(
          levelEdu.value.toString() as unknown as TIdLevelsEducation
        )
      )
      if (level_education) {
        for (const level of levelsEducationList) {
          if (level.label === levelEdu.label) {
            setReactLevelEducation(level)
          }
        }
      }
    }

    const onChangeFaculty = (faculty: ISelectItem<string>) => {
      dispatch(setFaculty(faculty.value.toString()))
      setReactFaculty(faculty)
    }

    const onSetWishes = (id: TAgainstWishes) => {
      if (id === 'against_alcohol') {
        dispatch(setAgainstAlcohol())
      } else if (id === 'against_smoking') {
        dispatch(setAgainstSmoking())
      }
    }
    if (errorStatus === 401) {
      redirectToAuth()
    }
    const isMobileVersion = getClientWidth() < adaptiveWidth['572px']

    const labelForPrice = (
      <>
        Предпочтения по цене, <img src={ruble} alt="" />
      </>
    )

    const content = (
      <>
        <div className={styles.wrapper}>
          <div className={styles.filters}>
            <Range
              minValue={+price_min}
              maxValue={+price_max}
              label={labelForPrice}
              maxDefaultValue={200000}
              step={50}
              onChangeMin={(item) => {
                dispatch(setPriceMin(item.toString()))
              }}
              onChangeMax={(item) => {
                dispatch(setPriceMax(item.toString()))
              }}
              wrapperClass={styles.adaptiveFilter}
              type='single'
            />
            <Range
              minValue={+age_min}
              maxValue={+age_max}
              label="Возраст"
              maxDefaultValue={100}
              step={1}
              onChangeMin={(item) => {
                dispatch(setAgeMin(item.toString()))
              }}
              onChangeMax={(item) => {
                dispatch(setAgeMax(item.toString()))
              }}
              wrapperClass={styles.adaptiveFilter}
              type='double'
            />
            <MultipleFilter
              label="Пол"
              items={genderList}
              onClick={onSetGender}
              itemClass={`${styles.triple_neighbours}`}
              wrapperClass={styles.adaptiveMultiple}
            />
            {isFullDescrClicked ? (
              <>
                <MultipleFilter
                  label="Курс"
                  items={coursesList}
                  onClick={onSetCourse}
                  itemClass={`${styles.course}`}
                  wrapperClass={styles.adaptiveMultiple}
                />
                <SelectFilter
                  items={levelsEducationList}
                  label="Ступень образования"
                  onChange={onChangeEducationStage}
                  value={reactLevelEducation}
                  className={styles.selectFilter}
                />
                <SelectFilter
                  items={allFaculties}
                  label="Факультет"
                  onChange={onChangeFaculty}
                  value={reactFaculty}
                  className={styles.selectFilter}
                />
                <MultipleFilter
                  label="Пожелания"
                  items={wishesList}
                  onClick={onSetWishes}
                  itemClass={`item_double `}
                  wrapperClass={`wrapper_double ${styles.adaptiveMultiple}`}
                />
              </>
            ) : null}
          </div>
          <SearchAndResetFilters
            onClickHide={() => setFullDescrClicked(!isFullDescrClicked)}
            onClickReset={() => {
              setReactFaculty(null)
              setReactLevelEducation(null)
              dispatch(resetAllFilters())
            }}
            isFullDescrClicked={isFullDescrClicked}
            onSeachData={() => {
              if (isMobileVersion) {
                onCloseMobileFilters()
              }
              onSearchNeighbours()
            }}
            searchBtnText="Показать соседей"
          />
        </div>
      </>
    )

    if (isMobileVersion) {
      return (
        <>
          <MobileStickyBlock>
            <Btn
              className={styles.mobileFiltersBtn}
              onClick={() => {
                setMobileFilters(true)
              }}
            >
              Фильтры
            </Btn>
          </MobileStickyBlock>
          <MobileDialog
            isMobile={isMobileFilters}
            onCloseDialog={onCloseMobileFilters}
          >
            {content}
          </MobileDialog>
        </>
      )
    }
    return <>{content}</>
  }
}

export default FindNeighbourFilters
