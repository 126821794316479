import type { RootState } from 'app/store'
import {
  resetRoomData,
  setLoadedDataMyAd,
  resetDeleteStatus,
} from './myAdsSlice'

export const myRoomAd = (state: RootState) => state.myAdsSlice.myRoomAd

export const myRoomAdStatus = (state: RootState) =>
  state.myAdsSlice.myRoomAdStatus

export const allMyRoomAds = (state: RootState) => state.myAdsSlice.allMyRoomAds

export const allMyRoomAdsStatus = (state: RootState) =>
  state.myAdsSlice.allMyRoomAdsStatus

export const editMyAdStatus = (state: RootState) =>
  state.myAdsSlice.editMyAdStatus
export const deleteMyAdStatus = (state: RootState) =>
  state.myAdsSlice.deleteMyAdStatus

export { resetRoomData, setLoadedDataMyAd, resetDeleteStatus }

export const error = (state: RootState) => state.myAdsSlice.error
export const errorStatus = (state: RootState) => state.myAdsSlice.errorStatus
