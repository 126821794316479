import { Container } from 'shared'

import styles from './MobileStickyBlock.module.scss'

interface Props {
  children: JSX.Element | JSX.Element[]
  containerClass?: string
}

const MobileStickyBlock = (props: Props) => {
  const { containerClass } = props
  return (
    <>
      <div
        className={`${styles.stickyBlock} ${
          containerClass ? containerClass : ''
        }`}
      >
        {props.children}
      </div>
    </>
  )
}

export default MobileStickyBlock
