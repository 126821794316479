import { memo } from 'react'

import { ModalWraper, Title, Text, Btn, useToken, routes } from 'shared'

import styles from './ExitRegModal.module.scss'
import { Link } from 'react-router-dom'
interface Props {
  isExitRegModalOpen: boolean
  closeModal: () => void
  message: string
  title: string
  exitLink: string
  isClearStorage: boolean
}

const ExitRegModal = memo((props: Props) => {
  const { isExitRegModalOpen, closeModal, message, title, exitLink, isClearStorage = false } = props
  const {clearStorage} = useToken()
  function onClick() {
    if (isClearStorage) clearStorage()
  }
  return (
    <ModalWraper
      isModalOpen={isExitRegModalOpen}
      closeModal={closeModal}
      showCloseBtn={false}
      wrapperClass={styles.modal_reg}
    >
      <Title type="h3">{title}</Title>
      {message ?  
        <div className={styles.text}>
          <Text type="paragraph">
            {message}
          </Text>
        </div> 
      : null}
      <div className={styles.buttons}>
        <Btn onClick={closeModal} width={200} className={styles.btn_small}>
          Остаться
        </Btn>
        <Btn
            color="button-red"
            type="outlined"
            width={128}
            onClick={onClick}
            url={exitLink}
            className={styles.btn_small}
          >
            Выйти
        </Btn>
      </div>
    </ModalWraper>
  )
})
export default ExitRegModal
